import React from 'react'

import { useCQuery } from '../../Services'
import { useCapsule, cap } from '../../State'
import { Text, Flex, FlagField } from '../../Components'
import { paw } from '../../Resources'
import { updateCliente, getZampe } from './ProcedureVendita'
import { ScontoBox } from './ScontoBox'

export const Sconto = () => {
  const [{ noSaleDiscount, affiliazione }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const idCliente = cliente ? cliente.Id : 0
  const { data: statsCliente } = useCQuery(['statsCliente', idCliente])

  if (!cliente || !statsCliente || idCliente < 1) return null

  const displayNoSconto = noSaleDiscount || cliente.Affiliato
    ? null
    : (
      <FlagField
        label='Mai Sconto' checked={cliente.MaiSconto}
        onChange={(checked) => updateCliente(cliente, { MaiSconto: checked ? 1 : 0 })}
      />
      )

  const displayAffiliazione = !cliente.Affiliato ? null : <Text bold size={20} value={`AFFILIAZIONE: ${affiliazione && affiliazione.Nome}`} />

  const displaySconto = !cliente.Affiliato || !affiliazione || affiliazione.TipoSconto === 3
    ? null
    : <Text bold size={20} value={`SCONTO: ${affiliazione ? affiliazione.scontoLabel : ''}`} style={{ marginLeft: 20 }} />

  const displayZampe = cliente.Affiliato && affiliazione && affiliazione.TipoSconto !== 3
    ? null
    : [...Array(Math.floor(getZampe(statsCliente)))].map((e, ind) => <img alt='p' style={{ marginLeft: 10 }} key={ind} src={paw} width={30} height={30} />)

  return (
    <Flex js row heigth={30}>
      {displayNoSconto}
      <ScontoBox />

      <Flex row js style={{ marginLeft: 20 }}>
        {displayAffiliazione}
        {displaySconto}
        {displayZampe}
      </Flex>
    </Flex>
  )
}
