import React, { useState } from 'react'
import posed from 'react-pose'

import { Icon, Text } from '..'

const ClickButton = posed.div({
  idle: { x: 0, y: 0, scale: 1, boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.14)' },
  click: { x: 2, y: 2, scale: 1, boxShadow: '5px 5px 13px 3px rgba(100, 100, 100, 0.24)' },
  disabled: { scale: 1, boxShadow: '', backgroundColor: 'rgba(240, 240, 240, 0.8)' },
  hovered: { x: 0, y: 0, scale: 1.2, boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.14)' }
})

export function Button ({ round, disabled, icon, label, onClick, style, iconStyle, width, hidden }) {
  const [pose, setPose] = useState(disabled ? 'disabled' : 'idle')

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    borderRadius: 20,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
    userSelect: 'none',
    padding: 10,
    width,
    ...(label ? { paddingLeft: 20, paddingRight: 20 } : {}),
    ...(round ? { width: 30, height: 30 } : {}),
    ...style
  }

  const actions = {
    onMouseDown: () => !disabled && setPose('click'),
    onMouseUp: () => !disabled && setPose(!label ? 'hovered' : 'idle'),
    onMouseEnter: () => !disabled && !label && setPose('hovered'),
    onMouseLeave: () => !disabled && setPose('idle'),
    onClick: disabled ? null : onClick
  }

  return hidden ? null : (
    <ClickButton pose={pose} {...actions} style={buttonStyle}>
      {icon ? <Icon name={icon} size={16} style={iconStyle} /> : null}
      {label ? (<Text bold center value={label} style={{ marginLeft: icon ? 15 : 0, flexGrow: width ? 1 : 0, textAlign: 'center' }} />) : null}
    </ClickButton>
  )
}
