import React from 'react'
import { useCapsule, cap } from '../../State'

import { Text, Button, Card, Flex } from '../../Components'
import { getDisplayPrice, getTotaleCarrello, isSmallAmazon } from '../../Services'
import { TotaleDialog } from './TotaleDialog'

export function TotaleAmazon () {
  const [{ sale, carrello }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const dialogControls = {}

  if (!cliente || !isSmallAmazon(cliente)) return null

  return (

    <Card style={{ marginTop: 20, width: '100%' }}>
      <Flex row>
        <Text bold size={32} value='Totale:' />
        <Text size={32} style={{ marginLeft: 7 }} value={getDisplayPrice(getTotaleCarrello(carrello))} />
      </Flex>
      <Flex row fw jb style={{ marginTop: 10 }}>
        <Button icon='ban' label='Annulla' onClick={() => dialogControls.showCancel()} />

        <Button
          icon='cash-register' label={`${sale.Id ? 'Modifica' : 'Completa'} Vendita`}
          onClick={!carrello.length ? null : () => dialogControls.showProcess()}
        />

      </Flex>

      <TotaleDialog dialogControls={dialogControls} />
    </Card>
  )
}
