import React, { useEffect, useState } from 'react'
import { Input, Spinner } from '../../Components'
import { cap } from '../../State'
import { InputText } from 'primereact/inputtext'
import { FC, useCQuery, getNuovoProdotto, getDisplayWeight, getDisplayPrice, parsePrice, emptyShopifyItem, getEmptyMove } from '../../Services'

export const QtEditor = ({ info: { rowData, rowIndex, value }, updateQuantity }) => {
  const [val, setVal] = useState(rowData.Qt)
  if (rowData.PesoSfuso) return getDisplayWeight(rowData.PesoSfuso)
  if (rowData.IdProdotto === -1 || rowData.IdProdotto === 0) return '1'

  return (
    <InputText
      type='text' value={val}
      onBlur={(e) => updateQuantity(rowIndex, value, e.target.value)}
      onKeyDown={(key) => key.keyCode === 13 && updateQuantity(rowIndex, value, val)}
      onChange={(e) => setVal(parseInt(e.target.value) || '')}
      style={{ width: 50 }}
    />
  )
}
const Field = ({ label, field, max, prodotto = {}, onChange, style, disabled, onBlur, value, placeholder, ...props }) => {
  return (
    <Input
      id={field} value={value !== undefined ? value : prodotto[field]} disabled={disabled} onChange={onChange} maxLength={max}
      label={`${label}:`} placeholder={placeholder === false ? undefined : `Inserisci ${label}...`} onBlur={onBlur}
      style={{ width: '48%', ...style }} {...props}
    />
  )
}

export const ProField = ({ maxWidth, ...props }) => <Field {...{ ...props, width: maxWidth ? '100%' : 150 }} />
export const SfoField = ({ style, width, ...props }) => <Field {...{ ...props, width: width || 80, style: { height: 31, ...style } }} />
export const RoField = ({ width, ...props }) => <Field {...{ ...props, disabled: true }} placeholder={false} />

export const PriceField = ({ label, value, field, prodotto = {}, onChange, style, width, disabled }) => {
  const [price, setPrice] = useState(value ? getDisplayPrice(value) : getDisplayPrice(prodotto[field]).replace(' €', ''))
  const updateEvent = () => {
    onChange({ [field]: parsePrice(price) })
    setPrice(getDisplayPrice(parsePrice(price)).replace(' €', ''))
  }
  useEffect(() => { setPrice(value !== undefined ? getDisplayPrice(value) : getDisplayPrice(prodotto[field]).replace(' €', '')) }, [value, field, prodotto])
  return (
    <Input
      id={field} value={price} onChange={(e) => setPrice(e[field])} maxLength={7} disabled={disabled}
      label={`${label}:`} placeholder='Euro?' onBlur={updateEvent}
      style={{ width: '48%', ...style }}
    />
  )
}

export const Filtro = ({ label, datatable }) => {
  const state = useState('')
  const { isSuccess, data } = useCQuery('magCatForn')

  return !isSuccess || !data
    ? <Spinner />
    : <Input
        id={label} dropdown showClear placeholder='Cerca...'
        options={data[label]} useState={state}
        onChange={value => datatable.current.filter(value, label, 'equals')}
      />
}

export const handleNewProdotto = async (magazzino, newProductCode) => {
  if (!magazzino || !Array.isArray(magazzino)) {
    window.growl.show({ severity: 'error', summary: 'Errore di Aggiunta', detail: 'Errore di caricamento, riprovare.' })
    return false
  }

  if (magazzino.find(m => m.Codice === newProductCode)) {
    window.growl.show({ severity: 'error', summary: 'Errore di Aggiunta', detail: 'Codice a barre già presente in magazzino' })
    return true
  }

  const [prodListino = {}] = await FC.service('listino').find({ query: { Codice: newProductCode } })
  const prodotto = await FC.service('magazzino').create({ ...getNuovoProdotto(), Codice: newProductCode, ...prodListino, Reparto: 1 })
  FC.log({ IdTarget: prodotto.Id, EventType: 'CreateProduct', Data: prodotto })
  cap.magazzino.patchState({ prodotto })
  return true
}

export const handleSaveMovimento = ({ Id, EventTime, Qt }, onSuccess) => {
  if (String(Qt) !== String(parseInt(Qt))) {
    window.growl.show({ severity: 'error', summary: 'Errore di Modifica', detail: 'Quantità non valida' })
    return false
  }
  FC.service('movimenti').patch(Id, { EventTime, Qt: parseInt(Qt) })
    .then(() => {
      window.growl.show({ severity: 'success', summary: 'Modifica Salvata' })
      onSuccess()
    }).catch(() => window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio' }))
}

export const handleDeleteMovimento = ({ Id = 0 }, onSuccess) => {
  if (!Id) {
    window.growl.show({ severity: 'error', summary: 'Errore strano', detail: 'Non dovrebbe succedere' })
    return false
  }
  FC.service('movimenti').remove(Id)
    .then(() => {
      window.growl.show({ severity: 'success', summary: 'Movimento Eliminato' })
      onSuccess()
    }).catch(() => window.growl.show({ severity: 'error', summary: 'Errore nella cancellazione' }))
}

export const handleResetMagazzino = async (onSuccess) => {
  const newReset = await FC.service('movimenti').create({ IdProdotto: 0, IdSale: 0, Qt: 0 })
  if (!newReset.Id) {
    window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio' })
    return false
  }
  onSuccess()
  window.growl.show({ severity: 'success', summary: 'Reset Effettuato', details: 'Controllare ultimo movimento' })
}

export const handleAddToCaricoScarico = (addProd, magazzino) => {
  if (!addProd || addProd.Id === undefined) return false
  if (addProd.Id === false) { // new From Listino
    addProd.Codice && handleNewProdotto(magazzino, addProd.Codice)
    return false
  }
  const { caricoProdotti: prods } = cap.magazzino.state
  const prodListIndex = prods.findIndex(({ Id }) => Id === addProd.Id)
  cap.magazzino.patchState({
    caricoProdotti: prodListIndex === -1
      ? [{ ...addProd, Qt: 1 }, ...prods]
      : prods.map((p, i) => i === prodListIndex ? { ...p, Qt: p.Qt + 1 } : p)
  })
}

export const getShopify = ({ Codice }, shopify) => shopify && shopify.length ? shopify.find(s => s.sku === Codice) || emptyShopifyItem : emptyShopifyItem

export const getProdQt = ({ Id }, magazzinoQt) => magazzinoQt && magazzinoQt.length ? magazzinoQt.find(mqt => mqt.IdProdotto === Id) || getEmptyMove() : getEmptyMove()

export const modifyOrder = (qt, prod) => {
  if (!qt) return cap.ordine.setState(cap.ordine.state.filter((el) => el?.prod?.Id !== prod.Id))

  const index = cap.ordine.state.findIndex(el => el?.prod?.Id === prod.Id)
  if (index === -1) return cap.ordine.setState([...cap.ordine.state, { prod, qt: 1 }])

  cap.ordine.setState(cap.ordine.state.map((el, ind) => ({
    prod: el.prod,
    qt: index === ind ? (qt === 'add' ? (el.qt + 1) : qt) : el.qt
  })))
}
