import React, { useRef, useState } from 'react'
import { cap, useCapsule } from '../../State'
import { getDisplayPrice, exportTable } from '../../Services'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'

import { Card, Button, Flex, Text } from '../../Components'
import { modifyOrder } from './ProcedureMagazzino'

export const QtEditor = ({ info: { rowData: { qt, prod } } }) => {
  const [val, setVal] = useState(qt)
  return (
    <InputText
      type='text' value={val}
      onBlur={(e) => modifyOrder(val, prod)}
      onKeyDown={(key) => key.keyCode === 13 && modifyOrder(val, prod)}
      onChange={(e) => setVal(parseInt(e.target.value) || '')}
      style={{ width: '50px' }}
      onFocus={({ target }) => target.select()}
    />
  )
}

export function Ordine () {
  const [ordine, setOrdine] = useCapsule(cap.ordine)
  const [mustDelete, setMustDelete] = useState(false)
  const timeout = useRef(null)

  const templatePrice = ({ prod }) => getDisplayPrice(prod.PrezzoFornitore)
  const totalSingle = ({ prod, qt }) => getDisplayPrice(prod.PrezzoFornitore * qt)
  const totalPrice = getDisplayPrice(!ordine ? 0 : ordine.reduce((tot, p) => tot + (p.qt * p.prod.PrezzoFornitore), 0))
  const templateDelete = ({ prod }) => (
    <Button
      icon='trash' style={{ height: 20, width: 30, padding: 0, paddingLeft: 8, alignItems: 'center' }} onClick={(e) => {
        e.stopPropagation()
        modifyOrder(0, prod)
      }}
    />
  )

  const handleDelete = () => {
    if (mustDelete) {
      clearTimeout(timeout.current)
      setMustDelete(false)
      setOrdine([])
    } else {
      setMustDelete(true)
      timeout.current = setTimeout(() => setMustDelete(false), 2000)
    }
  }

  if (!ordine || !ordine.length) return null
  return (
    <Card fadeIn style={{ marginTop: 20 }} title='Ordine Corrente' titleCollapsed={`Ordine Corrente: ${totalPrice}`} collapsable collapsed>
      <DataTable
        style={{ minHeight: 400 }} paginator rows={20} emptyMessage='Nessuna Movimento Trovato'
        value={ordine}
        header={(
          <Flex row fw jb>
            <Text bold size={24} value={`Totale Ordine: ${totalPrice}`} />
            <Button icon={mustDelete ? 'check' : 'trash'} label={mustDelete ? 'Conferma' : 'Elimina Ordine'} width={200} onClick={handleDelete} />
            <Button icon='file-excel' label='Esporta Tabella' width={200} onClick={() => exportTable('ordine', ordine)} />
          </Flex>
        )}
      >
        <Column field='prod.Fornitore' header='Fornitore' style={{ width: 180 }} sortable />
        <Column field='prod.Categoria' header='Categoria' style={{ width: 180 }} />
        <Column field='prod.Descrizione' header='Descrizione' sortable />
        <Column field='prod.Info' header='Info' style={{ width: 120 }} sortable />
        <Column body={templatePrice} header='Prezzo F.' style={{ width: 120 }} />
        <Column header='Qt' field='qt' editor={(info) => <QtEditor info={info} />} style={{ width: 90, textAlign: 'right' }} />
        <Column body={totalSingle} header='Totale' style={{ width: 120 }} />
        <Column style={{ width: 60 }} body={templateDelete} />

      </DataTable>

    </Card>
  )
}
