import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Checkbox } from 'primereact/checkbox'

import { Text, Card, Flex, Icon, SearchBox, Spinner } from '../../Components'
import { getDisplayPrice, getDisplayWeight, getPriceKgDaPrezzo, getReparto, isLargeScreen, isSmallAmazon, useCQuery } from '../../Services'
import { useCapsule, cap } from '../../State'

import { RepartoEditor, DescriptionEditor, QtEditor, PriceEditor, descriptionTemplate } from './CartEditor'
import { handleAddProdotto, updateCliente } from './ProcedureVendita'

export function Carrello () {
  const [{ carrello, findProdotto }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const { status, data: magazzino } = useCQuery('magazzino')

  const setCarrello = (carrello) => cap.vendita.patchState({ carrello })

  const fornTemplate = (rowData) => {
    if (rowData.TotaleSingolo <= 0) return ''
    const prod = magazzino?.find(({ Id }) => Id === rowData.IdProdotto)
    return prod ? prod.Fornitore : ''
  }

  const prezSingTemplate = (rowData) => {
    if (rowData.IdProdotto === -1 || rowData.IdProdotto === 0) return ''
    const prod = magazzino?.find(({ Id }) => Id === rowData.IdProdotto)
    if (!prod) return 0
    return rowData.PesoSfuso > 0
      ? `${getDisplayPrice(getPriceKgDaPrezzo(prod, rowData.TotaleSingolo))}/Kg`
      : getDisplayPrice(rowData.prezzoCustom || prod.PrezzoListino)
  }

  const qtTemplate = (rowData) => rowData.PesoSfuso ? getDisplayWeight(rowData.PesoSfuso) : rowData.Qt
  const repTemplate = ({ Reparto }) => getReparto(Reparto)
  const totTemplate = (rowData) => getDisplayPrice(rowData.TotaleSingolo)

  const removeTemplate = (rowData, { rowIndex }) => (
    <Flex fw fh style={{ cursor: 'pointer' }} onClick={() => setCarrello(carrello.filter((p, ind) => ind !== rowIndex))}>
      <Text bold color='red' value='X' size={18} />
    </Flex>
  )

  const noDiscountTemplate = (rowData, { rowIndex }) => rowData.IdProdotto < 0
    ? null
    : <Checkbox
        checked={!!rowData.ValePunti} onChange={({ checked }) =>
          setCarrello(carrello.map((p, ind) => ind === rowIndex ? { ...p, ValePunti: checked ? 1 : 0 } : p))}
      />

  const handleAddFavourite = (prod) => {
    cap.vendita.patchState({ findProdotto: '' })
    if (cliente.Id < 1 || !prod || prod.IdProdotto === undefined) return false
    updateCliente(cliente, { [findProdotto]: prod.IdProdotto })
  }
  if (!cliente) return null
  const scrollHeight = isSmallAmazon(cliente) ? '370px' : isLargeScreen() ? '470px' : '270px'

  return (
    <Card style={{ height: isSmallAmazon(cliente) ? 'calc(100vh - 186px)' : 'calc(100vh - 286px)', width: '100%' }}>
      {status === 'loading'
        ? <Spinner />
        : (
          <Flex>
            <SearchBox
              placeholder='Inserisci codice, prezzo o nome prodotto'
              onSelection={findProdotto ? handleAddFavourite : handleAddProdotto}
            />
            <DataTable style={{ marginTop: 20, minHeight: 300, width: '100%' }} value={carrello} emptyMessage='Aggiungi Prodotti' editable scrollable scrollHeight={scrollHeight}>
              <Column header='💰' style={{ width: 42 }} body={noDiscountTemplate} />
              <Column header='Reparto' style={{ width: 150 }} body={repTemplate} editor={(info) => <RepartoEditor info={info} />} />
              <Column header='Fornitore' style={{ width: 150 }} body={fornTemplate} />
              <Column header='Descrizione' body={descriptionTemplate(magazzino)} editor={(info) => <DescriptionEditor magazzino={magazzino} info={info} />} />
              <Column header='Prezzo' style={{ width: 90, textAlign: 'right' }} body={prezSingTemplate} editor={(info) => <PriceEditor info={info} />} />
              <Column header='Qtà' style={{ width: 70, textAlign: 'right' }} body={qtTemplate} editor={(info) => <QtEditor magazzino={magazzino} info={info} />} />
              <Column header='Totale' style={{ width: 100, textAlign: 'right' }} body={totTemplate} />
              <Column header='' style={{ width: 20, textAlign: 'right', padding: 0 }} body={removeTemplate} />
            </DataTable>
            {carrello.length > (isLargeScreen() ? 15 : 7) && <Icon name='arrow-down' size={20} />}
          </Flex>
          )}
    </Card>
  )
}
