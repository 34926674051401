import React, { useState } from 'react'
import { Flex, Button, Text, Spinner } from '../'
import { Dialog } from 'primereact/dialog'

export function DialogSaveDelete ({ children, visibleState, onDelete, footer, onSave, isProcessing, ...props }) {
  const [footerDelete, setFooterDelete] = useState(false)
  const [visible, setVisible] = visibleState

  const handleDelete = () => {
    setFooterDelete(false)
    onDelete && onDelete()
  }

  const dialogFooterDelete = (
    <Flex fw jb row>
      <Text bold value='Sicuro di Eliminare ?' />
      <Flex jb row width={250}>
        <Button label='Annulla' icon='ban' onClick={() => setFooterDelete(false)} />
        <Button label='Elimina' icon='trash' onClick={handleDelete} />
      </Flex>
    </Flex>
  )

  const dialogFooter = (
    <Flex fw jb row>
      {onDelete ? <Button label='Elimina' icon='trash' onClick={() => setFooterDelete(true)} /> : <div />}
      <Flex jb row width={250}>
        <Button label='Annulla' icon='ban' onClick={() => setVisible(false)} />
        <Button label='Salva' icon='check' onClick={onSave} />
      </Flex>
    </Flex>
  )

  const dialogProcessing = (
    <Flex fw style={{ marginTop: -10 }}>
      <Spinner />
    </Flex>
  )

  const renderFooter = () => {
    if (footer === false) return null
    if (footer) return footer
    if (isProcessing) return dialogProcessing
    return footerDelete ? dialogFooterDelete : dialogFooter
  }

  return (
    <Dialog visible={visible} {...props} modal onHide={() => setVisible(false)} footer={renderFooter()}>
      {children}
    </Dialog>
  )
}
