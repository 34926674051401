import React, { useState } from 'react'
import { useCQuery } from '../../Services'
import { Card, Button, Input, Text, Flex, Password } from '../../Components'
import { Prodotti } from './Prodotti'
import { Vendite } from './Vendite'
import { Clienti } from './Clienti'
import { Affiliazioni } from './Affiliazioni'
import { cap } from '../../State'
import moment from 'moment'

const STATS_TYPES = ['Prodotti', 'Vendite', 'Clienti', 'Affiliazioni']
const defaultFrom = moment().startOf('month').startOf('day')
const defaultTo = moment().endOf('day')

const convertDate = (date) => moment(date).format('YYYY-MM-DD')
const convertRange = ({ from, to }) => ({ from: convertDate(from), to: convertDate(to) })

export default function Statistiche () {
  const [passVisible, setPassVisible] = useState(true)
  const [range, setRange] = useState({ from: defaultFrom.toDate(), to: defaultTo.toDate() })
  const [type, setType] = useState('')
  const queryStoricoClienti = { $sort: { Giorno: 1 }, $and: [{ Giorno: { $gte: convertDate(range.from) } }, { Giorno: { $lte: convertDate(range.to) } }] }

  useCQuery(['storicoClienti', queryStoricoClienti])
  useCQuery('statsClienti')

  const handleSetDate = (field) => (data) => {
    let newFrom = field === 'from' ? data.from : range.from
    let newTo = field === 'to' ? data.to : range.to
    field === 'to' && moment(newTo).isBefore(moment(newFrom)) && (newFrom = newTo)
    field === 'from' && moment(newFrom).isAfter(moment(newTo)) && (newTo = newFrom)
    setRange({ from: newFrom, to: newTo })
  }

  const SelButton = (label) => (
    <Button
      key={label} label={label} icon='chart-pie'
      onClick={() => setType(label)}
      style={{ marginLeft: 20 }}
    />
  )

  if (!cap.user.state.isAdmin && passVisible) return <Password matchPassword='melograno' handlePasswordOK={() => setPassVisible(false)} noCancel />

  return (
    <Flex fw>
      <Card style={{ width: '100%' }}>
        <Flex row fw js>
          <Flex row fw jb width={450}>
            <Text value='Dal: ' style={{ }} />
            <Input date id='from' value={range.from} onChange={handleSetDate('from')} style={{ width: 170 }} />

            <Text value='Al: ' style={{ }} />
            <Input date id='to' value={range.to} onChange={handleSetDate('to')} style={{ width: 170 }} />
          </Flex>
          <Flex row width={600} height={50} js>
            {STATS_TYPES.map(st => SelButton(st))}
          </Flex>
        </Flex>
      </Card>
      {type === 'Prodotti' ? <Prodotti range={convertRange(range)} /> : null}
      {type === 'Vendite' ? <Vendite range={convertRange(range)} /> : null}
      {type === 'Clienti' ? <Clienti query={queryStoricoClienti} /> : null}
      {type === 'Affiliazioni' ? <Affiliazioni range={convertRange(range)} /> : null}
    </Flex>
  )
}
