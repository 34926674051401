import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useCQuery, tipoAffiliazione } from '../../Services'
import { Card, Button, Flex, Text } from '../../Components'
import Editor from './Editor'

export default function Affiliazioni () {
  const [aff, setAff] = useState(null)

  const { isSuccess, data } = useCQuery('affiliazioni')

  const templateAttivo = ({ Attivo }) => Attivo ? 'Si' : 'No'
  const templateTipoSconto = ({ TipoSconto }) => (tipoAffiliazione.find(ta => ta.value === TipoSconto) || { label: 'Non Trovata' }).label
  const templateSconto = ({ TipoSconto, Sconto }) => [
    'Mai Sconto',
    `${Sconto}% su ogni vendita`,
    `${Sconto} Euro ogni 300 di spesa`,
    'Sconto Normale (solo tracciamento)'
  ][TipoSconto]

  return (
    <Card fadeIn>
      <DataTable
        paginator rows={20} emptyMessage='Nessuna Affiliazione' selectionMode='single'
        value={isSuccess ? data : []} selection={aff} onRowSelect={({ data }) => setAff(data)}
        header={(
          <Flex fw row jb>
            <Text value='GESTIONE AFFILIAZIONI' bold />
            <Button icon='plus' label='Nuova Affiliazione' onClick={() => setAff({ Attivo: 1 })} />
          </Flex>
        )}
      >
        <Column field='Nome' header='Nome' />
        <Column field='Attivo' header='Promo Attiva' body={templateAttivo} />
        <Column field='TipoSconto' header='Tipo Sconto' body={templateTipoSconto} />
        <Column field='Sconto' header='Sconto' body={templateSconto} />

      </DataTable>

      <Editor input={aff} onHide={() => setAff(null)} />
    </Card>
  )
}
