import { useQuery, queryCache } from 'react-query'
import { FC } from './FeathersClient'
import { getEmptyStats } from './Objects'
import moment from 'moment'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  operatori: {
    config: queryConfig.rare,
    queryFn: () => FC.service('sistema').get('Operatori').then(({ Valore }) => Valore.split('#$#').filter(o => o))
  },
  affiliazioni: {
    config: queryConfig.rare,
    queryFn: () => FC.service('affiliazioni').find()
  },
  clienti: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('info').get('clienti')
  },
  clientiExp: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('expclienti')
  },
  statsCliente: {
    config: queryConfig.rare,
    queryFn: (key, id) => id !== 0 ? FC.service('info').get('statcliente', { query: { id } }) : getEmptyStats(),
    noInitalPrefetch: true
  },
  statsClienti: {
    config: queryConfig.rare,
    queryFn: () => FC.service('statistiche').get('clienti')
  },
  storicoCliente: {
    config: queryConfig.rare,
    queryFn: (key, id) => id === 0 ? [] : FC.service('info').get('storicocliente', { query: { id } }),
    noInitalPrefetch: true
  },
  storicoClienti: {
    config: queryConfig.rare,
    queryFn: (key, query) => FC.service('storicoclienti').find({ query }),
    noInitalPrefetch: true
  },
  magazzino: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('magazzino').find({ query: { Codice: { $ne: '##CANCELLATO##' } } })
  },
  magCatForn: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('magCatForn')
  },
  bolle: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('sales').find({ query: { $sort: { SaleTime: -1 }, BollaDelivery: { $gt: 0 }, joinReferences: true } })
  },
  todaySales: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('sales').find({ query: { SaleTime: { $gte: moment().startOf('day') } } })
  },
  lastSales: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('sales').find({ query: { $sort: { SaleTime: -1 }, $limit: 40, joinReferences: true } })
  },
  movimenti: {
    config: queryConfig.frequent,
    queryFn: (key, searchFrom, searchTo) => FC.service('movimenti').find({
      query: {
        $sort: { EventTime: -1 },
        $and: [{ EventTime: { $gte: moment(searchFrom).startOf('day') } }, { EventTime: { $lte: moment(searchTo).endOf('day') } }]
      }
    }),
    defaultKeys: [moment().startOf('month').toDate(), moment().endOf('day').toDate()]
  },
  magazzinoQt: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('info').get('qtmagazzino')
  },
  shopify: {
    config: queryConfig.frequent,
    queryFn: () => FC.service('shopify').get('products')
  },
  statistiche: {
    config: queryConfig.rare,
    queryFn: (key, target, query) => FC.service('statistiche').get(target, { query }),
    noInitalPrefetch: true
  },
  prodottiVenduti: {
    config: queryConfig.rare,
    queryFn: (key, query) => FC.service('statistiche').get('prodottivenduti', { query }),
    defaultKeys: [{ from: moment().startOf('month').toDate(), to: moment().endOf('day').toDate() }]
  },
  daEvadere: {
    config: queryConfig.autoFetchFrequent,
    queryFn: () => FC.service('sales').find({ query: { $sort: { SaleTime: -1 }, BollaDelivery: { $gt: 0 }, Evasa: 0, Pagamento: { $ne: 'allaconsegnainnegozio' }, joinReferences: true } })
  },
  daRitirare: {
    config: queryConfig.autoFetchFrequent,
    queryFn: () => FC.service('sales').find({ query: { $sort: { SaleTime: -1 }, BollaDelivery: { $gt: 0 }, Evasa: 0, Pagamento: 'allaconsegnainnegozio', joinReferences: true } })
  }
}

export const useCQuery = (queryKey) => {
  const { queryFn, config } = queries[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  return useQuery({ queryKey, queryFn, config: { ...config, enabled: FC.isReady() } })
}

export const prefetchQuery = (queryKey) => {
  const { queryFn, config } = queries[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryCache.prefetchQuery(queryKey, queryFn, config)
}

export const prefetchQueries = () => {
  Object.entries(queries).forEach(([queryKey, { config, queryFn, defaultKeys, noInitalPrefetch }]) => {
    const key = defaultKeys ? [queryKey, ...defaultKeys] : queryKey
    !noInitalPrefetch && queryCache.prefetchQuery(key, queryFn, config)
  })
}

export const invalidateQuery = (queryKey) =>
  (Array.isArray(queryKey) ? queryKey : [queryKey])
    .forEach(key => queryCache.invalidateQueries(key, { refetchInactive: true, force: true }))
