import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import fcAuth from '@feathersjs/authentication-client'
import io from 'socket.io-client'

import { cap } from '../State'
import { serializeError } from 'serialize-error'
import { invalidateQuery } from '.'

/* ============================== Environment Setup ================================================== */
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const serverUrl = window._env_ && window._env_.API_URL ? window._env_.API_URL : 'https://api.doggypop.net'

/* ============================== Socket Configuration ================================================== */
const FC = {
  isDev,
  socket: io(serverUrl, { transports: ['websocket'], forceNew: true }),
  client: feathers(),
  online: false,
  authenticated: false,
  connectionHandler: (event) => () => {
    FC.isDev && console.log(`Socket ${event} to ${serverUrl}`)
    cap.user.patchState({ offline: event === 'disconnect' })
    FC.online = event === 'connect'
  }
}

FC.client.configure(socketio(FC.socket, { timeout: 30000, pingInterval: 5000, pingTimeout: 20000 }))
FC.client.configure(fcAuth({ storage: new fcAuth.MemoryStorage() }))

FC.socket.on('connect', FC.connectionHandler('connect'))
FC.socket.on('disconnect', FC.connectionHandler('disconnect'))

/* ============================== Socket Methods ================================================== */

FC.login = async (credentials) => {
  try {
    const user = await FC.client.authenticate(credentials)
    FC.authenticated = !!user.accessToken
    return user
  } catch (details) {
    FC.authenticated = false
    FC.isDev && console.log(details)
    return { user: { username: 'NotAuthenticated' } }
  }
}

FC.logout = () => {
  try { FC.client.logout() } catch (e) {}
}

const doServiceMethod = async (serviceName, method, param1, param2) => {
  try {
    return (await FC.client.service(serviceName)[method](param1, param2))
  } catch (err) {
    FC.isDev && console.log(err)
    try {
      const Details = {
        find: {
          Data: JSON.stringify({ serviceName, method, query: param1, state: cap.dumpStates() })
        },
        patch: {
          IdTarget: param1,
          Data: JSON.stringify({ serviceName, method, id: param1, body: param2, state: cap.dumpStates() })
        },
        get: {
          IdTarget: param1,
          Data: JSON.stringify({ serviceName, method, id: param1, query: param2, state: cap.dumpStates() })
        },
        create: {
          Data: JSON.stringify({ serviceName, method, body: param1, query: param2, state: cap.dumpStates() })
        },
        remove: {
          IdTarget: param1,
          Data: JSON.stringify({ serviceName, method, id: param1, state: cap.dumpStates() })
        },
        update: {
          IdTarget: param1,
          Data: JSON.stringify({ serviceName, method, id: param1, body: param2, state: cap.dumpStates() })
        }
      }
      err.name !== 'Timeout' && FC.client.service('logs').create({
        LogLevel: 'error',
        EventType: 'FeathersError',
        Error: JSON.stringify(serializeError(err)),
        ...Details[method]
      })
    } catch (e) {}
  }
}

FC.isReady = () => FC.online && FC.authenticated

FC.log = async (body, isError) => {
  const { EventDetails, Data, Error = '', ...data } = body
  FC.isDev && console.log(body)
  doServiceMethod('logs', 'create', { LogLevel: isError ? 'error' : 'info', Data: JSON.stringify(Data || EventDetails), Error, ...data })
}

FC.service = (serviceName) => ({
  find: (query) => doServiceMethod(serviceName, 'find', query),
  patch: (id, body) => doServiceMethod(serviceName, 'patch', id, body),
  get: (id, query) => doServiceMethod(serviceName, 'get', id, query),
  create: (body, query) => doServiceMethod(serviceName, 'create', body, query),
  remove: (id) => doServiceMethod(serviceName, 'remove', id),
  update: (id, body) => doServiceMethod(serviceName, 'update', id, body)
})

FC.checkConnection = () => FC.service('sistema').get('DbDescription')

// Channel Updates
FC.updateCache = (keys) => FC.service('messaggi').create({ action: 'update', keys })
FC.client.service('messaggi').on('created', (message) => {
  message && message.action === 'update' && message.keys && invalidateQuery(message.keys)
  FC.isDev && console.log('Ricevuto Messaggio!', message)
})

export { FC }
