import React, { useState, useRef } from 'react'
import { cap, useCapsule } from '../../State'
import { Card, Spinner, Button, Input, Flex, FlagField, Text } from '../../Components'
import { useCQuery, exportTable, getDisplayPrice, getReparto, FC } from '../../Services'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { handleNewProdotto, Filtro, modifyOrder } from './ProcedureMagazzino'

export function MagazzinoTable (props) {
  const [gSearch, setGSearch] = useState('')
  const [newProductCode, setNewProductCode] = useState('')
  const [showShopify, setShowShopify] = useState(false)
  const [processingShopify, setProcessingShopify] = useState(false)
  const [filteredTable, setFilteredTable] = useState(null)

  const [{ prodotto }] = useCapsule(cap.magazzino)

  const datatable = useRef(null)

  const { isSuccess: magLoadOK, data: magazzino } = useCQuery('magazzino')
  const { isSuccess: magQtLoadOk, data: magazzinoQt } = useCQuery('magazzinoQt')
  const { isSuccess: spfLoadOk, data: shopify } = useCQuery('shopify')

  const templateSPrice = (field) => ({ Codice }) => (shopify.find(s => s.sku === Codice) || { [field]: 'No' })[field] + ' €'
  const templateQt = ({ Id }) => (magazzinoQt && magazzinoQt.length ? magazzinoQt.find(mqt => mqt.IdProdotto === Id) || { Qt: 0 } : { Qt: 0 }).Qt
  const tempateSQt = ({ Codice }) => (shopify.find(s => s.sku === Codice) || { quantity: 'No' }).quantity
  const tempateRep = ({ Reparto }) => getReparto(Reparto)
  const tempateDesc = ({ Descrizione, Reparto }) => Reparto ? Descrizione : <Text value={`(SOLO B2B): ${Descrizione}`} color='red' />
  const filteredMagazzino = magazzino ? magazzino.filter(m => m.Id > 0 && (showShopify ? !!shopify?.find(s => s.sku === m.Codice) : true)) : []

  const templateOrd = (prod) => (
    <Button
      icon='plus' style={{ height: 20, width: 30, padding: 0, paddingLeft: 8, alignItems: 'center' }} onClick={(e) => {
        e.stopPropagation()
        modifyOrder('add', prod)
      }}
    />
  )

  const totalSum = (field) => getDisplayPrice(!magazzino ? 0 : (filteredTable || magazzino).reduce((tot, p) => tot + (templateQt({ Id: p.Id }) > 0 ? templateQt({ Id: p.Id }) * p[field] : 0), 0))

  const handleExport = () => {
    exportTable(showShopify ? 'magazzinoShopify' : 'magazzino', (filteredTable || filteredMagazzino).map(item => ({
      ...item,
      Qta: templateQt(item),
      ...(showShopify ? { PrComp: templateSPrice('priceCompare')(item), Prezzo: templateSPrice('price')(item), SyQta: tempateSQt(item) } : {})
    })))
  }

  const handleSyncShopify = async () => {
    setProcessingShopify(true)
    await FC.service('tools').get('syncShopify')
    setProcessingShopify(false)
    window.alert('Inviata email di riepilogo a ufficio@doggypop.com')
  }

  if (!magLoadOK || !magQtLoadOk || !spfLoadOk) return <Spinner />
  return (
    <Card fadeIn style={{ marginTop: 20, width: '100%' }}>
      <DataTable
        ref={datatable} value={filteredMagazzino} onValueChange={setFilteredTable}
        paginator rows={20} emptyMessage='Nessun Prodoto Trovato' style={{ minHeight: 500 }} globalFilter={gSearch}
        selectionMode='single' selection={prodotto} onRowSelect={({ data }) => cap.magazzino.patchState({ prodotto: data, prodottoSfuso: !!data.CodiceSfuso })}
        header={(
          <Flex row jb fw>
            <Input useState={[gSearch, setGSearch]} placeholder='Ricerca senza calcoli' style={{ width: 200 }} />

            <Flex style={{ width: 200 }}>
              <FlagField label='Mostra Shopify' checked={showShopify} onChange={setShowShopify} />
            </Flex>
            <Flex>
              {showShopify
                ? !processingShopify ? <Button icon='check' label='Sincronizza Shopify' width={220} onClick={handleSyncShopify} /> : <Spinner />
                : null}
            </Flex>

            <Flex js width={220} style={{ marginRight: 100 }}>
              <Text bold value={`Fornitore${filteredTable === null ? '' : ' (filtrato)'}: ${totalSum('PrezzoFornitore')} `} />
              <Text bold value={`Listino${filteredTable === null ? '' : ' (filtrato)'}: ${totalSum('PrezzoListino')}`} />
            </Flex>
            <Input
              useState={[newProductCode, setNewProductCode]} placeholder='Inserisci codice per nuovo prodotto'
              style={{ width: 280, marginRight: 20 }} onEnter={() => handleNewProdotto(magazzino, newProductCode) && setNewProductCode('')}
            />
            <Button icon='file-excel' label='Esporta Tabella' width={200} onClick={handleExport} />
          </Flex>
    )}
      >
        <Column field='Fornitore' header='Fornitore' style={{ width: 180 }} filter sortable filterElement={<Filtro label='Fornitore' datatable={datatable} />} />
        <Column field='Categoria' header='Categoria' style={{ width: 180 }} filter sortable filterElement={<Filtro label='Categoria' datatable={datatable} />} />
        <Column field='Reparto' header='Reparto' style={{ width: 120 }} sortable body={tempateRep} />
        <Column field='Descrizione' header='Descrizione' filter filterPlaceholder='Ricerca per Descrizione' sortable body={tempateDesc} />
        <Column field='Info' header='Info' style={{ width: 120 }} sortable />
        <Column field='Codice' header='Codice' style={{ width: 160 }} />
        <Column field='QtMinima' header='Qt Min.' style={{ width: 90 }} sortable />
        <Column header='Qt' style={{ width: 120 }} sortable body={templateQt} />

        {showShopify && <Column header='SY PrComp' style={{ width: 90 }} sortable body={templateSPrice('priceCompare')} />}
        {showShopify && <Column header='SY Prezzo' style={{ width: 90 }} sortable body={templateSPrice('price')} />}
        {showShopify && <Column header='SY Qt' style={{ width: 90 }} sortable body={tempateSQt} />}
        <Column header='Ord.' style={{ width: 60 }} body={templateOrd} />

      </DataTable>
    </Card>
  )
}
