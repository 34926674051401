import React from 'react'
import { getDisplayWeight, getDisplayPrice } from '../../Services'
import { Text, Field } from '../../Components'
import moment from 'moment'

const Prodotto = ({ prodotto: { IdProdotto, Qt, TotaleSingolo, PesoSfuso, Note, Prodotto } }) => {
  if (IdProdotto === -2) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
        <Text bold value={`${1} X ${getDisplayPrice(TotaleSingolo)}`} />
        <Text style={{ marginLeft: 7 }} value={Note || 'Spedizione Corriere'} />
      </div>
    )
  }
  if (IdProdotto === -1) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
        <Text bold value={`${1} X ${getDisplayPrice(TotaleSingolo)}`} />
        <Text style={{ marginLeft: 7 }} value={Note || 'Sconto'} />
      </div>
    )
  }
  if (IdProdotto === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
        <Text bold value={`${1} X ${getDisplayPrice(TotaleSingolo)}`} />
        <Text style={{ marginLeft: 7 }} value={Note || 'Prodotto Singolo'} />
      </div>
    )
  }
  if (IdProdotto > 0 && PesoSfuso === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
        <Text bold value={`${Qt} X ${getDisplayPrice(Prodotto.PrezzoListino)}`} />
        <Text style={{ marginLeft: 7 }} value={`(${Prodotto.Categoria})`} />
        <Text style={{ marginLeft: 7 }} value={Prodotto.Fornitore} />
        <Text style={{ marginLeft: 7 }} value='  --  ' />
        <Text style={{ marginLeft: 7 }} value={Note || Prodotto.Descrizione} />
      </div>
    )
  }
  if (IdProdotto > 0 && PesoSfuso > 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3 }}>
        <Text bold value={`${getDisplayWeight(PesoSfuso)} X ${getDisplayPrice(TotaleSingolo)}`} />
        <Text style={{ marginLeft: 7 }} value={`(${Prodotto.Categoria})`} />
        <Text style={{ marginLeft: 7 }} value={Prodotto.Fornitore} />
        <Text style={{ marginLeft: 7 }} value='  --  ' />
        <Text style={{ marginLeft: 7 }} value={Note || Prodotto.Descrizione} />
      </div>
    )
  }
  return JSON.stringify(TotaleSingolo)
}

export function Sale ({ sale }) {
  return (
    <div>
      <Field label='Vendita del:' value={`${moment(sale.SaleTime).format('DD/MM/YY [alle] hh:mm')} (${sale.Operatore})`} />
      <Field label='Stato Ordine:' value={sale.Evasa ? 'Evaso' : 'Non Evaso'} />
      <Field label='Cliente:' value={`${sale.Cliente.Nome} ${sale.Cliente.Cognome}`} />
      <Field label='Totale:' value={getDisplayPrice(sale.Totale)} />
      <Field label='Colli:' value={`${sale.Colli} (${sale.PesoKg} Kg)`} />
      <Field label='Bolla n:' value={sale.BollaDelivery} />
      <Text bold value='Prodotti:' style={{ marginTop: 20 }} />
      {sale.Prodotti.map((prodotto, index) => (
        <div key={index} style={{ marginTop: 0, paddingLeft: 40, paddingRight: 40 }}>
          <Prodotto prodotto={prodotto} />
        </div>))}
    </div>
  )
}
