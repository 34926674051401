import React from 'react'
import { Card, ClienteSelection } from '../Components'

// This is the page that shows the list of clients
export default function Clienti () {
  return (
    <Card fadeIn>
      <ClienteSelection />
    </Card>
  )
}
