import React, { useState } from 'react'
import { doLogin } from '../State'
import { logo } from '../Resources'

import { Card, Button, Input, Flex, Spinner } from '../Components'

export default function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)

  const handleSubmit = async () => {
    setWaiting(true)
    !(await doLogin({ strategy: 'local', username, password })) && setWaiting(false)
  }

  return (
    <Flex fh fw>
      <Card style={{ padding: 20 }} fadeIn>
        <Flex row style={{ margin: 30 }}>
          <img src={logo} alt='logoSmall' style={{ width: 200, height: 200, marginRight: 40 }} />
          <form>
            <Flex jb height={140} width={200}>
              <Input autofocus autoComplete='username' placeholder='Nome Utente' useState={[username, setUsername]} />
              <Input password useState={[password, setPassword]} onEnter={handleSubmit} />
              {waiting ? <Spinner /> : <Button label='Login' tooltip='prova' icon='sign-in-alt' onClick={handleSubmit} style={{ width: 200 }} />}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Flex>
  )
}
