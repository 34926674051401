import React, { useState, useRef, useEffect } from 'react'
import { useCapsule, cap, navigateTo } from '../../State'
import posed from 'react-pose'
import { useCQuery } from '../../Services'

import SlidingMenu from './SlidingMenu'
import { logo } from '../../Resources'

import { Text, Icon, Hover } from '..'
import { Flex } from '../Common/Flex'

const MenuBar = posed.div({
  visible: { y: 0, opacity: 1, transition: { y: { type: 'spring', stiffness: 50, damping: 15 }, default: 300 } },
  hidden: { y: -50, opacity: 0, transition: 300 }
})

export function TopMenu ({ licenses, history }) {
  const [page] = useCapsule(cap.page)
  const [user] = useCapsule(cap.user)
  const [cliente] = useCapsule(cap.cliente)
  const [{ sale: { Id, Operatore } }] = useCapsule(cap.vendita)
  const [isVisible, setVisible] = useState(false)
  const sliderMenu = useRef(null)
  const { isSuccess: evadereSuccess, data: oDaEvadere } = useCQuery('daEvadere')
  const { isSuccess: ritiroSuccess, data: oDaRitirare } = useCQuery('daRitirare')

  const daEvadere = evadereSuccess && oDaEvadere && oDaEvadere.length ? oDaEvadere.length : 0
  const daRitirare = ritiroSuccess && oDaRitirare && oDaRitirare.length ? oDaRitirare.length : 0

  useEffect(() => {
    const fadeInTimer = setTimeout(() => { setVisible(true) }, 200)
    return () => { fadeInTimer && clearTimeout(fadeInTimer) }
  }, [])

  const toggleMenu = state => () => sliderMenu && sliderMenu.current && sliderMenu.current.toggle(state)

  const pageTitle = !page ? null : (page.name !== 'Vendita' ? page.title : `${!Id ? 'NUOVA' : 'MODIFICA'} VENDITA${(cliente || {}).Piva ? ' B2B' : ''} DI ${Operatore || ''}`)

  if (page.noMenu) return null
  return (
    <div style={styles.containerStyle}>
      <SlidingMenu ref={sliderMenu} />
      <MenuBar style={styles.barStyle} pose={isVisible ? 'visible' : 'hidden'}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Hover rotateAnimation={90}>
            <Icon
              name='bars'
              size={30}
              style={{ marginLeft: 25, marginRight: 25 }}
              onMouseEnter={toggleMenu('mouseIn')}
              onMouseLeave={toggleMenu('mouseOut')}
              onClick={toggleMenu('click')}
            />
          </Hover>
          <div onMouseEnter={toggleMenu('mouseIn')} style={{ position: 'absolute', width: 60, height: 10, top: 40, left: 10 }} />

          <Text title bold size='20px' style={{ marginLeft: 10 }} value={(pageTitle || '').toUpperCase()} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {daEvadere > 0 && (
            <Flex
              style={{ backgroundColor: 'orange', cursor: 'pointer', height: 34, marginRight: 40, paddingLeft: 10, paddingRight: 10, borderRadius: 17 }}
              onClick={() => navigateTo('Bolle')}
            >
              <Text bold size='20px' value={`Ordini da Spedire: ${daEvadere}`} />

            </Flex>
          )}
          {daRitirare > 0 && (
            <Flex
              bg
              style={{ cursor: 'pointer', height: 34, marginRight: 40, paddingLeft: 10, paddingRight: 10, borderRadius: 17 }}
              onClick={() => navigateTo('Bolle')}
            >

              <Text bold size='20px' value={`Ordini in Ritiro: ${daRitirare}`} />

            </Flex>
          )}
          <Text bold size='20px' style={{ marginRight: 20 }} value='Doggypop' />
          {user.isDbProduction
            ? <img src={logo} alt='logoSmall' style={{ maxHeight: 36, maxWidth: 36, marginRight: 28 }} />
            : <Text size={12} value={'ris. ' + document.documentElement.clientWidth + ' x ' + document.documentElement.clientHeight} />}
        </div>
      </MenuBar>
    </div>
  )
}
const styles = {
  containerStyle: {
    width: '100%',
    height: 50,
    zIndex: 20,
    position: 'relative',
    color: '#32324e'
  },
  barStyle: {
    width: '100%',
    height: 50,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 2px 2px rgba(100, 100, 100, 0.5)',
    color: '#32324e',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}
