import React from 'react'
import { useMount, useUnmount } from 'react-use'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ScrollPanel } from 'primereact/scrollpanel'

import { useCapsule, cap } from '../State'
import ErrorHandler from './ErrorHandler'
import { TopMenu } from '../Components/Layout'
import { Background } from '../Components'
import { Toast } from 'primereact/toast'
import { Updater } from './Updater'
import ConnectionLost from './ConnectionLost'

export default function App () {
  const [Page] = useCapsule(cap.page)

  const setMessage = (message) => cap.cassa.setState({ message })
  useMount(() => window.ipcBridge && window.ipcBridge.registerListener(setMessage))
  useUnmount(() => window.ipcBridge && window.ipcBridge.unregisterListener())

  return (
    <Sentry.ErrorBoundary fallback={ErrorHandler}>
      <ConnectionLost />
      <Updater />
      <ReactQueryDevtools initialIsOpen={false} />
      <Toast ref={(el) => { window.growl = el }} style={{ marginTop: 50 }} />
      <Background>
        <TopMenu />
        <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 50px)', padding: 20 }} className='custom'>
          <Page.RenderPage />
        </ScrollPanel>
      </Background>
    </Sentry.ErrorBoundary>
  )
}
