import Bolle from './Bolle'
import Cassa from './Cassa'
import Clienti from './Clienti'
import Home from './Home'
import Login from './Login'
import Magazzino from './Magazzino'
import Statistiche from './Statistiche'
import Vendita from './Vendita'
import Affiliazioni from './Affiliazioni'

const Pages = {
  Bolle: {
    name: 'Bolle',
    title: 'BOLLE',
    RenderPage: Bolle
  },
  Cassa: {
    name: 'Cassa',
    title: 'CASSA',
    RenderPage: Cassa
  },
  Clienti: {
    name: 'Clienti',
    title: 'CLIENTI',
    RenderPage: Clienti
  },
  Home: {
    name: 'Home',
    title: 'HOME',
    RenderPage: Home
  },
  Login: {
    name: 'Login',
    title: '',
    RenderPage: Login,
    noMenu: true
  },
  Magazzino: {
    name: 'Magazzino',
    title: 'MAGAZZINO',
    RenderPage: Magazzino
  },
  Statistiche: {
    name: 'Statistiche',
    title: 'STATISTICHE',
    RenderPage: Statistiche
  },
  Vendita: {
    name: 'Vendita',
    title: 'VENDITA',
    RenderPage: Vendita
  },
  Affiliazioni: {
    name: 'Affiliazioni',
    title: 'AFFILIAZIONI',
    RenderPage: Affiliazioni
  }
}

export default Pages
