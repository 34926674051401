import React, { useState } from 'react'
import { useCQuery, getDisplayPrice, getPaymentOptions } from '../../Services'
import { Field, Card, Flex, Text, Password } from '../../Components'

export const CassaOggi = () => {
  const [passVisible, setPassVisible] = useState(false)
  const [passOK, setPassOK] = useState(false)

  const { data = [] } = useCQuery('todaySales')

  const saleDelivery = data.filter(s => s.IdCliente > -1 && s.BollaDelivery)
  const saleAmazon = data.filter(s => s.IdCliente === -1)
  const saleNegozio = data.filter(s => s.IdCliente > -1 && !s.BollaDelivery)

  const totAnonimi = saleNegozio.reduce((tot, s) => tot + (s.IdCliente === 0 ? 1 : 0), 0)
  const percentualeAnonimi = saleNegozio.length ? ((totAnonimi / saleNegozio.length) * 100).toFixed(2) + ' %' : '-- %'

  const totaleBT = data.reduce((tot, s) => tot + (s.Processato ? s.Totale : 0), 0)

  const getTotale = (sales) => sales.reduce((tot, s) => tot + s.Totale, 0)
  const getTotalePay = (method) => data.reduce((tot, s) => tot + (s.Pagamento === method ? s.Totale : 0), 0)

  const StatsDisplay = ({ title, stats }) => (
    <Flex as js style={{ marginLeft: 20 }}>
      <Text bold size={20} value={title} />
      {stats.map(([label, value], ind) => <Field label={`${label}:`} key={`${title}${ind}`} value={value} />)}
    </Flex>
  )

  return (
    <Card fadeIn collapsable collapsed title='STATISTICHE DI OGGI' style={{ width: '100%', marginTop: 20 }} onExpand={() => setPassVisible(true)}>
      <Password visibleState={[!passOK && passVisible, setPassVisible]} matchPassword='teamdoggy' handlePasswordOK={() => setPassOK(true)} />
      <Flex row fw js as>
        {!passOK
          ? <Text value='Statistiche non disponibili' />
          : (
            <>
              <StatsDisplay
                title='Negozio' stats={[
                  ['Numero Passaggi', saleNegozio.length],
                  ['Anonimi', percentualeAnonimi],
                  ['Totale Negozio', getDisplayPrice(getTotale(saleNegozio))]
                ]}
              />
              <StatsDisplay
                title='Online' stats={[
                  ['Vendite Delivery', saleDelivery.length],
                  ['Totale Delivery', getDisplayPrice(getTotale(saleDelivery))],
                  ['Vendite Amazon', saleAmazon.length],
                  ['Totale Amazon', getDisplayPrice(getTotale(saleAmazon))],
                  ['Totale Online', getDisplayPrice(getTotale(saleDelivery) + getTotale(saleAmazon))]
                ]}
              />
              <StatsDisplay
                title='Cassa' stats={[
                  ['Scontrinato', getDisplayPrice(totaleBT)],
                  ...(getPaymentOptions().map(({ label, value }) => [label, getDisplayPrice(getTotalePay(value))]))
                ]}
              />
            </>
            )}
      </Flex>
    </Card>
  )
}
