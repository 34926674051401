import React from 'react'

import { getDisplayPrice, getTotaleSconto, getScontoCarrello, useCQuery } from '../../Services'
import { useCapsule, cap } from '../../State'
import { Text, Flex, FlagField } from '../../Components'
import { arrow } from '../../Resources'
import { getSconto, getZampe } from './ProcedureVendita'

export const ScontoBox = () => {
  const [{ sale, carrello, addSconto, noSaleDiscount, affiliazione }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const idCliente = cliente ? cliente.Id : 0
  const { data: statsCliente } = useCQuery(['statsCliente', idCliente])

  if (!cliente || !statsCliente || idCliente < 1) return null

  const totalePunti = cliente.Punti + (sale.Id ? 0 : getTotaleSconto(carrello))
  const canSconto = totalePunti > 30000

  const affiliazioneConPunti = affiliazione && (affiliazione.TipoSconto === 2 || affiliazione.TipoSconto === 3)

  const handleAddSconto = (addSconto) => {
    const itemSconto = getScontoCarrello(affiliazione && affiliazione.TipoSconto !== 3 ? -affiliazione.Sconto * 100 : getSconto(getZampe(statsCliente)))
    cap.vendita.patchState({ carrello: addSconto ? [itemSconto, ...carrello] : carrello.filter(p => !p.isSconto), addSconto })
  }

  const hideBox = sale.isDelivery || noSaleDiscount || sale.Id || (affiliazione ? !affiliazioneConPunti : cliente.MaiSconto)

  if (hideBox) return null
  return (
    <>

      <Flex row width={180} style={boxScontoStyle(canSconto)}>
        <Text value='Punti: ' size={18} bold style={{ marginRight: 10 }} />
        <Text value={getDisplayPrice(addSconto ? 0 : totalePunti)} size={18} />
        {canSconto && <img alt='sc' style={{ position: 'absolute' }} src={arrow} />}
      </Flex>

      {canSconto && <FlagField label='Applica Sconto' checked={addSconto} onChange={handleAddSconto} />}

    </>
  )
}

const boxScontoStyle = (canSconto) => ({
  borderRadius: 15,
  height: 30,
  marginLeft: 20,
  marginRight: 20,
  backgroundColor: canSconto ? 'green' : 'white'
})
