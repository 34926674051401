import React, { useState } from 'react'
import { Text, Flex } from '../../Components'
import { parseInteger, getPrezzoDaPeso } from '../../Services'
import { SfoField, PriceField } from './ProcedureMagazzino'

export function ProdottoSfuso ({ stateProdotto: [prodotto, setProdotto] }) {
  const [prova, setProva] = useState('0')

  const updateField = e => setProdotto({ ...prodotto, ...e })

  const updatePeso = e => {
    const prop = Object.keys(e)[0]
    e[prop] = parseInteger(e[prop])
    setProdotto({ ...prodotto, ...e })
  }

  const getSku = (field) => `${prodotto.CodiceSfuso}${String(prodotto[field]).padStart(5, '0')}0`

  return (
    <Flex as jb style={{ width: 300, height: 400, marginLeft: 20 }}>
      <Text value='Configurazione dello sfuso:' style={{ marginLeft: 5 }} />
      <SfoField label='Codice Sfuso' field='CodiceSfuso' prodotto={prodotto} onChange={updateField} width={150} style={{ width: '100%' }} maxLength={7} />
      <PriceField label='Prezzo al Kg' field='PrezzoSfuso' prodotto={prodotto} onChange={updateField} width={150} style={{ width: '100%' }} />
      <SfoField label='Tolleranza in gr' field='Tolleranza' prodotto={prodotto} onChange={updatePeso} width={150} style={{ width: '100%' }} />

      <Text value={`Offerta 1: (SKU: ${getSku('Off1Prezzo')} )`} style={{ marginLeft: 5 }} />
      <Flex fw row jb>
        <SfoField label='Grammi' field='Off1Peso' prodotto={prodotto} onChange={updatePeso} />
        <PriceField label='Prezzo' field='Off1Prezzo' prodotto={prodotto} onChange={updateField} />
      </Flex>

      <Text value={`Offerta 2: (SKU: ${getSku('Off2Prezzo')} )`} style={{ marginLeft: 5 }} />
      <Flex fw row jb>
        <SfoField label='Grammi' field='Off2Peso' prodotto={prodotto} onChange={updatePeso} />
        <PriceField label='Prezzo' field='Off2Prezzo' prodotto={prodotto} onChange={updateField} />
      </Flex>

      <Text value={`Offerta 3: (SKU: ${getSku('Off3Prezzo')} )`} style={{ marginLeft: 5 }} />
      <Flex fw row jb>
        <SfoField label='Grammi' field='Off3Peso' prodotto={prodotto} onChange={updatePeso} />
        <PriceField label='Prezzo' field='Off3Prezzo' prodotto={prodotto} onChange={updateField} />
      </Flex>

      <Text value='Fai una prova del prezzo sfuso:' style={{ marginLeft: 5 }} />
      <Flex row fw jb>
        <SfoField label='Grammi' field='Prova' value={prova} onChange={(e) => setProva(parseInteger(e.Prova))} />
        <PriceField disabled label='Prezzo' field='ProvaPrice' value={getPrezzoDaPeso(prodotto, parseInteger(prova))} />
      </Flex>
    </Flex>
  )
}
