import React, { useState, useEffect } from 'react'

import { Input, Button, Text, Flex, DialogSaveDelete, FlagField, DateField, AniField } from '.'
import { FC, getEmptyCliente, getFullNomeCli, useCQuery } from '../Services'
import { cap } from '../State'
import { Spinner } from './Common/Spinner'
import { getAffiliazione } from '../Pages/Vendita/ProcedureVendita'

const CliField = ({ label, field, max, cliente, updateField, ...props }) =>
  <Input
    label={`${label}:`} placeholder={`Inserisci ${label}...`} {...props}
    id={field} value={cliente[field]} onChange={updateField} maxLength={max}
  />

export function ClienteEditor ({ visibleState: [visible, setVisible], expiredState, inputCliente, isVendita, isQuickEditor, onSelectionCompleted }) {
  const [cliente, setCliente] = useState({})
  const [processing, setProcessing] = useState(false)
  const isNewCli = !(inputCliente || {}).Id
  const [isExpired, setExpired] = expiredState || [false, () => null]
  const [isDeleting, setDelete] = useState(false)

  const { isSuccess, data: affiliazioni } = useCQuery('affiliazioni')

  useEffect(() => {
    const NomeConsegna = isNewCli ? '' : inputCliente.NomeConsegna || getFullNomeCli(inputCliente)
    setCliente(isNewCli ? getEmptyCliente() : { ...inputCliente, NomeConsegna })
  }, [inputCliente, isNewCli])

  const updateField = update => setCliente({ ...cliente, ...update })

  const handleSaveCliente = async () => {
    const { Id, LastSale, DataReg, ...fieldsCliente } = cliente
    if (!cliente.Nome || !cliente.Cognome) {
      window.growl.show({
        severity: 'error',
        summary: 'Dati Mancanti',
        detail: 'Inserire almeno nome e cognome'
      })
      return false
    }

    setProcessing(true)
    const savedCliente = isNewCli
      ? (await FC.service('clienti').create(fieldsCliente))
      : (await FC.service('clienti').patch(Id, fieldsCliente))

    if (isVendita) {
      cap.cliente.setState(savedCliente)
      cap.vendita.patchState({ affiliazione: getAffiliazione(savedCliente, affiliazioni), sale: { ...cap.vendita.state?.sale, ...(savedCliente.Piva ? { isDelivery: true } : {}) } })
    }

    FC.updateCache('clienti')
    setProcessing(false)
    setVisible(false)
    onSelectionCompleted && onSelectionCompleted()
  }

  const handleDeleteCliente = async () => {
    setProcessing(true)
    await FC.service('clienti').patch(cliente.Id, { Email: '##CANCELLATO##' })
    FC.updateCache('clienti')
    setProcessing(false)
    setVisible(false)
    setExpired(false)
  }

  const handleCloneCliente = async () => {
    const { Id, DataReg, IdCliente, LastSale, Punti, ...fieldsCliente } = cliente
    setProcessing(true)
    const savedCliente = await FC.service('clienti').create({ ...fieldsCliente, Punti: 0 })
    cap.cliente.setState(savedCliente)
    FC.updateCache('clienti')
    setProcessing(false)
    setVisible(false)
    setExpired(false)
    onSelectionCompleted && onSelectionCompleted()
  }

  const dialogFooterDelete = (
    <Flex fw jb row>
      <Text bold value='Sicuro di Eliminare ?' />
      <Flex jb row width={250}>
        <Button label='Annulla' icon='ban' onClick={() => setDelete(false)} />
        <Button label='Elimina' icon='trash' onClick={() => { setDelete(false); handleDeleteCliente() }} />
      </Flex>
    </Flex>
  )

  const dialogFooterExpired = (
    <Flex fw jb row>
      <Button label='Copia in nuovo Cliente e continua vendita' icon='check' onClick={handleCloneCliente} />
      <Button label='Chiudi' icon='ban' onClick={() => setVisible(false)} />
    </Flex>
  )

  const dialogFooter = isExpired
    ? dialogFooterExpired
    : !isVendita
        ? null
        : isDeleting
          ? dialogFooterDelete
          : (
            <Flex fw jb row>
              <Button label='Elimina' icon='trash' onClick={() => { setDelete(true) }} />

              <Flex fw je row>
                <Button label='Annulla' icon='ban' onClick={() => setVisible(false)} />
                <Button label='Salva e Continua' width={200} style={{ marginLeft: 20 }} icon='check' onClick={handleSaveCliente} />
              </Flex>
            </Flex>

            )

  const commonProps = { cliente, updateField }
  const optionsAffiliazione = [
    { label: 'Nessuna Affiliazione', value: 0 },
    ...(!isSuccess ? [] : affiliazioni?.map(({ Id, Nome }) => ({ label: Nome, value: Id })))
  ]

  return (
    <DialogSaveDelete
      visibleState={[visible, setVisible]} style={{ width: 900 }}
      onSave={handleSaveCliente} onDelete={isNewCli || isQuickEditor ? null : handleDeleteCliente}
      header={isExpired ? 'CLIENTE CON TESSERA ARCHIVIATA!' : ((isNewCli ? 'Nuovo Cliente' : 'Modifica Cliente') + (cliente.Piva ? ' Business' : ''))}
      isProcessing={processing} footer={dialogFooter}
    >
      <Flex row jb fw>
        <Flex width='33%' height={320} jb style={{ margin: 10 }}>
          <CliField label='Nome' field='Nome' {...commonProps} max={50} />
          <CliField label='Cognome' field='Cognome' {...commonProps} max={50} />
          <CliField label='Dettaglio' field='Email' {...commonProps} max={50} />
          <CliField label='Partita IVA' field='Piva' {...commonProps} max={30} />
          <FlagField label='Flag Antiparassitario' checked={cliente.Flag === 1} onChange={(checked) => updateField({ Flag: checked ? 1 : 0 })} />
          <AniField label='Cani Grandi' field='CaniTG' {...commonProps} />
          <AniField label='Cani Medi' field='CaniTM' {...commonProps} />
          <AniField label='Cani Piccoli' field='CaniTP' {...commonProps} />
          <AniField label='Gatti' field='Gatti' {...commonProps} />
        </Flex>

        <Flex width='33%' height={320} jb style={{ margin: 10 }}>
          <CliField label='SpedisciA' field='NomeConsegna' {...commonProps} max={50} />
          <CliField label='Telefono' field='Telefono' {...commonProps} max={12} />
          <CliField label='Indirizzo' field='IndVia' {...commonProps} max={100} />
          <CliField label='Città' field='Citta' {...commonProps} max={30} />
          <CliField label='CAP' field='IndCap' {...commonProps} max={8} />
          <CliField label='Provincia' field='IndProv' {...commonProps} max={5} />
          <DateField label='Data Registrazione' value={cliente.DataReg} />
          <DateField label='Data Ultimo Acquisto' value={cliente.LastSale} />
        </Flex>

        <Flex width='33%' height={300} je style={{ margin: 10 }}>
          {!isSuccess ? <Spinner /> : <CliField field='Affiliato' {...commonProps} dropdown options={optionsAffiliazione} style={{ marginBottom: 10 }} />}
          <Input multiline id='Nota' value={cliente.Nota || ''} onChange={updateField} maxLength={1024} placeholder='Note Cliente' />
        </Flex>
      </Flex>
    </DialogSaveDelete>
  )
}
