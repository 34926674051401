import { getUrlParams, FC, capitalize, validateJwt, prefetchQueries } from '../Services'
import { cap } from './Capsules'
import Pages from '../Pages'

export * from './Capsules'
export * from './StoredCapsule'

export const restoreState = async () => {
  const accessToken = validateJwt(cap.user.state.accessToken)
  if (!accessToken || !(await doLogin({ strategy: 'jwt', accessToken }))) return doLogout()

  navigateTo(capitalize(getUrlParams('view')) || cap.page.state.name)
}

export const doLogin = async (auth) => {
  const { user: { username }, accessToken } = await FC.login(auth)
  if (!accessToken) return false

  const db = await FC.checkConnection()
  if (!db) return doLogout()
  cap.user.setState({
    username,
    accessToken,
    isDbProduction: db.Valore === 'Database Produzione',
    isAdmin: username === 'admin',
    offline: false
  })

  prefetchQueries()
  auth.strategy === 'local' && navigateTo('Home')
  return true
}

export const doLogout = async () => cap.resetCapsules() && navigateTo('Login')

export const navigateTo = (pageName) => cap.page.setState(Pages[pageName] || Pages.Home)
