import React, { useState, useRef } from 'react'
import { Card, Icon, Input, Button, Spinner, Flex, DropDownCatForn, FlagField } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getDisplayPrice, getDisplayWeight, exportTable, useCQuery } from '../../Services'
import ProdottiChart from './ProdottiChart'

const EMPTY_SEARCH = { Categoria: '', Fornitore: '', Descrizione: '', isDelivery: false, isAmazon: false }
export const Prodotti = ({ range }) => {
  const [selectedItem, setSelectedItem] = useState({})
  const datatable = useRef(null)
  const [searchProd, setSearchProd] = useState(EMPTY_SEARCH)

  const { isSuccess: qtSuccess, data: magazzinoQt } = useCQuery('magazzinoQt')
  const { isSuccess: prodSuccess, data: prodotti } = useCQuery(['prodottiVenduti', { ...searchProd, ...range }])

  const templateEur = (rowData, { field }) => getDisplayPrice(rowData[field])
  const templateKg = (rowData, { field }) => getDisplayWeight(rowData[field])
  const templateTotEur = (rowData) => getDisplayPrice(rowData.TotSold + rowData.TotSfuso)
  const templateQt = ({ Id }) => magazzinoQt && magazzinoQt.length ? (magazzinoQt.find(mqt => mqt.IdProdotto === Id) || { Qt: 0 }).Qt : 0
  const templateCosto = ({ PrezzoFornitore, QtSold }) => getDisplayPrice(PrezzoFornitore * QtSold)
  const handleSearchUpdate = (upd) => setSearchProd({ ...searchProd, ...upd })

  const totalFields = (field, label) => {
    if (!prodotti) return <>{`${label}: 0`}</>
    const fields = {}
    prodotti.forEach(p => { fields[p[field]] = true })
    return <>{`${label}: ${Object.keys(fields).length}`}</>
  }

  const totalProds = () => <>{`Prodotti: ${prodotti ? prodotti.length : 0}`}</>

  const totalSum = (field, transform) => {
    if (!prodotti) return 0
    const sum = prodotti.reduce((tot, p) => tot + p[field], 0)
    return <>{transform ? transform(sum) : sum}</>
  }

  const totalCost = (transform) => {
    const sum = prodotti ? prodotti.reduce((tot, p) => tot + p.PrezzoFornitore * p.QtSold, 0) : 0
    return <>{transform ? transform(sum) : sum}</>
  }

  const handleExport = () => {
    exportTable('prodotti', prodotti.map(item => ({
      ...item,
      Qta: templateQt(item),
      TotGenerale: templateTotEur(item),
      TotSingolo: templateEur(item, { field: 'TotSold' }),
      KgSfuso: templateKg(item, { field: 'QtPeso' }),
      TotSfuso: templateEur(item, { field: 'TotSfuso' })
    })))
  }

  return (
    <>
      <Card style={{ marginTop: 20, width: '100%' }}>
        <Flex row jb fw>
          <Flex row jb width={1200}>
            <Icon name='search' size={30} />
            <DropDownCatForn value={searchProd.Categoria} field='Categoria' onChange={handleSearchUpdate} style={{ width: 350 }} />
            <DropDownCatForn value={searchProd.Fornitore} field='Fornitore' onChange={handleSearchUpdate} style={{ width: 250 }} />
            <Input id='Descrizione' value={searchProd.Descrizione} onChange={handleSearchUpdate} placeholder='Descrizione...' style={{ width: 200 }} />
            <Flex row jb style={{ marginLeft: 40, width: 300 }}>
              <FlagField label='Delivery' checked={searchProd.isDelivery} onChange={isDelivery => handleSearchUpdate({ isDelivery })} />
              <FlagField label='Amazon' checked={searchProd.isAmazon} onChange={isAmazon => handleSearchUpdate({ isAmazon })} />
            </Flex>
          </Flex>
          <Flex row jb width={360}>
            <Button icon='ban' label='Pulisci Ricerca' onClick={() => setSearchProd(EMPTY_SEARCH)} />
            <Button icon='file-excel' label='Esporta Tabella' onClick={() => prodSuccess && qtSuccess && handleExport()} />
          </Flex>
        </Flex>

      </Card>

      <ProdottiChart range={range} searchProd={searchProd} />

      {!prodSuccess || !qtSuccess
        ? <Spinner />
        : (
          <Card style={{ marginTop: 20, width: '100%' }}>
            <DataTable
              value={prodotti} paginator rows={20} emptyMessage='Nessun Prodotto Trovato'
              ref={datatable} autoLayout
              onSelectionChange={({ value }) => setSelectedItem(value)}
              selectionMode='single' selection={selectedItem} onRowSelect={({ data: { Fornitore, Categoria, Descrizione } }) => setSearchProd({ Fornitore, Categoria, Descrizione })}
            >
              <Column field='Categoria' header='Categoria' sortable style={{ }} filter filterElement={totalFields('Categoria', 'Categorie')} />
              <Column field='Fornitore' header='Fornitore' filterPlaceholder='Filtra...' sortable style={{ }} filter filterElement={totalFields('Fornitore', 'Fornitori')} />
              <Column field='Descrizione' header='Descrizione' filterPlaceholder='Filtra per Descrizione' sortable filter filterElement={totalProds()} />
              <Column header='Qt Magaz.' sortable style={{ textAlign: 'right' }} body={templateQt} />
              <Column field='QtSoldSfuso' header='Vendite Sfuso' sortable style={{ textAlign: 'right' }} filter filterElement={totalSum('QtSoldSfuso')} />
              <Column field='QtPeso' header='Kg Sfuso' sortable style={{ textAlign: 'right' }} body={templateKg} filter filterElement={totalSum('QtPeso', getDisplayWeight)} />
              <Column field='TotSfuso' header='Euro Sfuso' sortable style={{ textAlign: 'right' }} body={templateEur} filter filterElement={totalSum('TotSfuso', getDisplayPrice)} />
              <Column field='QtSold' header='Totale Singoli' sortable style={{ textAlign: 'right' }} filter filterElement={totalSum('QtSold')} />
              <Column field='TotSold' header='Euro Singoli' sortable style={{ textAlign: 'right' }} body={templateEur} filter filterElement={totalSum('TotSold', getDisplayPrice)} />
              <Column header='Costo Singoli' sortable style={{ textAlign: 'right' }} body={templateCosto} filter filterElement={totalCost(getDisplayPrice)} />
              <Column field='Totale' header='Totale Generale' sortable style={{ textAlign: 'right' }} body={templateTotEur} filter filterElement={totalSum('Totale', getDisplayPrice)} />
            </DataTable>
          </Card>
          )}
    </>
  )
}
