import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { cap } from '../../State'
import { getDisplayWeight, getReparto, getDisplayPrice, parsePrice } from '../../Services'

const updateCarrello = (index, update) => cap.vendita.patchState({
  carrello: cap.vendita.state.carrello.map((prod, ind) => ind === index ? { ...prod, ...update } : prod)
})

export const QtEditor = ({ magazzino, info: { rowData, rowIndex, value } }) => {
  const [val, setVal] = useState(rowData.Qt)

  if (rowData.PesoSfuso) return getDisplayWeight(rowData.PesoSfuso)
  if (rowData.IdProdotto === -1 || rowData.IdProdotto === 0) return '1'

  const prod = magazzino.find(({ Id }) => Id === rowData.IdProdotto) || { PrezzoListino: 100000 }
  const qtUpdate = { Qt: parseInt(val), TotaleSingolo: parseInt(val) * (rowData.prezzoCustom || prod.PrezzoListino) }

  return (
    <InputText
      type='text' value={val}
      onBlur={(e) => updateCarrello(rowIndex, qtUpdate)}
      onKeyDown={(key) => key.keyCode === 13 && updateCarrello(rowIndex, qtUpdate)}
      onChange={(e) => setVal(parseInt(e.target.value) || '')}
      style={{ width: '50px' }}
      onFocus={({ target }) => target.select()}
    />
  )
}

export const RepartoEditor = ({ info: { rowData, rowIndex } }) => {
  return (
    <InputNumber
      readonly showButtons min={1} max={6}
      prefix={getReparto(rowData.Reparto) + ': '} value={rowData.Reparto}
      onChange={({ value }) => updateCarrello(rowIndex, { Reparto: value || 1 })}
      inputStyle={{ width: 110 }}
    />
  )
}

export const descriptionTemplate = (magazzino) => (rowData) => {
  if (rowData.Note) return rowData.Note
  if (rowData.TotaleSingolo < 0) return 'Sconto'
  if (rowData.IdProdotto === 0) return 'Servizio'
  const prod = magazzino?.find(({ Id }) => Id === rowData.IdProdotto)
  return `${rowData.PesoSfuso > 0 ? 'SFUSO - ' : ''}${prod ? prod.Descrizione : 'NON TROVATO!!'}`
}

export const DescriptionEditor = ({ magazzino, info: { rowData, rowIndex, value } }) => {
  const description = descriptionTemplate(magazzino)(rowData)
  const [val, setVal] = useState(description)

  return (
    <InputText
      type='text' value={val}
      onBlur={(e) => updateCarrello(rowIndex, { Note: val })}
      onKeyDown={(key) => key.keyCode === 13 && updateCarrello(rowIndex, { Note: val })}
      onChange={(e) => setVal(e.target.value || '')}
      style={{ width: '270px' }}
      onFocus={({ target }) => target.select()}
    />
  )
}

export const PriceEditor = ({ info: { rowData, rowIndex } }) => {
  console.log('renderPrice', rowData)
  const [val, setVal] = useState(getDisplayPrice(rowData.TotaleSingolo / rowData.Qt).split(' ')[0])
  if (rowData.IdProdotto === -1 || rowData.IdProdotto === 0) return ''
  if (rowData.PesoSfuso > 0) return getDisplayPrice(rowData.TotaleSingolo)

  const priceUpdate = { prezzoCustom: parsePrice(val), TotaleSingolo: parseInt(rowData.Qt) * parsePrice(val) }

  return (
    <InputText
      type='text' value={val}
      onBlur={() => updateCarrello(rowIndex, priceUpdate)}
      onKeyDown={(key) => key.keyCode === 13 && updateCarrello(rowIndex, priceUpdate)}
      onChange={(e) => setVal(e.target.value || '')}
      style={{ width: '70px' }}
      onFocus={({ target }) => target.select()}
    />
  )
}
