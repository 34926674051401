import React, { useState, useRef, useEffect } from 'react'

import { OverlayPanel } from 'primereact/overlaypanel'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Input, Text } from '.'
import { getDisplayPrice, getPesoDaPrezzo, FC, isLargeScreen, useCQuery } from '../Services'
import { cap } from '../State'

import _ from 'lodash'

const fixComma = (price) => price.replace(',', '.')
const isPrice = (value) => (value.length <= 6 || value.startsWith('-')) && !isNaN(parseFloat(fixComma(value.trim()))) && /^-?\d+\.?\d*$/.test(fixComma(value.trim()))
const isSfuso = (value) => value.startsWith('21') && value.length === 13
const getPrezzoFromText = (text) => parseInt(parseFloat(fixComma(text.trim())) * 100)

export function SearchBox ({ onSelection: doOnSelection, returnMagazzino, style, placeholder }) {
  const [searchBox, setSearchBox] = useState('')
  const overlayRef = useRef(null)
  const inputDivRef = useRef(null)

  const { data: magazzino } = useCQuery('magazzino')

  const showDialog = () => {
    if (!overlayRef || !overlayRef.current || !inputDivRef || !inputDivRef.current) return false
    overlayRef.current.show(null, inputDivRef.current)

    if (!overlayRef.current.container) return false
    const { current: { container: { style } } } = overlayRef
    style.top = '30px'
    style.left = '0px'
    style.right = '0px'
  }

  const delayedCall = useRef(_.debounce(showDialog, 500)).current
  const isFindProdotto = cap.page.state.name === 'Vendita' && cap.vendita.state.findProdotto
  useEffect(() => { isFindProdotto && setTimeout(showDialog, 200) }, [isFindProdotto])

  const hideDialog = () => isFindProdotto && cap.vendita.patchState({ findProdotto: '' })

  const onSelection = (prod) => doOnSelection && doOnSelection(prod, magazzino)

  const handleSearchEnter = () => {
    delayedCall.cancel()
    if (!searchBox) return false
    if (isPrice(searchBox)) {
      !returnMagazzino && onSelection({
        IdProdotto: getPrezzoFromText(searchBox) < 0 ? -1 : 0,
        Qt: 1,
        TotaleSingolo: getPrezzoFromText(searchBox),
        ValePunti: 1,
        Reparto: getPrezzoFromText(searchBox) < 0 ? 1 : 3,
        PesoSfuso: 0
      })
      overlayRef.current.hide()
      return setSearchBox('')
    }
    if (isSfuso(searchBox)) { // 2100083011559
      if (returnMagazzino) return setSearchBox('')
      const prodotto = magazzino.find(({ CodiceSfuso }) => CodiceSfuso === searchBox.substring(0, 7))
      if (!prodotto) return false
      const PesoSfuso = getPesoDaPrezzo(prodotto, parseInt(searchBox.substring(7, 12)))
      onSelection({ IdProdotto: prodotto.Id, Qt: 1, TotaleSingolo: parseInt(searchBox.substring(7, 12)), ValePunti: 1, Reparto: 1, PesoSfuso })
      overlayRef.current.hide()
      return setSearchBox('')
    }

    const prodotto = magazzino?.find(({ Codice }) => Codice.toLowerCase() === searchBox.replace(/'/g, '-').toLowerCase())

    if (!prodotto) { // 5060084820395
      returnMagazzino && FC.service('listino').find({ query: { Codice: searchBox } }).then(([found]) => {
        if (!found) return false
        onSelection({ Id: false, Codice: searchBox })
        overlayRef.current.hide()
        setSearchBox('')
      })
      return false
    }
    returnMagazzino
      ? onSelection(prodotto)
      : onSelection({ IdProdotto: prodotto.Id, Qt: 1, TotaleSingolo: prodotto.PrezzoListino, ValePunti: 1, Reparto: prodotto.Reparto, PesoSfuso: 0 })
    setSearchBox('')
    overlayRef.current.hide()
  }

  const onSearchChange = (value) => {
    !value.length && overlayRef.current.hide()
    value.length > 1 && delayedCall(value)
    ;(!value || isPrice(value) || isSfuso(value)) && delayedCall.cancel()
  }

  const onProductSelect = ({ data }) => {
    overlayRef.current.hide()
    setSearchBox('')
    returnMagazzino
      ? onSelection(data)
      : onSelection({ IdProdotto: data.Id, Qt: 1, TotaleSingolo: data.PrezzoListino, ValePunti: 1, Reparto: data.Reparto, PesoSfuso: 0 })
  }

  const tempateDesc = ({ Descrizione, Reparto }) => Reparto ? Descrizione : <Text value={`(SOLO B2B): ${Descrizione}`} color='red' />
  const isB2B = cap.cliente.state && cap.cliente.state.Id && cap.cliente.state.Piva
  const filterSearch = ({ Id, Reparto }) => Id > 0 && (isB2B || Reparto)
  return (
    <div style={{ width: '100%', position: 'relative', ...style }}>
      <div ref={inputDivRef} style={{ width: '100%' }}>
        <Input
          id='searchBox' autofocus
          useState={[searchBox, setSearchBox]} placeholder={placeholder}
          onChange={onSearchChange} onEnter={handleSearchEnter}
          disabled={magazzino === null}
        />
      </div>
      <OverlayPanel ref={overlayRef} dismissable={false} showCloseIcon onHide={hideDialog}>
        <DataTable
          emptyMessage='Nessun Prodotto Trovato' style={{ width: '100%' }}
          value={magazzino && magazzino.length ? magazzino.filter(filterSearch) : []}
          paginator rows={isLargeScreen() ? 12 : 8} globalFilter={searchBox} selectionMode='single' onRowSelect={onProductSelect}
        >
          <Column field='Categoria' header='Categoria' style={{ width: 120 }} />
          <Column field='Fornitore' header='Fornitore' style={{ width: 150 }} />
          <Column field='Descrizione' header='Descrizione' body={tempateDesc} />
          <Column field='Info' header='Info' style={{ width: 120 }} />
          <Column header='Prezzo' style={{ width: 90, textAlign: 'right' }} body={rowData => getDisplayPrice(rowData.PrezzoListino)} />
        </DataTable>
      </OverlayPanel>
    </div>
  )
}
