import { FC, tipoAffiliazione, getDisplayPrice, getDisplayWeight } from '../../Services'
import { cap, navigateTo } from '../../State'
import moment from 'moment'

export const updateCliente = (cliente, update) => {
  cap.cliente.patchState(update)
  cliente && cliente.Id > 0 && FC.service('clienti').patch(cliente.Id, update)
}

export const setCurrentSale = async (IdVendita, noRedirect) => {
  const [{ Cliente: cli, Prodotti, ...fields }, affiliazioni] = await Promise.all([
    FC.service('sales').get(IdVendita, { query: { joinReferences: true } }),
    FC.service('affiliazioni').find()
  ])

  const cliente = cli.Id === 0 ? { Id: 0, Nome: 'Cliente', Cognome: 'Anonimo' } : cli
  const carrello = Prodotti.map(({ Prodotto, ...pro }) => {
    pro.TotaleSingolo < 0 && (pro.isSconto = true)
    if (pro.PesoSfuso > 0 || !pro.Qt || pro.IdProdotto === -1 || pro.IdProdotto === 0) return pro
    Prodotto.PrezzoListino !== parseInt(pro.TotaleSingolo / pro.Qt) && (pro.prezzoCustom = parseInt(pro.TotaleSingolo / pro.Qt))
    return pro
  })
  cap.cliente.setState(cliente)
  cap.vendita.setState({
    sale: { isDelivery: fields.BollaDelivery > 0, ...fields },
    carrello,
    cassa: noRedirect ? cap.vendita.state.cassa : {},
    addSconto: noRedirect ? cap.vendita.state.addSconto : false,
    noSaleDiscount: noRedirect ? cap.vendita.state.addSconto : false,
    findProdotto: '',
    affiliazione: getAffiliazione(cliente, affiliazioni)
  })
  !noRedirect && navigateTo('Vendita')
}

export const handleAddProdotto = (prod, magazzino) => {
  if (!prod || prod.IdProdotto === undefined) return false
  const { carrello } = cap.vendita.state
  const currentIndex = carrello.findIndex(({ IdProdotto, PesoSfuso }) => IdProdotto === prod.IdProdotto && !PesoSfuso)
  const isNew = currentIndex === -1 || prod.IdProdotto < 1 || prod.PesoSfuso > 0
  if (!isNew) {
    const prodMag = magazzino.find(({ Id }) => Id === prod.IdProdotto)
    if (!prodMag) return false
    carrello[currentIndex].Qt += 1
    carrello[currentIndex].TotaleSingolo = carrello[currentIndex].Qt * (carrello[currentIndex].prezzoCustom || prodMag.PrezzoListino)
  }
  cap.vendita.patchState({ carrello: isNew ? [prod, ...carrello] : carrello })
}

export const processaVendita = () => processVenditaChange(cap.vendita.state.sale.Id ? 'update' : 'create')
export const cancellaVendita = () => processVenditaChange('remove')

const processVenditaChange = async (action) => {
  const result = await FC.service('vendita')[action]({ cliente: cap.cliente.state, vendita: cap.vendita.state }, {})
  const IdSale = action === 'create' ? (result ? result.IdSale || 0 : 0) || 0 : cap.vendita.state.sale.Id

  FC.log({ IdTarget: IdSale, EventType: action + 'Vendita', Data: { action, result, vendita: cap.vendita.state, cliente: cap.cliente.state } }, result ? !result.OK : true)
  if (!result || !result.OK) {
    cap.vendita.reset()
    cap.cliente.reset()
    window.growl.show({ severity: 'error', summary: 'Errore Salvataggio Vendita', detail: 'Ripetere Operazione' })
    navigateTo('Home')
    return false
  }
  FC.updateCache(['clienti', 'magazzinoQt', 'bolle', 'movimenti', 'lastSales', 'todaySales', ['storicoCliente', cap.cliente.state.Id], ['statsCliente', cap.cliente.state.Id], 'prodottiVenduti', ['statistiche', 'venditenegozio'], ['statistiche', 'venditeonline']])

  cap.vendita.patchState({ cassa: { action, IdSale } })
  navigateTo('Cassa')
}

export const getSconto = (zampe) => {
  switch (Math.floor(zampe)) {
    case 3:
      return -700
    case 4:
      return -800
    case 5:
      return -1000
    default:
      return -500
  }
}

export const getZampe = (stats) => {
  let zampe = 0
  if (stats.Passaggi < 2 || stats.SMG === 0) return zampe
  if (stats.SMG <= 70) {
    zampe = ((stats.SMG - 30) * 100 / 40) * 0.025
  } else {
    zampe = (stats.SMG > 300) ? 4 : ((stats.SMG - 70) * 100 / 230) * 0.025 + 1.5
  }

  stats.Anni === 1 && (zampe += 0.5)
  stats.Anni === 2 && (zampe += 1)
  stats.Anni > 2 && (zampe += 1.5)

  zampe > 5 && (zampe = 5)
  zampe < 0 && (zampe = 0)

  stats.Anni === 0 && stats.Mesi < 2 && zampe > 2 && (zampe = 2)
  return parseFloat(zampe.toFixed(2))
}

export const getAffiliazione = (cliente, affiliazioni) => {
  const affiliazione = cliente && cliente.Affiliato ? (affiliazioni.find(({ Id, Attivo }) => Attivo && Id === cliente.Affiliato)) : null
  affiliazione && (affiliazione.scontoLabel = [
    tipoAffiliazione[0].label,
    `${affiliazione.Sconto}% ${tipoAffiliazione[1].label}`,
    `${affiliazione.Sconto} ${tipoAffiliazione[2].label}`,
    tipoAffiliazione[3].label
  ][affiliazione.TipoSconto])
  return affiliazione
}

export const getSalesFromStorico = (storico) => {
  if (!storico || !storico.length) return []
  const sales = {}
  storico.forEach(s => {
    !sales[s.Id] && (sales[s.Id] = { prodotti: [] })
    sales[s.Id].SaleTime = moment(s.SaleTime).format('DD/MM/YY [alle] HH:mm')
    sales[s.Id].Pagamento = s.Pagamento
    sales[s.Id].Totale = s.Totale
    const item = {
      PesoSfuso: s.PesoSfuso,
      Descrizione: s.Note || s.Descrizione,
      Qt: s.Qt,
      TotaleSingolo: s.TotaleSingolo,
      IdProdotto: s.IdProdotto
    }
    sales[s.Id].prodotti = [...sales[s.Id].prodotti, item]
  })
  return Object.values(sales).reverse()
}

export const getDisplayProduct = (p) => {
  if (p.IdProdotto < 0) return '❤️ Sconto: ' + getDisplayPrice(-p.TotaleSingolo)
  if (p.PesoSfuso > 0) return '🌽 ' + getDisplayWeight(p.PesoSfuso) + ` (${getDisplayPrice(p.TotaleSingolo)}) ` + p.Descrizione.substr(0, 30)
  return `${p.Qt} X ${p.Descrizione.substr(0, 30)} - ${getDisplayPrice(p.TotaleSingolo)}`
}
