import { useState, useEffect } from 'react'
import axios from 'axios'
import { FC, getStringHash } from '../Services'

const client = axios.create({ baseURL: window.location.href, timeout: 10000 })

const CHECK_INTERVAL = 30 * 1000

export function Updater () {
  const [indexHash, setIndexHash] = useState(false)
  useEffect(() => {
    const checkUpdate = () => {
      client.get('/')
        .then(({ data, status }) => {
          if (status !== 200) return false
          const dataHash = getStringHash(data)
          !FC.isDev && indexHash && indexHash !== dataHash && window.location.reload()
          indexHash !== dataHash && setIndexHash(dataHash)
        })
        .catch(console.log)
    }

    const checkTimer = setInterval(checkUpdate, CHECK_INTERVAL)
    return () => { checkTimer && clearInterval(checkTimer) }
  }, [indexHash])
  return null
}
