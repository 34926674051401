import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import App from './App'
import Bolla from './Pages/Bolla'

import { restoreState } from './State'
import { getUrlParams } from './Services'

import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

const enableSentryInDev = false

const isDevelopment = process.env.NODE_ENV && process.env.NODE_ENV === 'development'

;(!isDevelopment || enableSentryInDev) && Sentry.init({
  dsn: 'https://9c2e7cda2f004b0882ee6a16615b95cd@o480278.ingest.sentry.io/5527042',
  release: 'dogmanager-' + process.env.NODE_ENV
})

const singlePages = {
  bolla: Bolla
}

const Application = singlePages[getUrlParams('view')] || App
restoreState().then(() => { ReactDOM.render(<Application />, document.getElementById('root')) })
