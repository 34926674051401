
const background = require('./background.jpg').default
const logo = require('./logo.png').default
const loading = require('./loading.gif').default
const paw = require('./paw.png').default
const satispay = require('./sati.png').default
const arrow = require('./arrow.gif').default

export {
  background,
  logo,
  loading,
  paw,
  satispay,
  arrow
}
