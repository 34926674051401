import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faUsers,
  faTruck,
  faSignOutAlt,
  faSearch,
  faPlus,
  faShoppingBasket,
  faUser,
  faPencilAlt,
  faArrowDown,
  faBan,
  faSync,
  faBoxes,
  faCode,
  faArrowUp,
  faFileExcel,
  faChartPie,
  faUnlock,
  faBars,
  faSignInAlt,
  faExclamationTriangle,
  faPrint,
  faCheck,
  faTrash,
  faChevronUp,
  faTimes,
  faMoneyBillAlt,
  faCashRegister,
  faGlobeAfrica
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faHome,
  faUsers,
  faTruck,
  faSignOutAlt,
  faSearch,
  faPlus,
  faShoppingBasket,
  faUser,
  faPencilAlt,
  faArrowDown,
  faBan,
  faSync,
  faBoxes,
  faCode,
  faArrowUp,
  faFileExcel,
  faChartPie,
  faUnlock,
  faSignInAlt,
  faExclamationTriangle,
  faPrint,
  faCheck,
  faTrash,
  faChevronUp,
  faTimes,
  faMoneyBillAlt,
  faCashRegister,
  faGlobeAfrica
)

export function Icon ({ name, size, style, color, ...props }) {
  return (
    <FontAwesomeIcon {...props} icon={name} style={{ fontSize: size || 36, color, ...style }} />
  )
}
