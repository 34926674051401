import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { cap, useCapsule } from '../../State'
import { Button, Text, Flex } from '../../Components'
import { ProField, RoField } from './ProcedureMagazzino'
import { getEmptyMove, useCQuery } from '../../Services'

export function ProdottoQuantity ({ stateProdotto: [prodotto, setProdotto] }) {
  const [{ prodottoSfuso, action }] = useCapsule(cap.magazzino)
  const { isSuccess, data: magazzinoQt } = useCQuery('magazzinoQt')

  const setProdottoSfuso = (prodottoSfuso) => cap.magazzino.patchState({ prodottoSfuso })
  const updateField = e => setProdotto({ ...prodotto, ...e })

  const handleSetAction = (action) => {
    cap.magazzino.patchState({ action })
    setTimeout(() => {
      const qtInput = document.getElementById('actionQt')
      qtInput && qtInput.focus && qtInput.focus()
    }, 400)
  }

  const qtProd = isSuccess && magazzinoQt && magazzinoQt.length ? magazzinoQt.find(mqt => mqt.IdProdotto === prodotto.Id) || getEmptyMove() : getEmptyMove()

  return (
    <>
      <Flex row fw js>
        <Text value='Quantità prodotto:' style={{ marginLeft: 5, marginRight: 20 }} />
        <Checkbox checked={prodottoSfuso} onChange={({ checked }) => { setProdottoSfuso(checked); updateField({ CodiceSfuso: '' }) }} />
        <Text value='Prodotto Sfuso ' style={{ marginLeft: 10 }} />
      </Flex>

      <Flex row fw jb>
        <Flex row width='48%' jb>
          <RoField label='Caricate' value={qtProd.TotCarichi} prodotto={prodotto} />
          <RoField label='Scaricate' value={qtProd.TotScarichi} prodotto={prodotto} />
        </Flex>

        <Flex row width='48%' jb>
          <RoField label='Totale' value={qtProd.Qt} prodotto={prodotto} />
          {action ? <div /> : <Button label='Rettifica' width='48%' onClick={() => handleSetAction('rettifica')} />}
        </Flex>
      </Flex>

      <Flex row fw jb>
        <ProField label='Qt. Minima' field='QtMinima' prodotto={prodotto} onChange={updateField} width={60} style={{ width: 185 }} />
        {action ? <div /> : <Button label='Carica' width='32%' onClick={() => handleSetAction('carico')} />}
        {action ? <div /> : <Button label='Scarica' width='32%' onClick={() => handleSetAction('scarico')} />}
      </Flex>
    </>
  )
}
