import React from 'react'
import { Card, Flex } from '../../Components'
import { CassaOggi } from './CassaOggi'
import { VenditeTable } from './VenditeTable'
import { NewSale } from './NewSale'

export default function Home () {
  return (
    <Flex fw as row>
      <Flex js fw style={{ marginRight: 20 }}>
        <NewSale />
        <CassaOggi />
      </Flex>
      <Card fadeIn style={{ width: 640 }}>
        <VenditeTable />
      </Card>
    </Flex>
  )
}
