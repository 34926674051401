const forceZeroAndPositive = value => Math.abs(isNaN(value) ? 0 : value)

export const parsePrice = price => forceZeroAndPositive(Math.round(parseFloat(price.replace(',', '.').trim()) * 100))

export const parseInteger = value => forceZeroAndPositive(parseInt(value))

export const getPriceKgDaPrezzo = (prod, price) => {
  if (!prod || !price) return 0
  let prezzoKg = prod.PrezzoSfuso
  prod.Off1Prezzo && prod.Off1Peso && price >= prod.Off1Prezzo && (prezzoKg = (prod.Off1Prezzo * 1000) / prod.Off1Peso)
  prod.Off2Prezzo && prod.Off2Peso && price >= prod.Off2Prezzo && (prezzoKg = (prod.Off2Prezzo * 1000) / prod.Off2Peso)
  prod.Off3Prezzo && prod.Off3Peso && price >= prod.Off3Prezzo && (prezzoKg = (prod.Off3Prezzo * 1000) / prod.Off3Peso)
  return parseInt(prezzoKg)
}

export const getPesoDaPrezzo = (prod, price) => {
  if (!prod || !price || !prod.PrezzoSfuso) return 0
  let peso = (price * 1000) / prod.PrezzoSfuso
  prod.Off1Prezzo && prod.Off1Peso && price >= prod.Off1Prezzo && (peso = (price * 1000) / ((prod.Off1Prezzo * 1000) / prod.Off1Peso))
  prod.Off2Prezzo && prod.Off2Peso && price >= prod.Off2Prezzo && (peso = (price * 1000) / ((prod.Off2Prezzo * 1000) / prod.Off2Peso))
  prod.Off3Prezzo && prod.Off3Peso && price >= prod.Off3Prezzo && (peso = (price * 1000) / ((prod.Off3Prezzo * 1000) / prod.Off3Peso))
  return Math.floor(peso)
}

export const getPrezzoDaPeso = (prod, peso) => {
  let priceKg = prod.PrezzoSfuso
  prod.Off1Prezzo && prod.Off1Peso && peso > (prod.Off1Peso - prod.Tolleranza) && (priceKg = prod.Off1Prezzo / (prod.Off1Peso / 1000))
  prod.Off2Prezzo && prod.Off2Peso && peso > (prod.Off2Peso - prod.Tolleranza) && (priceKg = prod.Off2Prezzo / (prod.Off2Peso / 1000))
  prod.Off3Prezzo && prod.Off3Peso && peso > (prod.Off3Peso - prod.Tolleranza) && (priceKg = prod.Off3Prezzo / (prod.Off3Peso / 1000))

  const prezzo = Math.round((priceKg * peso) / 1000)
  return prezzo
}

export const getDisplayPrice = (prezzo) => {
  if (!prezzo || isNaN(parseInt(prezzo))) return '0,00 €'
  const isNegative = prezzo < 0
  const num = Math.abs(parseInt(prezzo))
  const cents = ',' + `${(num % 100)}`.padStart(2, '0') + ' €'
  const parts = []
  let euro = Math.floor(num / 100)
  while (euro) {
    parts.push(`${(euro % 1000)}`.padStart(3, '0'))
    euro = Math.floor(euro / 1000)
  }
  const euroPart = parts.length ? parts.reverse().join('.').replace(/^0+/, '') : '0'
  return (isNegative ? '- ' : '') + euroPart + cents
}

export const getDisplayWeight = (peso) => {
  if (peso < 1000) return `0,${Math.floor(peso / 100)} Kg`
  const kg = ~~(peso / 1000)
  const grams = Math.floor((peso % 1000) / 100)
  return grams ? `${kg},${grams} Kg` : `${kg} Kg`
}
