import React, { useState } from 'react'
import posed from 'react-pose'

export function FadeIn ({ enabled, style, children }) {
  if (!enabled) return children
  return (
    <div className='fadeinclass' style={style}>
      {children}
    </div>
  )
}

export function Hover ({ reversed, style, children, rotateAnimation = 0 }) {
  const [hovering, setHovering] = useState('idle')
  return (
    <HoverContainer
      style={{ cursor: 'pointer', ...style }}
      pose={hovering}
      onMouseEnter={() => setHovering('hovered')}
      onMouseLeave={() => setHovering('idle')}
      rotateAnimation={rotateAnimation}
    >
      {children}
    </HoverContainer>
  )
}

const HoverContainer = posed.div({
  idle: { scale: 1, rotate: 0 },
  hovered: { scale: 1.2, rotate: (props) => props.rotateAnimation }
})
