import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Rating } from 'primereact/rating'

import { Text, Flex, Spinner } from '../'
import { useCQuery } from '../../Services'
import moment from 'moment'

export function Field ({ label, value, style, size }) {
  return (
    <Flex row js style={{ marginTop: 3, ...style }}>
      <Text size={size} bold value={label} />
      <Text size={size} style={{ marginLeft: 7 }} value={value} />
    </Flex>
  )
}

export function FlagField ({ label, checked, onChange, style }) {
  return (
    <Flex row fw={!!style} js style={style || { marginTop: 3 }}>
      <Text bold value={`${label}: `} style={{ marginRight: 10 }} />
      <Checkbox checked={!!checked} onChange={({ checked }) => onChange(checked)} />
    </Flex>
  )
}

export function AniField ({ cliente, onChange, label, field }) {
  return (
    <Flex row jb fw>
      <Text value={`${label}: `} />
      <Rating value={cliente[field]} onChange={({ value }) => onChange({ [field]: value || 0 })} cancel />
    </Flex>
  )
}

export function DateField ({ label, value }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
      <Text value={`${label}: `} />
      <Text value={value === null ? 'Mai Fatto Acquisti' : moment(value).format('DD/MM/YYYY')} />
    </div>
  )
}

export function DropDownCatForn ({ style, field, value, onChange }) {
  const { isSuccess, data } = useCQuery('magCatForn')
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        paddingLeft: 12,
        alignItems: 'center',
        border: 'none',
        borderRadius: 20,
        justifyContent: 'space-between',
        ...style
      }}
    >
      <Text value={`${field}:`} color='rgb(120,120,120)' />
      {!isSuccess ? <Spinner size={30} /> : (
        <Dropdown
          editable placeholder={`Inserisci ${field}...`} style={{ width: '80%' }}
          dropdown id={field} value={value}
          onChange={({ value }) => onChange({ [field]: value })} options={data[field]}
        />

      )}
    </div>
  )
}
