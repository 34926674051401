import React, { useState } from 'react'

import { useCQuery, getFullNomeCli, getDisplayPrice, isLargeScreen } from '../../Services'
import { Spinner, Text, Button, Flex } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { setCurrentSale } from '../../Pages/Vendita/ProcedureVendita'
import moment from 'moment'

export function VenditeTable () {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [sale, setSale] = useState({})

  const { data = [], isLoading } = useCQuery('lastSales')

  const nameTemplate = (rowData, { field }) => <Text value={getFullNomeCli(rowData.Cliente)} />
  const dateTemplate = (rowData, { field }) => <Text value={moment(rowData[field]).format('DD/MM/YYYY [alle] HH:mm')} />
  const priceTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={getDisplayPrice(rowData[field])} />

  return (
    <>
      {isLoading ? <Spinner /> : (
        <DataTable
          style={{ width: 600 }}
          value={data} paginator rows={isLargeScreen() ? 20 : 12} emptyMessage='Nessuna Vendita Trovata' header={<Text value='ULTIME VENDITE' bold size={18} />}
          selectionMode='single' selection={sale} onSelectionChange={({ value }) => setSale(value)} onRowSelect={() => setDialogVisible(true)}
        >
          <Column field='nomeCliente' header='Cliente' body={nameTemplate} />
          <Column field='Operatore' header='Operatore' style={{ width: 100 }} />
          <Column field='SaleTime' header='Vendita Del' body={dateTemplate} style={{ width: 180 }} />
          <Column field='Totale' header='Totale' body={priceTemplate} style={{ width: 100 }} />
        </DataTable>
      )}
      <Dialog
        visible={dialogVisible} onHide={() => setDialogVisible(false)} header='Modifica Vendita' modal footer={
          <Flex row fw jb width={380}>
            <Button label='Annulla' icon='ban' onClick={() => setDialogVisible(false)} />
            <Button label='Modifica Vendita' icon='pencil-alt' onClick={() => setCurrentSale(sale.Id)} />
          </Flex>
        }
      >
        <Text value='Vuoi modificare la vendita?' />
      </Dialog>
    </>
  )
}
