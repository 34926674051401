import { StoredCapsule } from './StoredCapsule'
import { objectMap } from '../Services'
import _ from 'lodash'

const capsules = {
  page: {
    name: 'Login'
  },
  user: {
    accessToken: '',
    username: '',
    isAdmin: false,
    isDbProduction: true,
    offline: false
  },
  magazzino: {
    caricoProdotti: [],
    prodotto: null,
    prodottoSfuso: false,
    action: ''
  },
  online: {
    prodotto: null
  },
  cliente: null,
  vendita: {
    sale: {
      Pagamento: 'contanti'
    },
    findProdotto: '',
    carrello: [],
    cassa: {},
    addSconto: false,
    noSaleDiscount: false,
    affiliazione: null
  },
  cassa: {
    message: ''
  },
  ordine: []
}

const cap = objectMap(capsules, (key, value) => new StoredCapsule(value, key))

cap.dumpStates = () => objectMap(cap, (key, value) => _.omit(value.state, 'accessToken'))
cap.resetCapsules = () => Object.values(cap).map(c => typeof cap === 'object' && c.reset && c.reset())

export { cap }
