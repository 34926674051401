import React, { useState } from 'react'
import { Card, Input, Button, Field, FlagField, Flex } from '../../Components'

import { getShipment, useCQuery } from '../../Services'
import { useCapsule, cap } from '../../State'

import { handleAddProdotto } from './ProcedureVendita'

export function Delivery () {
  const [{ sale }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)
  const [colli, setColli] = useState(sale.Colli || '')
  const [peso, setPeso] = useState(sale.PesoKg || '')
  const [notaCorr, setNotaCorr] = useState(sale.Nota || '')
  const [vAmazon, setVAmazon] = useState(sale.VenditeAmazon || '1')

  const { status: statusMag, data: magazzino } = useCQuery('magazzino')

  const updateVendita = (update) => cap.vendita.patchState({ sale: { ...sale, ...update } })

  if (!cliente || !cliente.Id || statusMag === 'loading') return null

  if (cliente.Id === -1) {
    return (
      <Card title='INSERISCI NUMERO VENDITE' style={{ width: 380 }}>
        <Field label='Inserire il numero di vendite Amazon: ' value='' style={{ marginTop: 10 }} />
        <Input
          style={{ width: '100%', marginTop: 10 }}
          label='Vendite / Colli:' id='vAmazon' value={vAmazon}
          onBlur={() => updateVendita({ VenditeAmazon: parseInt(vAmazon) })}
          onChange={({ vAmazon }) => setVAmazon(vAmazon)} maxLength={2}
        />
      </Card>
    )
  }

  return (
    <Card title={`DELIVERY${cliente.Piva ? ' (B2B)' : ''}`} collapsable collapsed={!sale.isDelivery} style={{ width: 380 }}>
      {cliente.Piva
        ? null
        : <FlagField label='Spedisci al Cliente' checked={sale.isDelivery} onChange={(isDelivery) => updateVendita({ isDelivery })} />}
      <Field label='Consegnare A: ' value={cliente.NomeConsegna} />
      <Field label='Indirizzo: ' value={cliente.IndVia} />
      <Field label='Luogo: ' value={`${cliente.IndCap || ''} ${cliente.Citta || ''} (${cliente.IndProv || ''})`} />
      <Field label='Telefono: ' value={cliente.Telefono} />
      {cliente.Piva && <Field label='Partita IVA: ' value={cliente.Piva} />}
      <Flex row jb style={{ marginTop: 10, marginBottom: 10, padding: 3 }}>
        <Input
          style={{ width: '48%' }}
          label='Colli:' placeholder='Quanti Colli?' id='Colli' value={colli}
          onBlur={() => updateVendita({ Colli: parseInt(colli) || 0 })}
          onChange={({ Colli }) => setColli(Colli)} maxLength={3}
        />
        <Input
          style={{ width: '48%' }}
          label='Peso:' placeholder='Quanti Kili?' id='PesoKg' value={peso}
          onBlur={() => updateVendita({ PesoKg: parseInt(peso) || 0 })}
          onChange={({ PesoKg }) => setPeso(PesoKg)} maxLength={4}
        />
      </Flex>
      <Field label='Nota per Corriere: ' value='' />
      <Input
        useState={[notaCorr, setNotaCorr]}
        multiline maxLength={250}
        onBlur={() => updateVendita({ Nota: notaCorr })}
        style={{ height: 170, marginTop: 10, maxHeight: 170 }}
      />
      <Button
        icon='truck' label='Aggiungi Spedizione' width='88%' style={{ margin: 20 }}
        onClick={() => handleAddProdotto(magazzino, getShipment())}
      />
    </Card>
  )
}
