import React, { useState, useEffect } from 'react'
import posed from 'react-pose'
import { Text, Flex, Icon, FadeIn } from '../'

const transition = { default: { duration: 400 } }
const CardContainer = posed.div({
  open: { height: 'auto', opacity: 1, marginTop: 10, transition },
  closed: { height: 0, opacity: 0, marginTop: 0, transition }
})

const ChevronContainer = posed.div({
  open: { rotate: 0, transition },
  closed: { rotate: 180, transition }
})

export function Card ({ fadeIn, collapsable, title, titleCollapsed, titleStyle, collapsed, children, style, customHeader, onExpand }) {
  const [state, setState] = useState('closed')
  const isCollapsed = state === 'closed'

  const toggleCollapse = (newState) => {
    const applyNewState = newState || (isCollapsed ? 'open' : 'closed')
    setState(applyNewState)
    applyNewState === 'open' && onExpand && onExpand()
  }

  useEffect(() => { collapsable && setState(collapsed ? 'closed' : 'open') }, [collapsable, collapsed])

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 20,
    padding: 20,
    boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)',
    ...(collapsable ? { position: 'relative' } : {}),
    ...(collapsable && isCollapsed && !customHeader ? { cursor: 'pointer' } : {}),
    ...style
  }

  return (
    <FadeIn enabled={fadeIn} style={{ ...(style ? { width: style.width, height: style.height } : {}) }}>
      <div style={cardStyle} onClick={isCollapsed && !customHeader ? () => toggleCollapse('open') : null}>
        {!customHeader && !title
          ? null
          : (
            <Flex row jb fw>
              <Flex row js>
                {title ? <Text title bold value={titleCollapsed ? (isCollapsed ? titleCollapsed : title) : title} style={{ ...titleStyle, marginRight: 20 }} size={20} /> : null}
                {customHeader}
              </Flex>
              {collapsable && (
                <ChevronContainer pose={state} style={{ cursor: 'pointer' }} onClick={() => (customHeader || !isCollapsed) && toggleCollapse()}>
                  <Icon name='chevron-up' />
                </ChevronContainer>
              )}
            </Flex>
            )}
        {!collapsable
          ? children
          : (
            <CardContainer style={{ overflow: 'hidden' }} pose={state}>
              {children}
            </CardContainer>
            )}
      </div>
    </FadeIn>
  )
}
