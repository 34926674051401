import React, { useState } from 'react'
import { Card, Flex, Text } from '../../Components'
import { useCQuery } from '../../Services'
import { Chart } from 'primereact/chart'
import { Checkbox } from 'primereact/checkbox'
import { RadioButton } from 'primereact/radiobutton'
import moment from 'moment'

const dows = [
  'Boh?',
  'Domenica',
  'Lunedí',
  'Martedí',
  'Mercoledí',
  'Giovedí',
  'Vernerdí',
  'Sabato'
]

/* const isoDows = [
  'Lunedí',
  'Martedí',
  'Mercoledí',
  'Giovedí',
  'Vernerdí',
  'Sabato',
  'Domenica'
]

const colorsList = [
  '#3366FF',
  '#CC33FF',
  '#33CCFF',
  '#03A89E',
  '#FF3366',
  '#33FF33',
  '#FF4000',
  '#FFDE7A',
  '#238E68',
  '#283A90',
  '#FFCC11',
  '#FFC0CB'
] */

export default function ProdottiChart ({ range }) {
  const [chartPeriod, setChartPeriod] = useState('day')
  const [chartPeriodWeek, setChartPeriodWeek] = useState('week')
  const [showDelivery, setShowDelivery] = useState(true)
  const [showAnonimi, setShowAnonimi] = useState(true)
  const [showMorning, setShowMorning] = useState(true)

  const { data: weeklyData } = useCQuery(['statistiche', 'venditesettimana', range])
  const { data: weeklySales } = useCQuery(['statistiche', 'trendsettimana', { ...range, period: chartPeriodWeek }])
  const { data } = useCQuery(['statistiche', 'vendite', { ...range, period: chartPeriod }])
  // const { data: passaggi } = useCQuery(['statPassaggiOrari', 'passaggiOrari', range])

  const FlagStat = ({ value, setValue, label }) => (
    <Flex row>
      <Checkbox checked={value} onChange={({ checked }) => setValue(checked)} />
      <Text value={label} style={{ marginLeft: 5 }} />
    </Flex>
  )

  const OptStat = ({ value, name, setValue, getValue }) => (
    <Flex row>
      <RadioButton value={value} name={name} onChange={(e) => setValue(e.value)} checked={getValue === value} />
      <Text value={name} style={{ marginLeft: 5 }} />
    </Flex>
  )

  const getChartText = () => {
    const period = { day: 'Giornaliere ', week: 'Settimanali ', month: 'Mensili ' }
    const dateRange = ` dal ${moment(range.from).format('D/M/YY')} al ${moment(range.to).format('D/M/YY')}`
    const label = 'Totale Vendite ' + period[chartPeriod] + dateRange
    return label
  }

  const formatDayChart = (stime, selPeriod) => {
    const upCasePar = (par) => par ? par.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ') : ''
    if (selPeriod === 'day') return upCasePar(moment(stime).format('dd D MMM'))
    if (selPeriod === 'week') return upCasePar(moment(stime, 'YYYY-WW').format('D') + '-' + moment(stime, 'YYYY-WW').add(6, 'days').format('D MMM'))
    if (selPeriod === 'month') return upCasePar(moment(stime).format('MMM YYYY'))
  }

  const barClienti = showAnonimi ? [
    { type: 'bar', label: 'Clienti', backgroundColor: '#4CAF50', data: data && data.map(d => d.PassaggiClienti) },
    { type: 'bar', label: 'Anonimi', backgroundColor: '#DD73F5', data: data && data.map(d => d.PassaggiAnonimi) }
  ] : []

  const barOrario = showMorning ? [
    { type: 'bar', label: 'Mattino', backgroundColor: '#F5ED3F', data: data && data.map(d => d.PassaggiMattina) },
    { type: 'bar', label: 'Pomeriggio', backgroundColor: '#F5772F', data: data && data.map(d => d.PassaggiPomeriggio) }
  ] : []

  const lineClienti = { type: 'line', label: 'Totale Passaggi', borderColor: '#2196F3', fill: false, data: data && data.map(d => d.TotalePassaggi) }

  const lineDelivery = showDelivery ? [
    { type: 'line', label: 'In Negozio', borderColor: '#B54CF5', fill: false, data: data && data.map(d => d.TotClienti + d.TotAnonimo) },
    { type: 'line', label: 'Online', borderColor: '#F2413A', fill: false, data: data && data.map(d => d.TotDelivery + d.TotAmazon) }
  ] : []

  const lineTotale = { type: 'line', label: 'Totale Generale', borderColor: '#2196F3', fill: false, data: data && data.map(d => d.Totale) }

  const barClientiWeek = showAnonimi ? [
    { type: 'bar', label: 'Clienti', backgroundColor: '#4CAF50', data: weeklyData && weeklyData.map(d => d.PassaggiClienti) },
    { type: 'bar', label: 'Anonimi', backgroundColor: '#DD73F5', data: weeklyData && weeklyData.map(d => d.PassaggiAnonimi) }
  ] : []

  const barOrarioWeek = showMorning ? [
    { type: 'bar', label: 'Mattino', backgroundColor: '#F5ED3F', data: weeklyData && weeklyData.map(d => d.PassaggiMattina) },
    { type: 'bar', label: 'Pomeriggio', backgroundColor: '#F5772F', data: weeklyData && weeklyData.map(d => d.PassaggiPomeriggio) }
  ] : []

  const lineClientiWeek = { type: 'bar', label: 'Totale Passaggi', backgroundColor: '#2196F3', fill: false, data: weeklyData && weeklyData.map(d => d.TotalePassaggi) }

  const lineDeliveryWeek = showDelivery ? [
    { type: 'bar', label: 'In Negozio', backgroundColor: '#B54CF5', fill: false, data: weeklyData && weeklyData.map(d => d.TotLocal) },
    { type: 'bar', label: 'Delivery', backgroundColor: '#F2413A', fill: false, data: weeklyData && weeklyData.map(d => d.TotDelivery) }
  ] : []

  const lineTotaleWeek = { type: 'bar', label: 'Totale Del Giorno', backgroundColor: '#2196F3', fill: false, data: weeklyData && weeklyData.map(d => d.Totale) }

  const linearWeekly = [
    { type: 'line', label: dows[2], borderColor: '#F5F043', fill: false, data: weeklySales && weeklySales.map(d => d.TotLun) },
    { type: 'line', label: dows[3], borderColor: '#A793F5', fill: false, data: weeklySales && weeklySales.map(d => d.TotMar) },
    { type: 'line', label: dows[4], borderColor: '#1AF52C', fill: false, data: weeklySales && weeklySales.map(d => d.TotMer) },
    { type: 'line', label: dows[5], borderColor: '#4ADEF5', fill: false, data: weeklySales && weeklySales.map(d => d.TotGio) },
    { type: 'line', label: dows[6], borderColor: '#F55444', fill: false, data: weeklySales && weeklySales.map(d => d.TotVen) },
    { type: 'line', label: dows[7], borderColor: '#482FF5', fill: false, data: weeklySales && weeklySales.map(d => d.TotSat) }
  ]

  return (
    <>
      <Card title='GRAFICO VENDITE' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

        <Flex row fw jb as width={590}>
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Giornaliero' value='day' />
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Settimanale' value='week' />
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Mensile' value='month' />
          <FlagStat label='Mostra Online' value={showDelivery} setValue={setShowDelivery} />
        </Flex>

        <Chart
          type='bar' data={{
            labels: data && data.map(({ stime }) => formatDayChart(stime, chartPeriod)),
            datasets: [lineTotale, ...lineDelivery]
          }}
          options={{
            responsive: true,
            title: { display: true, text: getChartText() },
            tooltips: { mode: 'index', intersect: true },
            scales: { yAxes: [{ ticks: { callback: (value, index, values) => '€' + value } }] }
          }}
        />
      </Card>

      <Card title='GRAFICO PASSAGGI IN NEGOZIO' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

        <Flex row fw jb as width={590}>
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Giornaliero' value='day' />
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Settimanale' value='week' />
          <OptStat getValue={chartPeriod} setValue={setChartPeriod} name='Mensile' value='month' />
          <FlagStat label='Mostra Anonimi' value={showAnonimi} setValue={setShowAnonimi} />
          <FlagStat label='Mostra Orario' value={showMorning} setValue={setShowMorning} />

        </Flex>
        <Chart
          type='bar' data={{
            labels: data && data.map(({ stime }) => formatDayChart(stime, chartPeriod)),
            datasets: [...barClienti, ...barOrario, lineClienti]
          }}
          options={{
            responsive: true,
            title: { display: true, text: getChartText() },
            tooltips: { mode: 'index', intersect: true }
          }}
        />
      </Card>

      <Card title='GRAFICO VENITE SETTIMANA' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

        <Flex row fw jb as width={590}>
          <FlagStat label='Mostra Delivery' value={showDelivery} setValue={setShowDelivery} />
        </Flex>

        <Chart
          type='bar' data={{
            labels: weeklyData && weeklyData.map(({ dow }) => dows[parseInt(dow)]),
            datasets: [lineTotaleWeek, ...lineDeliveryWeek]
          }}
          options={{
            responsive: true,
            title: { display: true, text: getChartText() },
            tooltips: { mode: 'index', intersect: true }
          }}
        />
      </Card>

      <Card title='GRAFICO PASSAGGI SETTIMANA' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

        <Flex row fw jb as width={300}>
          <FlagStat label='Mostra Anonimi' value={showAnonimi} setValue={setShowAnonimi} />
          <FlagStat label='Mostra Orario' value={showMorning} setValue={setShowMorning} />
        </Flex>
        <Chart
          type='bar' data={{
            labels: weeklyData && weeklyData.map(({ dow }) => dows[parseInt(dow)]),
            datasets: [...barClientiWeek, ...barOrarioWeek, lineClientiWeek]
          }}
          options={{
            responsive: true,
            title: { display: true, text: getChartText() },
            tooltips: { mode: 'index', intersect: true }
          }}
        />
      </Card>

      <Card title='GRAFICO VENDITE SETTIMANA LINEARE' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

        <Flex row fw jb as width={300}>
          <OptStat getValue={chartPeriodWeek} setValue={setChartPeriodWeek} name='Settimanale' value='week' />
          <OptStat getValue={chartPeriodWeek} setValue={setChartPeriodWeek} name='Mensile' value='month' />
        </Flex>

        <Chart
          type='bar' data={{
            labels: weeklySales && weeklySales.map(({ stime }) => formatDayChart(stime, chartPeriodWeek)),
            datasets: [...linearWeekly]
          }}
          options={{
            responsive: true,
            title: { display: true, text: getChartText() },
            tooltips: { mode: 'index', intersect: true },
            scales: { yAxes: [{ ticks: { callback: (value, index, values) => '€' + value } }] }
          }}
        />
      </Card>
    </>
  )
}
