import { useEffect, useState } from 'react'
import { useCapsule, cap } from '../../State'
import { getScontoCarrello, getTotaleSconto } from '../../Services'

export function Affiliazione () {
  const [{ carrello, affiliazione = {}, noSaleDiscount }] = useCapsule(cap.vendita)
  const isScontoPercentuale = affiliazione && affiliazione.TipoSconto === 1
  const newTotale = getTotaleSconto(carrello)
  const [totale, setTotale] = useState(newTotale)

  useEffect(() => {
    const scontoMissing = !carrello.find(p => p.isSconto)
    if (!noSaleDiscount && isScontoPercentuale && (newTotale !== totale || scontoMissing)) {
      const sconto = -Math.round(newTotale * (affiliazione.Sconto / 100))
      cap.vendita.patchState({ carrello: [...carrello.filter(p => !p.isSconto), getScontoCarrello(sconto)] })
      setTotale(newTotale)
    }
  }, [noSaleDiscount, isScontoPercentuale, totale, newTotale, affiliazione, carrello])
  return null
}
