import React, { useState, useEffect } from 'react'
import { Card, Icon, Text, Flex, Input, Button } from '../'

export function Password ({ visibleState, matchPassword, handlePasswordOK, noCancel }) {
  const [visible, setVisible] = useState(true)
  const [pass, setPass] = useState('')
  useEffect(() => visibleState && visibleState.length && setVisible(visibleState[0]), [visibleState])

  const setVisibility = (state) => {
    visibleState && visibleState[1](state)
    setVisible(state)
  }

  const handleSubmit = () => {
    if (pass === matchPassword) {
      setVisibility(false)
      handlePasswordOK()
    }
    setPass('')
  }

  return !visible ? null : (
    <Flex fw fh style={containerStyle}>
      <Card fadeIn>
        <form>
          <Flex width={300} row jb>
            <Icon name='unlock' size={80} />
            <Flex width={240} height={80}>
              <Text size={24} value='Inserire Password' />
              <input id='username' type='text' name='username' autoComplete='username' style={{ visibility: 'hidden' }} />
              <Flex row fw jb style={{ paddingLeft: 20 }}>
                <Input password autofocus useState={[pass, setPass]} style={{ height: 30, width: noCancel ? 200 : 170 }} onEnter={handleSubmit} />
                {!noCancel && <Button round icon='times' onClick={() => setVisibility(false)} />}
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Card>
    </Flex>
  )
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0, 0, 0.6)',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 100
}
