import React from 'react'
import { Card, Button, Spinner, Flex, Text } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getDisplayPrice, exportTable, useCQuery } from '../../Services'
import moment from 'moment'
import VenditeChart from './VenditeChart'

export const Vendite = ({ range }) => {
  const { status: statusVn, data: venditeNegozio } = useCQuery(['statistiche', 'venditenegozio', range])
  const { status: statusVo, data: venditeOnline } = useCQuery(['statistiche', 'venditeonline', range])

  const templateEur = (rowData, { field }) => getDisplayPrice(rowData[field] * 100)
  const totPassaggi = ({ VenditeAmazon, VenditeDelivery, VenditeShopify }) => VenditeAmazon + VenditeDelivery + VenditeShopify

  const parseDate = date => moment(date).format('DD/MM/YYYY')

  return statusVn === 'loading' || statusVo === 'loading'
    ? <Spinner />
    : (
      <>
        <VenditeChart range={range} />

        <Card style={{ marginTop: 20, width: '100%' }}>
          <DataTable
            value={venditeNegozio} paginator rows={20} emptyMessage='Nessuna Vendita Trovata'
            header={
              <Flex row fw jb>
                <Text bold value={`Vendite in Negozio dal ${parseDate(range.from)} al ${parseDate(range.to)}`} />
                <Button icon='file-excel' label='Esporta Tabella' onClick={() => exportTable('venditeNegozio', venditeNegozio)} />
              </Flex>
          }
          >
            <Column field='stime' header='Giorno' sortable body={(rowData) => parseDate(rowData.stime)} />
            <Column field='PassaggiClienti' header='Clienti' sortable style={{ textAlign: 'center' }} />
            <Column field='PassaggiAnonimi' header='Anonimi' sortable style={{ textAlign: 'center' }} />
            <Column field='PassaggiMattina' header='Mattino' sortable style={{ textAlign: 'center' }} />
            <Column field='PassaggiPomeriggio' header='Pomeriggio' sortable style={{ textAlign: 'center' }} />
            <Column field='TotalePassaggi' header='Totale' sortable style={{ textAlign: 'center' }} />
            <Column field='TotClienti' header='Da Clienti' sortable body={templateEur} style={{ textAlign: 'right' }} />
            <Column field='TotAnonimo' header='Da Anonimo' sortable body={templateEur} style={{ textAlign: 'right' }} />
            <Column field='Totale' header='Totale' sortable body={templateEur} style={{ textAlign: 'right' }} />
          </DataTable>
        </Card>

        <Card style={{ marginTop: 20, width: '100%' }}>
          <DataTable
            value={venditeOnline} paginator rows={20} emptyMessage='Nessuna Vendita Trovata'
            header={
              <Flex row fw jb>
                <Text bold value={`Vendite Online dal ${parseDate(range.from)} al ${parseDate(range.to)}`} />
                <Button icon='file-excel' label='Esporta Tabella' onClick={() => exportTable('venditeAmazon', venditeOnline)} />
              </Flex>
          }
          >
            <Column field='stime' header='Giorno' sortable body={(rowData) => parseDate(rowData.stime)} />
            <Column field='VenditeAmazon' header='N. Amazon' sortable style={{ textAlign: 'center' }} />
            <Column field='VenditeDelivery' header='N. Delivery' sortable style={{ textAlign: 'center' }} />
            <Column field='VenditeShopify' header='N. Shopify' sortable style={{ textAlign: 'center' }} />
            <Column field='TotalePassaggiOnline' header='Tot' body={totPassaggi} sortable style={{ textAlign: 'center' }} />
            <Column field='TotAmazon' header='Da Amazon' sortable body={templateEur} style={{ textAlign: 'right' }} />
            <Column field='TotDelivery' header='Da Delivery' sortable body={templateEur} style={{ textAlign: 'right' }} />
            <Column field='TotShopify' header='Da Shopify' sortable body={templateEur} style={{ textAlign: 'right' }} />
            <Column field='Totale' header='Totale' sortable body={templateEur} style={{ textAlign: 'right' }} />
          </DataTable>
        </Card>
      </>
      )
}
