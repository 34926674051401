import copy from 'clipboard-copy'
import moment from 'moment'
import { getDisplayPrice } from './ParseDisplay'

export const copyToClipboard = (text) => {
  copy(text)
    .then(() => window.growl.show({ severity: 'success', summary: 'Tabella Copiata negli appunti' }))
    .catch(() => window.growl.show({ severity: 'error', summary: 'Errore nella copia negli appunti' }))
}

const parseDate = date => moment(date).format('DD/MM/YYYY')

const structures = {
  magazzino: [
    ['Fornitore', v => v.Fornitore],
    ['Categoria', v => v.Categoria],
    ['Descrizione', v => v.Descrizione],
    ['Info', v => v.Info],
    ['Codice', v => '\'' + v.Codice],
    ['QtMin', v => v.QtMinima],
    ['Qta', v => v.Qta],
    ['Prezzo Fornitore Cents', v => v.PrezzoFornitore],
    ['Prezzo Listino Cents', v => v.PrezzoListino]
  ],

  magazzinoShopify: [
    ['Fornitore', v => v.Fornitore],
    ['Categoria', v => v.Categoria],
    ['Descrizione', v => v.Descrizione],
    ['Info', v => v.Info],
    ['Codice', v => '\'' + v.Codice],
    ['QtMin', v => v.QtMinima],
    ['Qta', v => v.Qta],
    ['SY PrComp', v => v.PrComp],
    ['SY Prezzo', v => v.Prezzo],
    ['SY Qt', v => v.SyQta],
    ['Prezzo Fornitore Cents', v => v.PrezzoFornitore],
    ['Prezzo Listino Cents', v => v.PrezzoListino]
  ],

  prodotti: [
    ['Categoria', v => v.Categoria],
    ['Fornitore', v => v.Fornitore],
    ['Descrizione', v => v.Descrizione],
    ['QtMagaz.', v => v.Qta],
    ['Vendite Sfuso', v => v.QtSoldSfuso],
    ['Kg Sfuso', v => v.KgSfuso.slice(0, -3)],
    ['Euro Sfuso', v => v.TotSfuso.slice(0, -2)],
    ['Totale Singoli', v => v.QtSold],
    ['Euro Singoli', v => v.TotSingolo.slice(0, -2)],
    ['Totale Generale', v => v.TotGenerale.slice(0, -2)]
  ],

  venditeNegozio: [
    ['Giorno', v => parseDate(v.stime)],
    ['Clienti', v => v.PassaggiClienti],
    ['Anonimi', v => v.PassaggiAnonimi],
    ['Mattino', v => v.PassaggiMattina],
    ['Pomeriggio', v => v.PassaggiPomeriggio],
    ['Totale Passaggi', v => v.TotalePassaggi],
    ['Euro Da Clienti', v => v.TotClienti],
    ['Euro Da Anonimo', v => v.TotAnonimo],
    ['Totale Euro', v => v.Totale]
  ],

  venditeAmazon: [
    ['Giorno', v => parseDate(v.stime)],
    ['Vendite Amazon', v => v.VenditeAmazon],
    ['Vendite Delivery', v => v.VenditeDelivery],
    ['Vendite Shopify', v => v.VenditeShopify],
    ['TotPassaggi', v => v.TotalePassaggi],
    ['Euro Da Amazon', v => v.TotAmazon],
    ['Euro Da Delivery', v => v.TotDelivery],
    ['Euro Da Shopify', v => v.TotShopify],
    ['Totale Euro', v => v.Totale]
  ],

  clientiStorico: [
    ['Giorno', v => parseDate(v.Giorno)],
    ['Totale Clienti', v => v.TotClienti],
    ['Senza Acquisti', v => v.TotMaiAcquisti],
    ['Registrazioni', v => v.Registrati],
    ['Media SpesaMediaMensile', v => getDisplayPrice(v.AvgSMM).slice(0, -2)],
    ['Media Anno', v => getDisplayPrice(v.AvgTotAnno).slice(0, -2)]
  ],

  clientiDettaglio: [
    ['Nome', v => v.Nome],
    ['Cognome', v => v.Cognome],
    ['Registrato', v => parseDate(v.DataReg)],
    ['Ultimo Acquisto', v => parseDate(v.LastSale)],
    ['Totale Speso', v => getDisplayPrice(v.TotGenerale).slice(0, -2)],
    ['Totale Anno', v => getDisplayPrice(v.TotAnno).slice(0, -2)],
    ['Spesa Media Mensile', v => getDisplayPrice(v.SpesaMediaMensile).slice(0, -2)],
    ['Sconto', v => v.cSconto]
  ],

  ordine: [
    ['Categoria', v => v.prod.Categoria],
    ['Fornitore', v => v.prod.Fornitore],
    ['Descrizione', v => v.prod.Descrizione],
    ['Info', v => v.prod.Info],
    ['Prezzo Fornitore', v => getDisplayPrice(v.prod.PrezzoFornitore).slice(0, -2)],
    ['Quantità', v => v.qt],
    ['Totale Singolo', v => getDisplayPrice(v.prod.PrezzoFornitore * v.qt).slice(0, -2)]
  ]
}

export const exportTable = (table, data) => {
  const structure = structures[table]
  if (!structure || !data || !data.length) return null
  const header = structure.map(s => s[0]).join('\t')
  const parseLine = line => structure.map(e => e[1](line)).join('\t')

  copyToClipboard(header + '\n' + data.map(parseLine).join('\n'))
}
