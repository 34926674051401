import React, { useState } from 'react'
import { useCapsule, cap, navigateTo } from '../../State'
import { useMount } from 'react-use'
import { Dialog } from 'primereact/dialog'
import { Card, Icon, Input, Text, Flex, Spinner, Button } from '../../Components'
import { FC, getPaymentOptions, getDisplayPrice, getFullNomeCli, parsePrice, getDisplayWeight, useCQuery } from '../../Services'
import { setCurrentSale } from '../Vendita/ProcedureVendita'
import { printReceipt } from './Stampante'

const mockPrinterResponse = FC.isDev

export default function Cassa () {
  const [{ sale, cassa, carrello }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)
  const [cash, setCash] = useState('')
  const [lottery, setLottery] = useState('')
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showPrint, setShowPrint] = useState(false)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [showPrintError, setShowPrintError] = useState(false)

  const { isSuccess, data: magazzino } = useCQuery('magazzino')

  const configOptions = {
    create: {
      icon: 'check',
      message: 'Vendita Registrata',
      IdVendita: cassa.IdSale
    },
    update: {
      icon: 'pencil-alt',
      message: 'Vendita Modificata',
      skipHome: !!sale.Processato,
      IdVendita: sale.Id
    },
    remove: {
      icon: 'trash',
      message: 'Vendita Eliminata',
      skipHome: true
    }
  }
  const config = configOptions[cassa.action] || configOptions.remove

  const clearSaleGoHome = () => {
    cap.vendita.reset()
    cap.cliente.reset()
    navigateTo('Home')
  }

  useMount(() => {
    setShowPrint(!config.skipHome && (window.ipcBridge || mockPrinterResponse))
    config.skipHome && setTimeout(clearSaleGoHome, 2000)
    config.IdVendita && setCurrentSale(config.IdVendita, true).then(() => setLoading(false))
  })

  const resto = !cash ? '' : parsePrice(cash) - sale.Totale < 0 ? '##' : getDisplayPrice(parsePrice(cash) - sale.Totale)

  const getProduct = (se) => {
    if (!magazzino) return null
    if (se.IdProdotto === -1) return '❤️ ' + (se.Note || 'Sconto')
    if (se.IdProdotto === 0) return se.Note || 'Servizio'
    const prod = magazzino.find(p => p.Id === se.IdProdotto)
    if (se.PesoSfuso > 0) return '🌽 ' + getDisplayWeight(se.PesoSfuso) + ' ' + (se.Note || prod.Descrizione.substr(0, 30))
    return `${se.Qt} X ${se.Note || prod.Descrizione.substr(0, 30)}`
  }

  const handlePrint = async () => {
    if (!magazzino) return false
    setProcessing(true)
    const result = await printReceipt(magazzino, sale, carrello, mockPrinterResponse, sale.Pagamento === 'contanti' ? '' : lottery)
    setShowPrintError(!result)
    setShowPrint(!result)
    setProcessing(false)
  }

  const handleManualPrint = async () => {
    if (!magazzino) return false
    setProcessing(true)
    await FC.service('sales').patch(sale.Id, { Processato: 1 })
    cap.vendita.patchState({ sale: { ...cap.vendita.state.sale, Processato: 1 } })
    FC.updateCache(['todaySales', 'daRitirare', 'daEvadere'])
    setShowPrintError(false)
    setShowPrint(false)
    setProcessing(false)
    setDialogVisible(false)
  }

  const setPagamento = (Pagamento) => {
    ;(!sale || !sale.Id) && FC.log({ EventType: 'Cassa / SetPagamento NoSales', Data: sale }, true)
    FC.service('sales').patch(sale.Id, { Pagamento }).then(() => FC.updateCache('todaySales'))
    cap.vendita.patchState({ sale: { ...cap.vendita.state.sale, Pagamento } })
  }

  return (
    <Flex fw fh>
      <Flex row as>
        <Card fadeIn style={{ width: 460 }}>

          <Flex fw row style={{ marginBottom: config.skipHome ? 20 : 40 }}>
            <Icon name={config.icon} size={40} style={{ marginRight: 20 }} />
            <Text bold value={config.message} size={32} />
          </Flex>
          {config.skipHome
            ? <Flex fw row><Spinner /></Flex>
            : (
              <>
                {isSuccess && carrello.map((p, i) => (
                  <Flex row fw jb key={'pr' + i}>
                    <Text size={20} value={getProduct(p)} />
                    <Text bold size={20} value={getDisplayPrice(p.TotaleSingolo)} />
                  </Flex>
                ))}

                <Flex row fw jb style={{ marginTop: 20 }}>
                  <Text bold size={32} value='Totale:' />
                  <Text bold size={32} value={getDisplayPrice(sale.Totale)} />
                </Flex>

                {sale.BollaDelivery < 1
                  ? null
                  : (
                    <Flex fw row jb style={{ marginTop: 10 }}>
                      <Text bold size={20} value='Numero Bolla:' style={{ marginLeft: 5 }} />
                      <Text bold size={20} value={sale.BollaDelivery} style={{ }} />
                    </Flex>
                    )}

                {processing || loading
                  ? (
                    <Flex row fw jb style={{ marginTop: 20 }}>
                      <Spinner />
                    </Flex>
                    )
                  : (
                    <>
                      <Flex fw row jb style={{ marginTop: 20 }}>
                        <Text bold value='Tipo di pagamento:' style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }} />
                        {cliente.Piva
                          ? <Input placeholder='Pagamento...' useState={[sale.Pagamento, setPagamento]} maxLength={30} style={{ width: 240 }} />
                          : <Input dropdown useState={[sale.Pagamento, setPagamento]} options={getPaymentOptions()} style={{ width: 240 }} />}

                      </Flex>
                      <Flex row fw jb style={{ marginTop: 20 }}>
                        <Button icon='shopping-basket' label='Torna alla Vendita' onClick={() => navigateTo('Vendita')} />
                        <Button icon='home' label='Chiudi' width={200} onClick={clearSaleGoHome} />
                      </Flex>
                    </>
                    )}
              </>
              )}
        </Card>
        <Flex>
          {!config.skipHome && showPrint && (
            <Card fadeIn style={{ width: 380, marginLeft: 20 }} title={`CLIENTE: ${getFullNomeCli(cliente)}`}>
              {processing || loading
                ? (
                  <Flex fw>
                    {!loading && <Text bold value='Stampa in Corso' size={24} style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }} />}
                    <Spinner />
                  </Flex>
                  )
                : (
                  <>

                    {sale.Pagamento === 'contanti'
                      ? (
                        <Flex row fw jb style={{ marginTop: 10 }}>
                          <Input label='Contanti:' placeholder='Calcola Resto' useState={[cash, setCash]} style={{ width: 190 }} />
                          <Input label='Resto: ' readOnly value={resto} style={{ width: 130 }} />

                        </Flex>
                        )
                      : (
                        <Flex row fw jb style={{ marginTop: 10 }}>
                          <Input label='Lotteria:' placeholder='Codice Lotteria...' maxLength={16} useState={[lottery, setLottery]} />
                        </Flex>
                        )}

                    {!isSuccess || !sale || !sale.Id
                      ? <Flex fw row><Spinner /></Flex>
                      : <Button width='100%' style={{ marginTop: 20 }} icon='print' label='Scontrino stampato a mano' onClick={() => setDialogVisible(true)} />}
                    {!isSuccess || !sale || !sale.Id
                      ? <Flex fw row><Spinner /></Flex>
                      : <Button width='100%' style={{ marginTop: 20 }} icon='print' label='Stampa Scontrino' onClick={handlePrint} />}
                  </>
                  )}
            </Card>
          )}
          {showPrintError && (
            <Card fadeIn style={{ width: 380, marginLeft: 20, marginTop: 20 }}>
              <Text bold color='red' value='Erroredi stampa !!!' size={24} style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }} />
              <Text bold color='red' value='Scontrino non stampato o alcuni prodotti non sono stati conteggiati' size={20} style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }} />
              <Text bold color='red' value='Scontrinare a mano prodotti mancanti!' size={20} style={{ marginLeft: 5, marginTop: 10, marginBottom: 10 }} />
            </Card>
          )}
        </Flex>
      </Flex>

      <Dialog visible={dialogVisible} modal onHide={() => setDialogVisible(false)} footer={null} header='Hai stampato a mano lo scontrino?'>
        <Flex fw row jb>

          <Button width='45%' icon='check' label='SI' onClick={handleManualPrint} />
          <Button width='45%' style={{ marginLeft: 10 }} icon='ban' label='NO' onClick={() => setDialogVisible(false)} />
        </Flex>

      </Dialog>
    </Flex>
  )
}
