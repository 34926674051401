import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Card, Spinner, SearchBox, Icon, Button, Text, Flex } from '../../Components'
import { FC, getDisplayPrice } from '../../Services'
import { useCapsule, cap } from '../../State'
import { QtEditor, handleAddToCaricoScarico } from './ProcedureMagazzino'
import { Dialog } from 'primereact/dialog'
import { RettificaFile } from './RettificaFile'

export function CaricoScarico () {
  const [{ caricoProdotti }] = useCapsule(cap.magazzino)
  const [processing, setProcessing] = useState(false)
  const [isLoadingAction, setLoadingAction] = useState(undefined)
  const [rettificaVisible, setRettificaVisible] = useState(false)

  const setProdotti = (cp) => cap.magazzino.patchState({ caricoProdotti: cp })
  const updateQuantity = (rowIndex, value, Qt) => setProdotti(value.map((v, i) => i === rowIndex ? { ...v, Qt } : v))
  const deleteProduct = (rowIndex) => setProdotti(caricoProdotti.filter((p, ind) => ind !== rowIndex))

  const handleLoadProdotti = async () => {
    setProcessing(true)
    if (isLoadingAction === undefined) {
      setProcessing(false)
      return false
    }

    const result = await Promise.all(caricoProdotti.map(({ Id, Qt }) => {
      (Qt === null || isNaN(Qt) || !Qt) && FC.log({ EventType: 'ErrWithHandleLoadProdotti - Qt', Data: { Id, Qt } }, true)
      return FC.service('movimenti').create({ IdProdotto: Id, IdSale: 0, Qt: Qt * (isLoadingAction ? 1 : -1) })
    }))

    FC.updateCache(['movimenti', 'magazzinoQt'])
    setLoadingAction(undefined)
    setProcessing(false)
    setProdotti([])

    result.filter(r => !r || !r.Id).length
      ? window.growl.show({ severity: 'error', summary: 'Errore durante il salvataggio', details: 'Controllare ultimi movimenti' })
      : window.growl.show({ severity: 'success', summary: 'Caricamento Completato' })
  }

  const priceTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={getDisplayPrice(rowData[field])} />
  const removeTemplate = (rowData, { rowIndex }) => (
    <Flex fw fh style={{ cursor: 'pointer' }} onClick={() => deleteProduct(rowIndex)}>
      <Text bold color='red' value='X' size={18} />
    </Flex>
  )

  const footer = (
    <Flex row fw jb width={300}>
      <Button label='Annulla' icon='ban' onClick={() => setLoadingAction(undefined)} />
      <Button label={`${isLoadingAction ? 'Carica' : 'Scarica'} Prodotti`} icon='check' onClick={handleLoadProdotti} />
    </Flex>
  )

  return (
    <Card fadeIn title='Carico e Scarico Prodotti' collapsable collapsed={true || !caricoProdotti.length}>
      <Flex style={{ padding: 10 }}>

        <SearchBox onSelection={handleAddToCaricoScarico} returnMagazzino placeholder='Inserisci codice o nome prodotto' />

        <DataTable
          style={{ marginTop: 20, minHeight: 500 }} value={caricoProdotti}
          emptyMessage='Aggiungi Prodotti' editable scrollable scrollHeight='470px'
        >
          <Column field='Fornitore' header='Fornitore' style={{ width: 200 }} />
          <Column field='Categoria' header='Categoria' style={{ width: 200 }} />
          <Column field='Descrizione' header='Descrizione' />
          <Column field='Info' header='Info' style={{ width: 200 }} />
          <Column field='PrezzoListino' header='Prezzo' style={{ width: 200 }} body={priceTemplate} />
          <Column field='Qt' header='Quantità' style={{ width: 80, textAlign: 'right' }} editor={(info) => <QtEditor info={info} updateQuantity={updateQuantity} />} />
          <Column field='X' header='' style={{ width: 20, textAlign: 'right', padding: 0 }} body={removeTemplate} />
        </DataTable>
        {caricoProdotti.length > 15 && <Icon name='arrow-down' size={20} />}
        <Flex row width='100%' jb style={{ marginTop: 10 }}>
          <Flex width={143} />
          <Button style={{ width: 250 }} icon='arrow-up' label='Carica Prodotti' onClick={() => caricoProdotti.length && setLoadingAction(true)} />
          <Button style={{ width: 250 }} icon='arrow-down' label='Scarica Prodotti' onClick={() => caricoProdotti.length && setLoadingAction(false)} />
          <Button style={{ width: 143 }} label='Rettifica da File' onClick={() => setRettificaVisible(true)} />
        </Flex>

        <Dialog
          modal visible={isLoadingAction !== undefined} onHide={() => setLoadingAction(undefined)}
          header={`Conferma ${isLoadingAction ? 'Carico' : 'Scarico'} Prodotti`}
          footer={processing ? <Spinner /> : footer}
        >
          <Text value={`Sicuro di voler ${isLoadingAction === 1 ? 'Caricare' : 'Scaricare'} i prodotti nell'elenco?`} />
        </Dialog>

        <RettificaFile visibleState={[rettificaVisible, setRettificaVisible]} />
      </Flex>
    </Card>
  )
}
