import React from 'react'
import { Card, Spinner, Flex, Button, Text } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getDisplayPrice, exportTable, useCQuery } from '../../Services'
import moment from 'moment'
import { ClientiChart } from './ClientiChart'
import { getZampe, getSconto } from '../Vendita/ProcedureVendita'

export const Clienti = ({ query }) => {
  const { status: statusClienti, data: clienti } = useCQuery('statsClienti')
  const { status: statusStorico, data: storico } = useCQuery(['storicoClienti', query])

  const templateEur = (rowData, { field }) => getDisplayPrice(rowData[field])
  const templateDate = (rowData, { field }) => moment(rowData[field]).format('DD/MM/YYYY')
  const templateSconto = ({ stats, Affiliato, ScontoAttivo, Sconto, TipoSconto }) => (Affiliato === 0 || Affiliato === 3 || (Affiliato > 0 && !ScontoAttivo))
    ? `${getDisplayPrice(-getSconto(getZampe(stats)))} ogni 300`
    : [
        'A. Mai',
    `A. ${Sconto}% Totale`,
    `A. ${Sconto} € ogni 300`,
    'Sconto Normale (solo tracciamento)'
      ][TipoSconto]
  const processedClienti = statusClienti !== 'loading' && clienti.map((c) => ({ ...c, cSconto: templateSconto(c) }))

  return (
    <>
      <ClientiChart query={query} />

      {statusStorico === 'loading'
        ? <Spinner />
        : (
          <Card style={{ marginTop: 20, width: '100%' }}>
            <DataTable
              value={storico} paginator rows={20} emptyMessage='Nessuno Storico Trovato'
              header={(
                <Flex row jb fw>
                  <Text bold value='Statistiche Storiche Clienti' />
                  <Button icon='file-excel' label='Esporta Tabella' width={200} onClick={() => exportTable('clientiStorico', storico)} />
                </Flex>
            )}
            >
              <Column field='Giorno' header='Giorno' sortable style={{ width: 160 }} body={templateDate} />
              <Column field='TotClienti' header='Totale Clienti' sortable style={{ width: 160 }} />
              <Column field='TotMaiAcquisti' header='Senza Acquisti' sortable style={{ width: 160 }} />
              <Column field='Registrati' header='Registrazioni' sortable style={{ width: 160 }} />
              <Column field='AvgSMM' header='Media SMM' sortable style={{ width: 160 }} body={templateEur} />
              <Column field='AvgTotAnno' header='Media Anno' sortable style={{ width: 160 }} body={templateEur} />
            </DataTable>
          </Card>
          )}
      {statusClienti === 'loading'
        ? <Spinner />
        : (
          <Card style={{ marginTop: 20, width: '100%' }}>
            <DataTable
              value={processedClienti} paginator rows={20} emptyMessage='Nessun Cliente Trovato'
              header={(
                <Flex row jb fw>
                  <Text bold value='Dettaglio Clienti' />
                  <Button icon='file-excel' label='Esporta Tabella' width={200} onClick={() => exportTable('clientiDettaglio', processedClienti)} />
                </Flex>
            )}
            >
              <Column field='Nome' header='Nome' sortable style={{ width: 160 }} />
              <Column field='Cognome' header='Cognome' sortable style={{ width: 160 }} />
              <Column field='DataReg' header='Registrato' sortable style={{ width: 160 }} body={templateDate} />
              <Column field='LastSale' header='Ultimo Acquisto' sortable style={{ width: 160 }} body={templateDate} />
              <Column field='TotGenerale' header='Totale Speso' sortable style={{ width: 160 }} body={templateEur} />
              <Column field='TotAnno' header='Totale Anno' sortable style={{ width: 160 }} body={templateEur} />
              <Column field='SpesaMediaMensile' header='SMM' sortable style={{ width: 160 }} body={templateEur} />
              <Column field='cSconto' header='Sconto' style={{ width: 160 }} sortable />
            </DataTable>
          </Card>
          )}
    </>
  )
}
