import React from 'react'
import { Button } from '../../Components'
import { cap } from '../../State'

export const DashButton = ({ field, cliente, magazzino, addProdotto }) => {
  const prodotto = cliente[field] > 0 && magazzino ? magazzino.find(({ Id }) => Id === cliente[field]) : null
  const onAddProdotto = () => addProdotto({
    IdProdotto: prodotto.Id,
    Qt: 1,
    TotaleSingolo: prodotto.PrezzoListino,
    Reparto: prodotto.Reparto,
    ValePunti: 1,
    PesoSfuso: 0
  })
  return (
    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
      <Button round iconStyle={{ marginLeft: -2 }} icon='pencil-alt' onClick={() => cap.vendita.patchState({ findProdotto: field })} />
      <Button
        icon='shopping-basket'
        label={prodotto ? `${prodotto.Fornitore} ${prodotto.Descrizione} (${prodotto.Info})` : 'Selezionare Prodotto'}
        style={{ marginLeft: 10, width: '100%', height: 50 }}
        onClick={() => prodotto ? onAddProdotto() : cap.vendita.patchState({ findProdotto: field })}
      />
    </div>
  )
}
