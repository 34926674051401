import React from 'react'
import { useCapsule, cap } from '../../State'

import { Flex, Card, Text, Spinner } from '../../Components'
import { ScrollPanel } from 'primereact/scrollpanel'
import { getDisplayPrice, isLargeScreen, getPaymentIcon, getScreenHeight, useCQuery } from '../../Services'
import { getSalesFromStorico, getDisplayProduct } from './ProcedureVendita'

export function Storico ({ fullHeight }) {
  const [cliente] = useCapsule(cap.cliente)
  const IdCliente = cliente ? cliente.Id : 0

  const { isSuccess, data: storico } = useCQuery(['storicoCliente', IdCliente])
  const sales = getSalesFromStorico(storico)
  const hidePanel = (isLargeScreen() !== !!fullHeight) || !cliente || cliente.Id < 1

  const renderElement = (s, ind) => (
    <div key={ind}>
      <Text bold value={`${s.SaleTime} - ${getDisplayPrice(s.Totale)} ${getPaymentIcon(s.Pagamento)}`} style={{ marginTop: ind ? 10 : 0 }} />
      {s.prodotti.map((p, i) => <Text key={'pr' + i} value={getDisplayProduct(p)} />)}
    </div>
  )

  const renderContent = sales.length ? sales.map(renderElement) : <Text value='Storico non presente.' />

  if (hidePanel) return null
  return (
    <Flex js as fh style={{ marginLeft: fullHeight ? 20 : 0 }}>
      <Card title='STORICO' {...getCardProps(fullHeight)}>
        <ScrollPanel style={{ height: fullHeight ? getScreenHeight() - 250 : 217 }}>
          {!isSuccess ? <Spinner /> : renderContent}
        </ScrollPanel>
      </Card>
    </Flex>
  )
}

const getCardProps = (fullHeight) => {
  const styleFull = { height: '100%', width: 330, marginTop: 0 }
  const styleShort = { width: 380, marginTop: 20 }
  return {
    style: fullHeight ? styleFull : styleShort,
    collapsable: !fullHeight,
    collapsed: !fullHeight
  }
}
