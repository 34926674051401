import React, { useEffect, useState } from 'react'
import { FC, getUrlParams, getDisplayPrice, scorporoIva } from '../../Services'
import { Text } from '../../Components'
import { logo } from '../../Resources'
import { Prodotti } from './Prodotti'
import moment from 'moment'
import './Bolla.css'

const Field = ({ label, value, inverted, style, size }) => (
  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 3, ...style }}>
    <Text bold={!inverted} value={label} size={size} />
    <Text bold={inverted} style={{ marginLeft: 7 }} value={value} size={size} />
  </div>
)

const Panel = ({ title, children }) => (
  <div style={{ display: 'flex', marginTop: 3, flexDirection: 'column', width: '48%', height: 100 }}>
    <Text boldsize={12} value={title} />
    <div style={styles.addressPanel}>
      {children}
    </div>
  </div>
)

const Cell = ({ title, children, size, height = 40 }) => (
  <div style={{ ...styles.box, width: `${size}%`, height, padding: 3 }}>
    <Text size={9} value={title} />
    {children}
  </div>
)

export default function Bolla () {
  const [sale, setSale] = useState(null)
  const { Cliente = {} } = sale || {}
  const idSale = parseInt(getUrlParams('idSale') || 0)
  const combine = (getUrlParams('combine') || '').split('-').filter(a => a).map(a => parseInt(a))

  const { box, row, col } = styles
  useEffect(() => {
    FC.service('sales').get(idSale, { query: { joinReferences: true } }).then(({ Prodotti, ...sale }) => {
      const mySale = { ...sale, Prodotti: Prodotti.sort((a, b) => b.IdProdotto - a.IdProdotto) }
      if (!combine.length) {
        setSale(mySale)
      } else {
        FC.service('sales').find({ query: { joinReferences: true, Id: { $in: combine } } }).then(mySales => {
          setSale({
            ...sale,
            Prodotti: mySales.map(({ Prodotti }) => Prodotti).reduce((a, b) => a.concat(b), []).sort((a, b) => b.IdProdotto - a.IdProdotto),
            Totale: mySales.reduce((a, b) => a + b.Totale, 0)
          })
        })
      }
    })
  }, [idSale, combine])
  if (!sale) return null

  const totaleDoc = Cliente.Piva
    ? sale.Prodotti.map(prod => scorporoIva(prod)).reduce((tot, cur) => tot + cur, 0)
    : sale.Totale

  return (
    <div className='page'>
      <div className='subpage' style={{ ...col, justifyContent: 'space-between' }}>
        <div style={{ ...row, alignItems: 'center' }}>
          <img alt='logo' src={logo} width='100px' height='100px' />
          <div style={{ ...col, marginLeft: 100 }}>
            <Text size={24} bold value='Doggypop' />
            <div style={{ backgroundColor: 'black', height: 2, width: '100%', marginBottom: 10, marginTop: 5 }} />
            <Text size={12} value='Via Cascina Colombaro 46/A - 12100 Cuneo (CN) - Italia' />
            <Text size={12} value='P.iva 02989150046 Reg. imprese CN 2353206' />
          </div>
        </div>
        <div style={{ ...row, alignItems: 'center', height: Cliente.Piva ? 10 : 22 }}>
          <div style={{ backgroundColor: 'black', height: 2, width: '48%', marginRight: 30 }} />
          <Field inverted label='Doc. di trasporto nr.' value={sale.BollaDelivery} style={{ marginRight: 20, marginLeft: -3 }} />
          <Field inverted label='del' value={`${moment(sale.SaleTime).format('DD/MM/YYYY')}`} />
        </div>

        <div style={{ ...row, alignItems: 'center', justifyContent: 'space-between' }}>
          <Panel title='Destinatario'>
            <Text size={14} value={`${Cliente.Nome.toUpperCase()} ${Cliente.Cognome.toUpperCase()}`} />
            <Text size={14} value={Cliente.IndVia} />
            <Text size={14} value={`${Cliente.IndCap} ${Cliente.Citta} (${Cliente.IndProv})`} />
            {Cliente.Piva ? <Text size={14} value={`Partita IVA: ${Cliente.Piva}`} /> : null}
          </Panel>
          <Panel title='Destinazione'>
            <Text size={14} value={Cliente.NomeConsegna.toUpperCase()} />
            <Text size={14} value={Cliente.IndVia} />
            <Text size={14} value={`${Cliente.IndCap} ${Cliente.Citta} (${Cliente.IndProv})`} />
            {Cliente.Piva ? <Text size={14} value='.' color='white' /> : null}
          </Panel>
        </div>

        <Prodotti prodotti={sale.Prodotti} b2b={Cliente.Piva} />

        <div style={{ ...col, ...box, height: 200 }}>
          <div style={{ ...row, height: 120 }}>
            <Cell size='60' height={120} title='Note di consegna'>
              {Cliente.Telefono && <Field inverted label='Telefono Cliente' value={Cliente.Telefono} />}
              {sale.Nota && sale.Nota.split('\n').map((line, ind) => <Text key={ind} value={line} />)}
            </Cell>
            <Cell size='40' height={120} title=''>
              <div style={{ ...col, height: '100%', justifyContent: 'flex-end', padding: 10 }}>
                <div style={{ backgroundColor: 'black', height: 2, width: '100%', marginBottom: 10 }} />
                {
                  Cliente.Piva
                    ? (
                      <>
                        <Field inverted label='Pagamento:' size={16} value={sale.Pagamento} />
                        <Field inverted label='Totale IVA Inclusa' size={16} value={getDisplayPrice(sale.Totale)} />
                        <Field inverted label='di cui IVA' size={16} value={getDisplayPrice(sale.Totale - totaleDoc)} />

                        <Field inverted label='Totale IVA Esclusa' size={16} value={getDisplayPrice(totaleDoc)} />

                      </>
                      )
                    : <Field inverted label='Tot. documento' size={20} value={getDisplayPrice(totaleDoc)} />
                }

              </div>

            </Cell>
          </div>
          <div style={{ ...row, height: 40 }}>
            <Cell size='50' title='Incaricato del trasporto' />
            <Cell size='30' title='Causale del trasporto' />
            <Cell size='20' title='Firma incaricato del trasporto' />
          </div>
          <div style={{ ...row, height: 40 }}>
            <Cell size='10' title='Nr. colli'>
              <Text value={sale.Colli} />
            </Cell>
            <Cell size='10' title='Peso'>
              <Text value={`${sale.PesoKg} Kg`} />
            </Cell>
            <Cell size='20' title='Aspetto esteriore beni'>
              <Text value='a vista' />
            </Cell>
            <Cell size='20' title='Porto' />
            <Cell size='20' title='Data e ora inizio trasporto' />
            <Cell size='20' title='Firma destinatario' />
          </div>
        </div>
      </div>
    </div>

  )
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowCell: {
    padding: 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  },
  box: {
    display: 'flex',
    border: 'solid',
    borderWidth: 1,
    flexDirection: 'column'
  },
  pageContainer: {
    background: 'white',
    display: 'block',
    margin: '0 auto',
    marginBottom: '0.5cm',
    boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
    width: '21cm',
    height: '29.7cm'
  },
  saleContainer: {
    backgroundColor: 'rgba(200 , 200, 200, 0.5)',
    borderRadius: 30,
    padding: 20
  },
  addressPanel: {
    display: 'flex',
    marginTop: 3,
    flexDirection: 'column',
    border: 'solid',
    borderWidth: 2,
    width: '100%',
    height: '100%',
    padding: 10,
    justifyContent: 'space-evenly'
  }
}
