import React, { useEffect, useState } from 'react'
import { Card, Input, Button, ClienteEditor, Field, FlagField, Flex } from '../../Components'
import { getDisplayPrice, useCQuery } from '../../Services'
import { useCapsule, cap } from '../../State'
import { DashButton } from './DashButton'
import { handleAddProdotto, updateCliente, getSconto, getZampe } from './ProcedureVendita'

export const ClientData = () => {
  const [cliente] = useCapsule(cap.cliente)
  const [{ affiliazione }] = useCapsule(cap.vendita)

  const [nota, setNota] = useState('')
  const [dialogVisible, setDialogVisible] = useState(false)

  const notaCliente = cliente ? cliente.Nota : ''
  useEffect(() => { setNota(notaCliente) }, [notaCliente])

  const IdCliente = cliente ? cliente.Id : 0
  const { isSuccess: statSuccess, data: statsCliente } = useCQuery(['statsCliente', IdCliente])
  const { isSuccess: magSuccess, data: magazzino } = useCQuery('magazzino')

  if (!cliente || cliente.Id < 1 || !statSuccess || !magSuccess) return null
  return (
    <Card style={{ marginTop: 20, height: 657, width: 380 }}>
      <Button icon='pencil-alt' label='Modifica Cliente' width='100%' style={{ marginBottom: 10 }} onClick={() => setDialogVisible(true)} />
      <Field label='Cliente Da: ' value={`${statsCliente?.Anni} Anni ${statsCliente?.Mesi} Mesi`} />
      <Field label='Viene Ogni: ' value={`${statsCliente.Freq} giorni`} />
      <Field label='È passato (ultimo anno): ' value={`${statsCliente.Passaggi} volte`} />
      <Field label='Spesa Media giornaliera: ' value={`${getDisplayPrice(statsCliente.SMG)}`} />
      {!!affiliazione && <Field label='Affiliazione: ' value={affiliazione.Nome} />}
      {(!affiliazione || (affiliazione && affiliazione.TipoSconto === 3)) && (
        <Field label='Zampe / Sconto: ' value={`${getZampe(statsCliente)} / ${getSconto(getZampe(statsCliente)) / (-100)}€`} />
      )}
      <Flex row fw>
        <Field label='Cani Grandi: ' value={cliente.CaniTG} />
        <Field label='Medi: ' value={cliente.CaniTM} style={{ marginLeft: 10 }} />
        <Field label='Piccoli: ' value={cliente.CaniTP} style={{ marginLeft: 10 }} />
        <Field label='Gatti: ' value={cliente.Gatti} style={{ marginLeft: 10 }} />
      </Flex>
      <FlagField label='Antiparassitario' checked={!!cliente.Flag} onChange={(checked) => updateCliente(cliente, { Flag: checked ? 1 : 0 })} />
      <Input
        multiline maxLength={250}
        useState={[nota, setNota]}
        onBlur={() => updateCliente(cliente, { Nota: nota })}
        style={{ height: 190, marginTop: 10, maxHeight: 190 }}
      />
      {[...Array(3)].map((e, id) => <DashButton key={id} field={`IdPro${id + 1}`} cliente={cliente} magazzino={magazzino} addProdotto={(prod) => handleAddProdotto(prod, magazzino)} />)}
      <ClienteEditor visibleState={[dialogVisible, setDialogVisible]} inputCliente={cliente} isVendita isQuickEditor />

    </Card>
  )
}
