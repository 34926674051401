import React from 'react'
import { Card, Background, Button, Icon, Text, Flex } from '../Components'
// import { FC } from '../Services'
import { cap, navigateTo } from '../State'
// import { serializeError } from 'serialize-error'
// FC.log({ EventType: 'Render Error', Data: { state: cap.dumpStates(), errorInfo }, Error: serializeError(error) }, true)

const ErrorHandler = ({ resetError }) => {
  const handleReset = () => {
    const user = { ...cap.user.state }
    cap.resetCapsules()
    cap.user.setState(user)
    navigateTo('Home')
    resetError()
  }

  return (
    <Background>
      <Flex fw fh>
        <Card style={{ width: 600, height: 400 }}>
          <Flex fw fh jse>
            <Icon name='exclamation-triangle' size={50} style={{ alignSelf: 'center' }} />
            <Text bold value='Qualcosa è andato storto' size={18} />
            <Text bold value='Umberto è già stato avvisato, non serve chiamarlo' size={18} />
            <Button icon='sync' label='Prova a Ricaricare' onClick={() => resetError()} />
            <Text bold value='Se ricaricare non funziona' size={18} />
            <Button icon='exclamation-triangle' label='Pova a Resettare' onClick={handleReset} />
          </Flex>
        </Card>
      </Flex>
    </Background>
  )
}

export default ErrorHandler
