import React from 'react'
import { Card, Button, Field, Spinner, Flex } from '../../Components'
import { getTotaleCarrello, getDisplayPrice, getFullNomeCli, useCQuery } from '../../Services'
import { cap, useCapsule, navigateTo } from '../../State'

export function NewSale () {
  const [cliente] = useCapsule(cap.cliente)
  const [{ sale, carrello }] = useCapsule(cap.vendita)
  const { data: operatori } = useCQuery('operatori')

  const newSale = (Operatore) => {
    cap.cliente.reset()
    cap.vendita.reset()
    cap.vendita.patchState({ sale: { ...cap.vendita.state.sale, Operatore } })
    navigateTo('Vendita')
  }

  return (
    <Flex fw>
      {sale && sale.Operatore && (
        <Card fadeIn title='❗️❗️ VENDITA IN CORSO NON COMPLETATA ❗️❗️' style={{ marginBottom: 20 }}>
          <Flex as style={{ margin: 20 }}>
            <Field size={20} label='Operatore:' value={sale.Operatore} />
            <Field size={20} label='Cliente:' value={getFullNomeCli(cliente)} />
            <Field size={20} label='Totale:' value={getDisplayPrice(getTotaleCarrello(carrello))} />
          </Flex>
          <Flex fw>
            <Button icon='shopping-basket' label='Torna a Vendita' width='50%' onClick={() => navigateTo('Vendita')} />
          </Flex>
        </Card>
      )}
      <Card fadeIn title='NUOVA VENDITA' style={{ width: '100%' }}>
        <Flex flexrow row jse style={{ padding: 20 }}>
          {!operatori || !operatori.length
            ? <Spinner />
            : operatori.map((operatore, index) =>
              <Button icon='user' key={index} label={operatore} width={150} style={{ margin: 10 }} onClick={() => newSale(operatore)} />
            )}
        </Flex>
      </Card>
    </Flex>
  )
}
