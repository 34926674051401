export const getReparto = rep => {
  const reparti = ['B2B 4%', 'Mangimi', 'Accessori', 'Servizi', 'Non Usare', 'Antipar.', '4% IVA']
  return reparti[rep] || 'Errore'
}
export const getRepartoOptions = () => [
  { label: '4% IVA per B2B', value: 0 },
  { label: 'Mangimi', value: 1 },
  { label: 'Accessori', value: 2 },
  { label: 'Servizi', value: 3 },
  { label: 'Non Usare', value: 4 },
  { label: 'Antiparassitari', value: 5 }
]

export const getPaymentOptions = () => [
  { label: 'Contanti', value: 'contanti' },
  { label: 'Carta', value: 'carta' },
  { label: 'Satispay', value: 'satispay' },
  { label: 'SatispayExpress', value: 'satispayexpress' },
  { label: 'Bonifico', value: 'bonifico' },
  { label: 'Contrassegno', value: 'contrassegno' },
  { label: 'Paypal', value: 'paypal' },
  { label: 'Ritiro a Mano', value: 'allaconsegnainnegozio' }
]

export const getPaymentCode = (payValue) => {
  if (payValue === 'contanti') return '010008CONTANTI'
  return '030021PAGAMENTO ELETTRONICO'
}

/*
1 Contanti x
2 Assegni
3 Carta Elettronica x
4 Pagamento Generico
5 Pagamento buono Pasti
6 sospeso
7 Credito x
Password 00000
*/

export const getPaymetLabel = (payV) => (getPaymentOptions().find(({ value }) => value === payV) || { label: 'Non Trovato' }).label

export const getPaymentIcon = (p) => {
  const icons = {
    contanti: '💰',
    carta: '💳',
    satispay: '💢',
    satispayexpress: '💢',
    bonifico: '🏦',
    contrassegno: '💌',
    paypal: 'P',
    allaconsegnainnegozio: '?'
  }
  return icons[p] || ''
}

export const getNuovoProdotto = () => ({
  Id: false,
  Descrizione: '',
  Categoria: '',
  Info: '',
  Fornitore: '',
  PrezzoListino: 0,
  PrezzoFornitore: 0,
  QtMinima: 0,
  CodiceFornitore: 0,
  CodiceSfuso: '',
  PrezzoSfuso: 0,
  Tolleranza: 50,
  Off1Peso: 0,
  Off1Prezzo: 0,
  Off2Peso: 0,
  Off2Prezzo: 0,
  Off3Peso: 0,
  Off3Prezzo: 0
})

export const getEmptyCliente = () => ({
  Nome: '',
  Cognome: '',
  Email: '',
  LastSale: null,
  Nota: '',
  Punti: 0,
  CaniTG: 0,
  CaniTM: 0,
  CaniTP: 0,
  Gatti: 0,
  IdPro1: 0,
  IdPro2: 0,
  IdPro3: 0,
  MaiSconto: 0,
  Flag: 0,
  IndVia: '',
  IndCap: '',
  IndProv: '',
  Telefono: '',
  Citta: '',
  NomeConsegna: ''
})

export const getAnonimo = () => ({
  Id: 0,
  Nome: 'Cliente',
  Cognome: 'Anonimo'
})

export const getEmptyStats = () => ({
  Totale: 0,
  Passaggi: 0,
  Giorni: 0,
  Anni: 0,
  Mesi: 0,
  SMG: 0,
  Freq: 0
})

export const getEmptyMove = () => ({
  Qt: 0,
  TotCarichi: 0,
  TotScarichi: 0
})

export const getScontoCarrello = (TotaleSingolo) => ({
  IdProdotto: -1,
  Qt: 1,
  TotaleSingolo,
  ValePunti: 0,
  PesoSfuso: 0,
  Reparto: 1,
  isSconto: true
})

export const getShipment = () => ({
  IdProdotto: -2,
  Qt: 1,
  TotaleSingolo: 500,
  ValePunti: 0,
  Reparto: 3,
  PesoSfuso: 0
})

export const tipoAffiliazione = [
  { label: 'Mai Sconto', value: 0 },
  { label: 'Percentuale Fissa', value: 1 },
  { label: 'Euro ogni 300', value: 2 },
  { label: 'Sconto Normale', value: 3 }
]

export const emptyShopifyItem = {
  title: 'title',
  variantId: 0,
  inventoryId: 0,
  sku: '',
  quantity: 0,
  price: '',
  priceCompare: ''
}
