import React, { useState } from 'react'
import { cap, useCapsule } from '../../State'
import { Button, Text, Flex } from '../../Components'
import { SfoField, getShopify } from './ProcedureMagazzino'
import { FC, getEmptyMove, parseInteger, useCQuery } from '../../Services'

export function ProdottoQuantityEdit () {
  const [actionQt, setActionQt] = useState('')
  const [processing, setProcessing] = useState(false)
  const [{ action }] = useCapsule(cap.magazzino)
  const { isSuccess, data: magazzinoQt } = useCQuery('magazzinoQt')
  const { isSuccess: sySuccess, data: shopify } = useCQuery('shopify')

  const actions = {
    carico: {
      label: 'Inserisci Quantità da Caricare:',
      button: 'Carica',
      okMessage: 'Prodotti Caricati',
      action: () => handleLoad(parseInt(actionQt), 'carico')
    },
    scarico: {
      label: 'Inserisci Quantità da Scaricare:',
      button: 'Scarica',
      okMessage: 'Prodotti Scaricati',
      action: () => handleLoad(-parseInt(actionQt), 'scarico')
    },
    rettifica: {
      label: 'Inserisci Quantità da far risultare come Totale:',
      button: 'Rettifica',
      okMessage: 'Rettifica Eseguita correttamente',
      action: () => handleLoad(parseInt(actionQt), 'rettifica')
    }
  }

  const handleClear = () => {
    setProcessing(false)
    setActionQt('')
    cap.magazzino.patchState({ action: '' })
  }

  const handleLoad = async (change) => {
    if (change === null || isNaN(parseInt(change))) {
      window.growl.show({ severity: 'error', summary: 'Controllare Quantità' })
      return false
    }

    if (action !== 'rettifica' && change === 0) return false

    setProcessing(true)
    const { prodotto } = cap.magazzino.state
    const prodQt = magazzinoQt ? magazzinoQt.find(({ IdProdotto }) => IdProdotto === prodotto.Id) || getEmptyMove() : getEmptyMove()

    if (!prodQt) {
      FC.log({ EventType: 'ErrHandleLoad - FindQt', Data: { prodQt, change, prodotto, actionQt } }, true)
      window.growl.show({ severity: 'error', summary: 'Errore reperimento prodotto' })
      setProcessing(false)
      return false
    }

    const Qt = parseInt(change) - (action !== 'rettifica' ? 0 : prodQt.Qt)
    try {
      if (Qt === null || isNaN(Qt)) {
        FC.log({ EventType: 'ErrWithHandleLoad - Qt', Data: { Qt, change, prodotto, actionQt } }, true)
        window.growl.show({ severity: 'error', summary: 'Controllare Quantità' })
        return false
      }
      const result = await FC.service('movimenti').create({ IdProdotto: prodotto.Id, IdSale: 0, Qt })

      result
        ? window.growl.show({ severity: 'success', summary: actions[action].okMessage })
        : window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio' })

      FC.updateCache(['movimenti', 'magazzinoQt'])
      if (sySuccess && !FC.isDev) {
        const { inventoryId } = getShopify(prodotto, shopify)
        if (inventoryId) {
          const { success } = await FC.service('tools').patch('updateShopifyQuantity', { IdProdotto: prodotto.Id, inventoryId })
          success
            ? window.growl.show({ severity: 'success', summary: 'Aggiornata Quantità Shopify' })
            : window.growl.show({ severity: 'error', summary: 'Errore aggiornamento Shopify' })
          setTimeout(() => success && FC.updateCache(['shopify']), 4000)
        }
      }
    } catch (e) {
      FC.log({ EventType: 'ErrHandleLoad - Catch', Data: { Qt, change, prodotto, actionQt } }, true)
      window.growl.show({ severity: 'error', summary: 'Errore caricamento quantità' })
    }
    handleClear()
  }

  return (
    <Flex fw jb as height={55}>
      <Text value={actions[action].label} style={{ marginLeft: 5 }} />
      <Flex fw row jb width={500}>
        <SfoField
          label='Quantità' width={110} style={{ width: 190 }}
          field='actionQt' value={actionQt} onChange={(e) => setActionQt(parseInteger(e.actionQt))}
        />
        <Button label='Annulla' style={{ width: 115, marginLeft: 20 }} onClick={handleClear} />
        <Button
          disabled={processing || !isSuccess}
          label={actions[action].button} style={{ width: 150, marginLeft: 20 }}
          onClick={() => actions[action].action()}
        />
      </Flex>
    </Flex>
  )
}
