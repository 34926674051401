import React, { useState } from 'react'

import { Dialog } from 'primereact/dialog'

import { Spinner, Text, Button, Flex } from '../../Components'
import { useCapsule, cap, navigateTo } from '../../State'
import { cancellaVendita, processaVendita } from './ProcedureVendita'

export function TotaleDialog ({ dialogControls }) {
  const [{ sale }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const [processing, setProcessing] = useState(false)
  const [cancelDialog, setCancelDialog] = useState(false)
  const [deleteQuestion, setDeleteQuestion] = useState(false)
  const [processDialog, setProcessDialog] = useState(false)

  dialogControls.showCancel = () => setCancelDialog(true)
  dialogControls.showProcess = () => setProcessDialog(true)

  const handleAnnullaModifiche = () => {
    cap.vendita.reset()
    cap.cliente.reset()
    navigateTo('Home')
  }

  const handleProcessEvent = (event) => () => {
    if (processing) return false
    setProcessing(true)
    setTimeout(event, 800)
  }

  const footerProcessing = footer => processing ? <Flex fw><Spinner /></Flex> : footer

  const footerProcessa = (
    <Flex row jb width={320}>
      <Button label='Annulla' icon='ban' onClick={() => setProcessDialog(false)} />
      <Button label='Vai alla Cassa' icon='check' onClick={handleProcessEvent(processaVendita)} />
    </Flex>
  )

  const footerCancella = (
    <Flex row jb style={{ width: 380 }}>
      <Button label='Torna Indietro' icon='check' onClick={() => setDeleteQuestion(false)} />
      <Button label='Elimina Vendita' icon='trash' onClick={handleProcessEvent(cancellaVendita)} />
    </Flex>
  )

  const footerAnnnulla = (
    <Flex row jb fw style={{ width: sale.Id ? 600 : 380 }}>
      {sale.Id ? <Button label='Elimina Vendita' icon='trash' onClick={() => setDeleteQuestion(true)} /> : null}
      <Button label={sale.Id ? 'Annulla Modifiche' : 'Annulla Vendita'} icon='ban' onClick={handleAnnullaModifiche} />
      <Button label={sale.Id ? 'Torna a Modificare' : 'Torna alla Vendita'} icon='check' onClick={() => setCancelDialog(false)} />
    </Flex>
  )

  return (
    <>
      <Dialog
        modal header='Completa Vendita' visible={processDialog}
        footer={footerProcessing(footerProcessa)} onHide={() => setProcessDialog(false)}
      >
        <Text value={`Processare la vendita${cliente.Piva ? ' (ATTENZIONE! Cliente Business)' : ''}?`} />
      </Dialog>
      <Dialog
        modal header='Annulla Vendita' visible={cancelDialog} onHide={() => setCancelDialog(false)}
        footer={footerProcessing(deleteQuestion ? footerCancella : footerAnnnulla)}
      >
        <Text
          value={
            !sale.Id
              ? 'Vuoi annullare questa nuova vendita?'
              : (deleteQuestion
                  ? 'Sicuro di voler cancellare definitivamente questa vendita?'
                  : 'Si vuole annullare le modifiche o eliminare la vendita?')
          }
        />
      </Dialog>
    </>
  )
}
