import React, { useState, useEffect } from 'react'
import { Card, Flex, Text } from '../../Components'
import { FC } from '../../Services'
import { Chart } from 'primereact/chart'
import { Checkbox } from 'primereact/checkbox'
import { RadioButton } from 'primereact/radiobutton'
import moment from 'moment'

export default function ProdottiChart ({ range, searchProd }) {
  const [chartPeriod, setChartPeriod] = useState('day')
  const [showDelivery, setShowDelivery] = useState(true)
  const [showSfuso, setShowSfuso] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    FC.service('statistiche').get('prodottichart', { query: { ...range, ...searchProd, period: chartPeriod } })
      .then(setData)
  }, [range, chartPeriod, searchProd])

  const FlagStat = ({ value, setValue, label }) => (
    <Flex row>
      <Checkbox checked={value} onChange={({ checked }) => setValue(checked)} />
      <Text value={label} style={{ marginLeft: 5 }} />
    </Flex>
  )

  const OptStat = ({ value, name }) => (
    <Flex row>
      <RadioButton value={value} name={name} onChange={(e) => setChartPeriod(e.value)} checked={chartPeriod === value} />
      <Text value={name} style={{ marginLeft: 5 }} />
    </Flex>
  )

  const getChartText = () => {
    const period = { day: 'Giornaliere ', week: 'Settimanali ', month: 'Mensili ' }
    const dateRange = ` dal ${moment(range.from).format('D/M/YY')} al ${moment(range.to).format('D/M/YY')}`
    const cat = searchProd.Categoria ? ' di ' + searchProd.Categoria : ''
    const forn = searchProd.Fornitore ? ' di ' + searchProd.Fornitore : ''
    const filt = searchProd.Descrizione ? ` (${searchProd.Descrizione})` : ''
    const label = 'Totale Vendite ' + period[chartPeriod] + cat + forn + filt + dateRange
    return label
  }

  const formatDayChart = (stime) => {
    const upCasePar = (par) => par ? par.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ') : ''
    if (chartPeriod === 'day') return upCasePar(moment(stime).format('dd D MMM'))
    if (chartPeriod === 'week') return upCasePar(moment(stime, 'YYYY-WW').format('D') + '-' + moment(stime, 'YYYY-WW').add(6, 'days').format('D MMM'))
    if (chartPeriod === 'month') return upCasePar(moment(stime).format('MMM YYYY'))
  }

  const barSfuso = showSfuso
    ? [
        { type: 'bar', label: 'Prodotti', backgroundColor: '#4CAF50', data: data ? data.map(d => d.TotSold) : [] },
        { type: 'bar', label: 'Sfuso', backgroundColor: '#FFC107', data: data ? data.map(d => d.TotSfuso) : [] }
      ]
    : []

  const lineDelivery = showDelivery
    ? [
        { type: 'line', label: 'Totale In Negozio', borderColor: '#B54CF5', fill: false, data: data ? data.map(d => d.TotLocal) : [] },
        { type: 'line', label: 'Totale Delivery', borderColor: '#F2413A', fill: false, data: data ? data.map(d => d.TotDelivery) : [] }
      ]
    : []

  const lineTotale = { type: 'line', label: 'Totale Generale', borderColor: '#2196F3', fill: false, data: data ? data.map(d => d.Totale) : [] }

  return (
    <Card title='GRAFICO' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>

      <Flex row fw jb as width={590}>
        <OptStat name='Giornaliero' value='day' />
        <OptStat name='Settimanale' value='week' />
        <OptStat name='Mensile' value='month' />
        <FlagStat label='Mostra Sfuso' value={showSfuso} setValue={setShowSfuso} />
        <FlagStat label='Mostra Delivery' value={showDelivery} setValue={setShowDelivery} />
      </Flex>

      <Chart
        type='bar' data={{
          labels: data?.map(({ stime }) => formatDayChart(stime)),
          datasets: [lineTotale, ...lineDelivery, ...barSfuso]
        }}
        options={{
          responsive: true,
          title: { display: true, text: getChartText() },
          tooltips: { mode: 'index', intersect: true },
          scales: { yAxes: [{ ticks: { callback: (value, index, values) => '€' + value } }] }
        }}
      />
    </Card>
  )
}
