import React, { useState, useEffect } from 'react'
import { useMount } from 'react-use'
import { FC, useCQuery } from '../../Services'
import { Flex, Input, Spinner, Button, Text } from '../../Components'
import { Checkbox } from 'primereact/checkbox'
import { getShopify, getProdQt } from './ProcedureMagazzino'

export function ProdottoShopify ({ prodotto, onChange, onSave }) {
  const { isSuccess: qtSuccess, data: magazzinoQt } = useCQuery('magazzinoQt')
  const { isSuccess: sySuccess, data: shopify } = useCQuery('shopify')

  const prodQt = qtSuccess ? getProdQt(prodotto, magazzinoQt) : {}
  const prodShopify = sySuccess ? getShopify(prodotto, shopify) : {}

  const [processing, setProcessing] = useState(false)
  const [price, setPrice] = useState(prodShopify.price)
  const [priceCompare, setPriceCompare] = useState(prodShopify.priceCompare)
  const [itemQt, setItemQt] = useState(prodShopify.quantity)
  const qtToSale = prodQt.Qt - prodotto.QtRiservataNegozio < 0 || !prodotto.OnlineShopify ? 0 : prodQt.Qt - prodotto.QtRiservataNegozio
  const isReady = sySuccess && qtSuccess && !processing

  const handleSync = async (newQt) => {
    const { variantId, inventoryId } = prodShopify
    if (!price || !priceCompare) {
      window.growl.show({ severity: 'error', summary: 'Errore Aggiornamento', detail: 'Impostare il prezzo' })
      return false
    }

    setProcessing(true)
    const [updateQt, updatePrice] = await Promise.all([
      FC.service('shopify').patch('quantity', { inventoryId, quantity: parseInt(newQt) }),
      FC.service('shopify').patch('price', { variantId, price: price.replace(',', '.'), priceCompare: priceCompare.replace(',', '.') })
    ])

    updateQt && updateQt.success && updatePrice && updatePrice.success
      ? window.growl.show({ severity: 'success', summary: 'Shopify Aggiornato!' })
      : window.growl.show({ severity: 'error', summary: 'Errore Aggiornamento', detail: `${(updateQt ? updateQt.error || '' : '')} ${(updatePrice ? updatePrice.error || '' : '')}` })

    updateQt && updateQt.success && setItemQt(newQt)
    ;((updateQt && updateQt.success) || (updatePrice && updatePrice.success)) && FC.updateCache('shopify')
    setProcessing(false)
  }

  useEffect(() => { setItemQt(prodShopify.quantity) }, [shopify, prodShopify.quantity])
  useMount(() => { !FC.isDev && isReady && (qtToSale !== prodShopify.quantity) && handleSync(qtToSale) }, [prodQt, qtToSale, prodotto])

  if (!isReady) return <Flex fw height={70}><Spinner /></Flex>

  let message = ''
  !prodotto.OnlineShopify && (message = 'Prodotto trovato su Shopify, abilitare vendita per settare quantità e prezzo')
  !prodShopify.variantId && (message = 'Il prodotto non è stato trovato nell\'inventario Shopify')

  const handleCheckUpdate = async ({ checked }) => {
    onChange({ OnlineShopify: checked ? 1 : 0 })
    handleSync(checked ? prodQt.Qt - prodotto.QtRiservataNegozio : 0)
  }

  const renderShopify = () => (
    <>
      <Flex row fw jb style={{ marginTop: 10 }}>
        <Input useState={[priceCompare, setPriceCompare]} label='Prezzo Cmp:' style={{ width: '32%' }} />
        <Input useState={[price, setPrice]} label='Prezzo:' style={{ width: '32%' }} />
        <Button label='Aggiorna Shopify' icon='sync' style={{ width: '32%', height: 31 }} onClick={() => handleSync(qtToSale)} />
      </Flex>
      <Text value='Prezzo è il prezzo di ventida, Prezzo Cmp quello barrato, è necessario includerlo e deve essere superiore anche solo di 1 cent' size={10} style={{ marginLeft: 5, marginTop: 5 }} />
    </>
  )

  return (
    <Flex fw js as height={70}>
      <Flex row fw js style={{ marginTop: 10 }}>
        <Text value='Vendita su Shopify:' style={{ marginLeft: 5, marginRight: 5 }} />
        <Checkbox checked={!!prodotto.OnlineShopify} onChange={handleCheckUpdate} disabled={!prodShopify.variantId} />
      </Flex>
      <Flex row fw jb style={{ marginTop: 10 }}>
        <Input id='QtRiservataNegozio' value={prodotto.QtRiservataNegozio} onChange={onChange} label='Qt. Riservata Negozio:' style={{ width: '35%', textWidth: '10%' }} />
        <Input id='qtMust' value={qtToSale} disabled label='Da Vendere:' style={{ width: '25%', textWidth: '10%' }} />
        <Input id='qtActual' value={itemQt} disabled label='Attualmente in Vendita:' style={{ width: '35%', textWidth: '10%' }} />

      </Flex>
      {message ? <Flex fw><Text value={message} style={{ marginTop: 20 }} /></Flex> : renderShopify()}
    </Flex>
  )
}
