import React, { useState } from 'react'
import { cap } from '../../State'
import { Spinner, Password } from '../../Components'
import { useCQuery } from '../../Services'
import { CaricoScarico } from './CaricoScarico'
import { Movimenti } from './Movimenti'
import { Prodotto } from './Prodotto'
import { MagazzinoTable } from './MagazzinoTable'
import { Ordine } from './Ordine'

export default function Magazzino () {
  const [passVisible, setPassVisible] = useState(true)

  const { isSuccess: magLoadOK } = useCQuery('magazzino')
  const { isSuccess: magQtLoadOk } = useCQuery('magazzinoQt')
  const { isSuccess: spfLoadOk } = useCQuery('shopify')

  if (!cap.user.state.isAdmin && passVisible) return <Password matchPassword='doggymagazzino' handlePasswordOK={() => setPassVisible(false)} noCancel />

  if (!magLoadOK || !magQtLoadOk || !spfLoadOk) return <Spinner />

  return (
    <>
      <CaricoScarico />
      <Movimenti />
      <Prodotto />
      <Ordine />
      <MagazzinoTable />
    </>
  )
}
