import React, { useState, useEffect } from 'react'
import { DropDownCatForn, Text, DialogSaveDelete, Flex } from '../../Components'
import { InputNumber } from 'primereact/inputnumber'

import { FC, useCQuery, getReparto } from '../../Services'
import { cap, useCapsule } from '../../State'
import { ProField, PriceField } from './ProcedureMagazzino'
import { ProdottoShopify } from './ProdottoShopify'
import { ProdottoSfuso } from './ProdottoSfuso'
import { ProdottoQuantity } from './ProdottoQuantity'
import { ProdottoQuantityEdit } from './ProdottoQuantityEdit'

export const Prodotto = () => {
  const [processing, setProcessing] = useState(false)
  const [prodotto, setProdotto] = useState(null)

  const [{ prodotto: storedProdotto, prodottoSfuso, action }] = useCapsule(cap.magazzino)

  const { isSuccess, data: magazzino } = useCQuery('magazzino')

  useEffect(() => {
    prodotto === null
      ? setProdotto(storedProdotto)
      : storedProdotto && storedProdotto.Id && storedProdotto.Id !== prodotto.Id && setProdotto(storedProdotto)
  }, [prodotto, storedProdotto])

  const setProdottoStore = prod => cap.magazzino.patchState({ prodotto: prod })
  const updateField = e => setProdotto({ ...prodotto, ...e })

  const handleClearProd = () => {
    setProdottoStore(null)
    setProdotto(null)
    setProcessing(false)
  }

  const handleEliminaProdotto = async () => {
    setProcessing(true)
    const result = await FC.service('magazzino').patch(prodotto.Id, { Codice: '##CANCELLATO##' })
    FC.log({ IdTarget: prodotto.Id, EventType: 'DeleteProduct', Data: { prodotto, OldCodice: prodotto.Codice } }, !result)
    FC.updateCache(['magazzino', 'magCatForn'])
    window.growl.show({ severity: 'success', summary: 'Prodotto Eliminato' })
    handleClearProd()
  }

  const handleSaveProdotto = async (closeWindow) => {
    const { Id, ...prod } = prodotto
    prod.Codice = prod.Codice.replace(/'/g, '-')
    setProcessing(true)

    const sameCode = !isSuccess ? false : magazzino.find(m => m.Id !== Id && (m.Codice.toLowerCase() === prod.Codice.toLowerCase() || (m.CodiceSfuso === prod.CodiceSfuso && prod.CodiceSfuso !== '')))
    if (sameCode) {
      setProcessing(false)
      window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio', detail: 'Utilizzato stesso codice di ' + sameCode.Descrizione })
      return false
    }
    if (prodottoSfuso && !prod.CodiceSfuso) {
      setProcessing(false)
      window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio', detail: 'Inserire codice a barre Sfuso ' })
      return false
    }

    if (prod.CodiceSfuso && !prod.PrezzoSfuso) {
      setProcessing(false)
      window.growl.show({ severity: 'error', summary: 'Errore nel salvataggio', detail: 'Inserire Prezzo al KG! ' })
      return false
    }

    !prod.QtMinima && (prod.QtMinima = 0)
    prod.QtRiservataNegozio === '' && (prod.QtRiservataNegozio = 0)

    const oldProd = await FC.service('magazzino').get(Id)
    const result = await FC.service('magazzino').patch(prodotto.Id, prod)
    FC.updateCache(['magazzino', 'magCatForn'])
    FC.log({ IdTarget: prodotto.Id, EventType: 'EditProduct', Data: { old: oldProd, new: prod, result } }, !result)

    closeWindow ? handleClearProd() : setProcessing(false)
  }

  if (!prodotto) return null

  return (
    <DialogSaveDelete
      visibleState={[!!prodotto, () => handleClearProd()]} isProcessing={processing}
      onSave={() => handleSaveProdotto(true)} onDelete={prodotto && prodotto.isNew ? null : handleEliminaProdotto}
      header={prodotto && prodotto.isNew ? 'Nuovo Prodotto' : 'Modifica Prodotto'}
    >
      <Flex row js as>
        <Flex js width={580}>
          <Flex jb fw height={230}>
            <Flex row fw jb>
              <DropDownCatForn field='Categoria' value={prodotto.Categoria} onChange={updateField} style={{ width: '48%' }} />
              <DropDownCatForn field='Fornitore' value={prodotto.Fornitore} onChange={updateField} style={{ width: '48%' }} />
            </Flex>

            <Flex row fw jb>
              <ProField label='Descrizione' field='Descrizione' prodotto={prodotto} onChange={updateField} max={255} style={{ width: '70%' }} />
              <InputNumber
                readonly showButtons min={0} max={6}
                prefix={getReparto(prodotto.Reparto) + ': '} value={prodotto.Reparto}
                onChange={({ value }) => updateField({ Reparto: value == null ? 1 : value })}
                inputStyle={{ width: 110 }}
              />
            </Flex>
            <Flex row fw jb>
              <ProField label='Codice' field='Codice' prodotto={prodotto} onChange={updateField} max={255} />
              <ProField label='Informazioni' field='Info' prodotto={prodotto} onChange={updateField} max={255} />
            </Flex>

            <ProdottoQuantity stateProdotto={[prodotto, setProdotto]} />

          </Flex>

          <Flex fw js height={170} style={{ marginTop: 10 }}>
            {action
              ? <ProdottoQuantityEdit />
              : (
                <>
                  <Flex jb fw height={55}>
                    <Text value='Prezzo di vendita Negozio:' style={{ alignSelf: 'flex-start', marginLeft: 5 }} />
                    <Flex row fw jb>
                      <PriceField label='Prezzo Fornitore' field='PrezzoFornitore' prodotto={prodotto} onChange={updateField} style={{ width: '48%' }} />
                      <PriceField label='Prezzo Listino' field='PrezzoListino' prodotto={prodotto} onChange={updateField} style={{ width: '48%' }} />
                    </Flex>
                  </Flex>
                  <ProdottoShopify prodotto={prodotto} onChange={updateField} onSave={() => handleSaveProdotto(false)} />
                </>)}
          </Flex>
        </Flex>

        {prodottoSfuso && <ProdottoSfuso stateProdotto={[prodotto, setProdotto]} />}
      </Flex>
    </DialogSaveDelete>
  )
}
