import React from 'react'
import { useCapsule, cap } from '../../State'

import { Text, Button, Card, Flex, FlagField, Input } from '../../Components'
import { getDisplayPrice, getTotaleCarrello, getPaymentOptions, isSmallAmazon } from '../../Services'
import { TotaleDialog } from './TotaleDialog'

export function Totale () {
  const [{ sale, carrello, noSaleDiscount, addSconto, affiliazione }] = useCapsule(cap.vendita)
  const [cliente] = useCapsule(cap.cliente)

  const dialogControls = {}

  const handleNoSaleDiscount = (noSaleDiscount) => {
    cap.vendita.patchState({
      carrello: noSaleDiscount ? carrello.filter(p => !p.isSconto) : carrello,
      noSaleDiscount,
      addSconto: !noSaleDiscount ? false : addSconto
    })
  }

  const setPagamento = (Pagamento) => {
    // Pagamento === 'satispay' ? handleNoSaleDiscount(true) : (sale.Pagamento === 'satispay' && handleNoSaleDiscount(false))
    cap.vendita.patchState({ sale: { ...sale, Pagamento } })
  }

  if (!cliente || isSmallAmazon(cliente)) return null

  const isAffMaiSconti = affiliazione && affiliazione.TipoSconto === 0
  return (

    <Card style={{ marginTop: 20, height: 80, width: '100%' }}>
      <Flex row fw jb>
        <Button icon='ban' label='Annulla' onClick={() => dialogControls.showCancel()} />

        <Flex row width={200}>
          {cliente.MaiSconto || cliente.Id < 1 || isAffMaiSconti
            ? null
            : <FlagField label='Vendita seza scontistica' checked={noSaleDiscount} onChange={handleNoSaleDiscount} />}
        </Flex>

        <Flex row width={140}>
          {cliente.Id === -1
            ? null
            : cliente.Piva
              ? <Input placeholder='Pagamento...' useState={[sale.Pagamento, setPagamento]} maxLength={30} />
              : <Input dropdown useState={[sale.Pagamento, setPagamento]} options={getPaymentOptions()} style={{ width: 140 }} />}
        </Flex>

        <Button
          icon='cash-register' label={`${sale.Id ? 'Modifica' : 'Completa'} Vendita`}
          onClick={!carrello.length ? null : () => dialogControls.showProcess()}
        />

        <Flex row>
          <Text bold size={32} value='Totale:' />
          <Text size={32} style={{ marginLeft: 7 }} value={getDisplayPrice(getTotaleCarrello(carrello))} />
        </Flex>
      </Flex>

      <TotaleDialog dialogControls={dialogControls} />
    </Card>
  )
}
