import React, { useState } from 'react'
import { Input, Text, Flex, DialogSaveDelete, Spinner } from '../../Components'
import { useCQuery, getEmptyMove, FC } from '../../Services'

export function RettificaFile ({ visibleState }) {
  const [dialogVisible, setDialogVisible] = visibleState
  const [input, setInput] = useState('')
  const [processing, setProcessing] = useState(false)
  const [progress, setProgress] = useState(0)

  const { isSuccess: magLoadOK, data: magazzino } = useCQuery('magazzino')
  const { isSuccess: magQtLoadOk, data: magazzinoQt } = useCQuery('magazzinoQt')

  const error = (text) => {
    window.growl.show({ severity: 'error', summary: 'Errore lettura elementi' })
    setProcessing(false)
    return false
  }

  const handleSaveRettifica = async () => {
    const rectTable = {}
    const rejected = []
    // const prods = []
    setProcessing(true)

    const elements = input.split(/\r?\n/)
    if (!input || !elements || !elements.length) return error('Errore lettura elementi')

    elements.forEach(el => rectTable[el] ? (rectTable[el]++) : (rectTable[el] = 1))
    if (elements.length !== Object.values(rectTable).reduce((tot, curr) => tot + curr, 0)) return error('Errore lettura elementi')

    for (let i = 0; i < Object.keys(rectTable).length; i++) {
      const codice = Object.keys(rectTable)[i]
      const qtRect = rectTable[codice]
      if (codice === '') continue
      if (i % 5 === 0) setProgress(Math.floor(i / Object.keys(rectTable).length * 100))

      const prodotto = magazzino.find(m => codice === m.Codice)
      if (!prodotto) {
        rejected.push(...Array(qtRect).fill(codice))
        continue
      }
      // prods.push(prodotto.Id)

      if (prodotto.Id === -2) {
        console.log(prodotto)
      }

      const prodQt = magazzinoQt.find(({ IdProdotto }) => IdProdotto === prodotto.Id) || getEmptyMove()
      const Qt = qtRect - prodQt.Qt
      if (Qt === 0) continue
      try {
        await FC.service('movimenti').create({ IdProdotto: prodotto.Id, IdSale: 0, Qt })
        console.log('aggiornato', { IdProdotto: prodotto.Id, IdSale: 0, Qt })
      } catch (err) {
        rejected.push(...Array(qtRect).fill(codice))
      }
    }

    if (rejected.length) {
      window.growl.show({ severity: 'error', summary: 'Alcuni codici non processati' })
      setInput('I codici processati correttamente sono stati rimossi.\nControllare i seguenti codici: \n' + rejected.join('\n'))
    } else {
      window.growl.show({ severity: 'info', summary: 'Rettifica Completata' })
      setInput('Tutti i codici rettificati, in caso di errore controllare in movimenti.')
    }
    // setInput(prods.join(', '))
    FC.updateCache(['movimenti', 'magazzinoQt'])
    setProcessing(false)
    setProgress(0)
  }

  return (
    <DialogSaveDelete
      visibleState={[dialogVisible, setDialogVisible]} header='Rettifica da codici'
      onSave={handleSaveRettifica}
      style={{ width: 600 }}
      footer={processing || !magLoadOK || !magQtLoadOk ? <Spinner /> : undefined}
    >
      <Text value='Attenzione, se un codice è presente N volte la sua quantità verrà rettificata a N.' />
      <Text value={'I codici errati o non trovati verranno restituiti nell\'elenco, quelli processati rimossi.'} />
      <Text value='In caso di errore andare in movimenti e cancellare le movimentazioni di rettifica errate.' />

      <Input disabled={processing} multiline useState={[input, setInput]} autoResize={false} rows={20} cols={8} style={{ marginTop: 20 }} />
      <Flex width={`${progress}%`} bg height={20} style={{ marginTop: 10 }} />
    </DialogSaveDelete>
  )
}
