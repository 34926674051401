import React from 'react'
import { useCapsule, cap } from '../State'
import { Card, Icon, Text, Flex } from '../Components'
import { loading } from '../Resources'

export default function ConnectionLost () {
  const [env] = useCapsule(cap.user)
  return !env.offline ? null : (
    <Flex style={pagecontainer}>
      <Card fadeIn style={{ width: 400, padding: 20 }}>
        <Flex fw>
          <Icon name='exclamation-triangle' style={{ alignSelf: 'center' }} />
          <Text bold value='Persa la connessione con il server' size={18} style={{ marginTop: 20 }} />
          <Text value='Il programma si riconnetterà automaticamente' size={18} style={{ marginTop: 20 }} />
          <img alt='err' src={loading} style={{ width: '100%' }} />
          <Text bold value='Attendere prego...' size={18} style={{ marginTop: 20 }} />
        </Flex>
      </Card>
    </Flex>
  )
}

const pagecontainer = {
  backgroundColor: 'rgba(0,0, 0, 0.6)',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 100
}
