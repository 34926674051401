import React, { useState, useEffect } from 'react'
import { Flex, Input, DialogSaveDelete, FlagField } from '../../Components'
import { FC, tipoAffiliazione } from '../../Services'

const Field = ({ label, id, aff, setAff, ...props }) => (
  <Input
    value={aff[id]} onChange={update => setAff({ ...aff, ...update })}
    label={`${label}:`} placeholder={`Inserisci ${label}...`} id={id} maxLength={50}
    {...props}
  />
)

const scontoLabel = [
  '',
  '% Sconto',
  'Euro Sconto'
]

export default function Editor ({ input, onHide }) {
  const [processing, setProcessing] = useState(false)
  const [aff, setAff] = useState({})
  const defaultProps = { aff, setAff }
  useEffect(() => { setAff(input || {}) }, [input])

  const isInputValid = () => {
    if (!aff.Nome || aff.TipoSconto === undefined) return false
    if (!aff.TipoSconto || aff.TipoSconto === 3) return true
    if (aff.TipoSconto === 1 && aff.Sconto > 99) return false
    return aff.Sconto
  }

  const handleSave = async () => {
    const { Id, ...fields } = aff
    if (!isInputValid()) return window.growl.show({ severity: 'error', summary: 'Controllare Nome e Valore Sconto' })
    setProcessing(true)
    await (aff.Id ? FC.service('affiliazioni').patch(Id, fields) : FC.service('affiliazioni').create(fields))
    FC.updateCache('affiliazioni')
    setProcessing(false)
    onHide()
    window.growl.show({ severity: 'success', summary: 'Salvataggio Affiliazione completato' })
  }

  return (
    <DialogSaveDelete
      visibleState={[!!input, onHide]} isProcessing={processing} style={{ width: 500 }}
      onSave={handleSave}
      header={`Affiliazione ${aff.Nome || ''}`}
    >
      <Flex as js height={160}>
        <Flex row fw style={{ marginBottom: 20 }}>
          <Field id='TipoSconto' label='Tipo Sconto' {...defaultProps} dropdown options={tipoAffiliazione} style={{ marginRight: 10 }} />
          {(!!aff.TipoSconto && aff.TipoSconto !== 3) && (
            <Field id='Sconto' label={scontoLabel[aff.TipoSconto]} {...defaultProps} keyfilter='pint' style={{ marginLeft: 10 }} />
          )}
        </Flex>
        <Field id='Nome' label='Nome Affiliazione' {...defaultProps} />
        <FlagField
          style={{ marginLeft: 10, marginTop: 20 }} label='Promozione Attiva'
          checked={aff.Attivo === 1} onChange={(v) => setAff({ ...aff, Attivo: v ? 1 : 0 })}
        />
      </Flex>
    </DialogSaveDelete>
  )
}
