import jwtDecode from 'jwt-decode'
import moment from 'moment'
import 'moment/locale/it'

moment.locale('it')

export const validateJwt = (jwt) => {
  try {
    return moment.unix(jwtDecode(jwt).exp) - moment() > 0 ? jwt : false
  } catch (e) {
    return false
  }
}

export const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(k, v)]))

export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const found = (Array.isArray(params) ? params : [params]).map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const capitalize = (word) => word ? word.toLowerCase().replace(/^\w/, c => c.toUpperCase()) : ''
export const capitalizeSentence = (par) => par ? par.split(' ').map(capitalize).join(' ') : ''

export const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth
export const getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight
export const isLargeScreen = () => getScreenWidth() > 1600
export const isSmallAmazon = (cliente) => cliente ? cliente.Id === -1 && !isLargeScreen() : false

export const getFullNomeCli = (cli) => cli ? `${capitalize(cli.Nome)} ${capitalize(cli.Cognome)}` : ''

export const getTotaleCarrello = c => c && c.length ? c.reduce((tot, curr) => tot + curr.TotaleSingolo, 0) : 0

export const getTotaleSconto = c => c && c.length ? c.reduce((tot, curr) => tot + (curr.ValePunti ? curr.TotaleSingolo : 0), 0) : 0

export const getStringHash = (st) => {
  let hash = 0
  for (let i = 0; i < st.length; i++) {
    const character = st.charCodeAt(i)
    hash = ((hash << 5) - hash) + character
    hash = hash & hash // Convert to 32bit integer
  }
  return `${hash}`
}

export const scorporoIva = ({ TotaleSingolo, Reparto }) => {
  const ivaReparto = [4, 22, 22, 22, 10]
  const iva = Reparto >= 0 && Reparto <= 4 ? ivaReparto[Reparto] : 22
  return Math.floor((100 * TotaleSingolo) / (100 + iva))
}
