import React from 'react'
import { getDisplayWeight, getDisplayPrice, scorporoIva } from '../../Services'
import { Text } from '../../Components'

const Row = ({ height = 25, descrizione, note, quantita, prezzoS, importo, iva, title }) => (
  <div style={{ ...styles.row, height: 25, width: '100%' }}>
    <div style={{ width: '7%', height, ...styles.rowCell }} />
    <div style={{ width: '48%', height, ...styles.rowCell }}>
      <Text size={12} bold={title} value={note || descrizione} />
    </div>
    <div style={{ width: '10%', height, ...styles.rowCell }}>
      <Text size={12} bold={title} value={quantita} />
    </div>
    <div style={{ width: '15%', height, ...styles.rowCell, justifyContent: 'flex-end' }}>
      <Text size={12} bold={title} value={prezzoS} />
    </div>
    <div style={{ width: '10%', height, ...styles.rowCell, justifyContent: 'flex-end' }}>
      <Text size={12} bold={title} value={iva} />
    </div>
    <div style={{ width: '10%', height, ...styles.rowCell, justifyContent: 'flex-end' }}>
      <Text size={12} bold={title} value={importo} />
    </div>
  </div>
)

const Prodotto = ({ prodotto: { IdProdotto, Qt, TotaleSingolo, PesoSfuso, Note, Prodotto, Reparto }, b2b }) => {
  const ivaReparto = [4, 22, 22, 22, 10, 22, 4]
  const iva = Reparto >= 0 && Reparto <= 4 ? ivaReparto[Reparto] : 22
  const singlePrice = b2b ? scorporoIva({ TotaleSingolo, Reparto }) : TotaleSingolo

  if (IdProdotto === -2) {
    return (
      <Row
        codice='' iva='22%' quantita='1'
        descrizione='Spedizione Corriere'
        prezzoS={getDisplayPrice(singlePrice)}
        importo={getDisplayPrice(singlePrice)}
        note={Note}
      />
    )
  }
  if (IdProdotto === -1) {
    return (
      <Row
        codice='' iva='22%' quantita='1'
        descrizione='Sconto'
        prezzoS={getDisplayPrice(singlePrice)}
        importo={getDisplayPrice(singlePrice)}
        note={Note}
      />
    )
  }
  if (IdProdotto === 0) {
    return (
      <Row
        codice='' iva='22%' quantita='1'
        descrizione='Prodotto Singolo'
        prezzoS={getDisplayPrice(singlePrice)}
        importo={getDisplayPrice(singlePrice)}
        note={Note}
      />
    )
  }
  if (IdProdotto > 0 && PesoSfuso === 0) {
    return (
      <Row
        codice='' iva={`${iva}%`} quantita={Qt}
        descrizione={`${Prodotto.Fornitore} - ${Prodotto.Descrizione}`}
        prezzoS={getDisplayPrice(singlePrice / Qt)}
        importo={getDisplayPrice(singlePrice)}
        note={Note}
      />
    )
  }

  if (IdProdotto > 0 && PesoSfuso > 0) {
    return (
      <Row
        codice='' iva='22%' quantita={getDisplayWeight(PesoSfuso)}
        descrizione={`${Prodotto.Fornitore} - ${Prodotto.Descrizione} (Sfuso)`}
        prezzoS={getDisplayPrice(singlePrice)}
        importo={getDisplayPrice(singlePrice)}
        note={Note}
      />
    )
  }
  return null
}

export function Prodotti ({ prodotti, b2b }) {
  const { col } = styles

  return (
    <div style={{ ...col, height: b2b ? 510 : 580, alignItems: 'center', marginTop: b2b ? 20 : 0 }}>
      <Row title codice='Codice' descrizione='Descrizione' quantita='Quantità' prezzoS={b2b ? 'Prezzo singolo i.e.' : 'Prezzo singolo'} iva='iva' importo='Importo' />
      <div style={{ backgroundColor: 'black', height: 2, width: '100%', marginBottom: 10 }} />
      {prodotti && prodotti.length && prodotti.map((prodotto, index) => <Prodotto key={index} prodotto={prodotto} b2b={b2b} />)}
    </div>
  )
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowCell: {
    padding: 3,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  }
}
