import React, { useRef } from 'react'
import { Card, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getDisplayPrice, useCQuery } from '../../Services'

export const Affiliazioni = ({ range }) => {
  const datatable = useRef(null)

  const { isSuccess, data: statAff } = useCQuery(['statistiche', 'affiliazioni', range])

  const templateEur = ({ Tot }) => getDisplayPrice(Tot)

  return !isSuccess ? <Spinner /> : (
    <Card style={{ marginTop: 20, width: '100%' }}>
      <DataTable
        value={statAff} paginator rows={20} emptyMessage='Nessun Prodotto Trovato'
        ref={datatable}
      >
        <Column field='Nome' header='Affiliazione' />
        <Column field='Cnt' header='Numero Vendite' style={{ width: 260 }} />
        <Column field='Tot' header='Totale Acquisti' style={{ width: 260 }} body={templateEur} />
      </DataTable>
    </Card>
  )
}
