import React from 'react'
import { useCapsule, cap } from '../../State'

import { Card, ClienteSelection, Flex } from '../../Components'
import { getFullNomeCli } from '../../Services'

import { Carrello } from './Carrello'
import { Sconto } from './Sconto'
import { ClientData } from './ClientData'
import { Totale } from './Totale'
import { TotaleAmazon } from './TotaleAmazon'
import { Storico } from './Storico'
import { Delivery } from './Delivery'
import { Affiliazione } from './Affiliazione'

export default function Vendita () {
  const [cliente, setCliente] = useCapsule(cap.cliente)

  const headerProps = {
    collapsed: !!cliente,
    onExpand: () => setCliente(null),
    title: !cliente ? 'SELEZIONA CLIENTE' : `CLIENTE${cliente.Piva ? ' BUSINESS' : ''}: ${getFullNomeCli(cliente)}`,
    style: { width: '100%' }
  }

  return (
    <Flex fw as js fh>
      <Card fadeIn collapsable customHeader={<Sconto />} {...headerProps}>
        <ClienteSelection isVendita />
      </Card>
      <Flex fw as row style={{ marginTop: 20 }}>
        <Flex style={{ flexGrow: 1 }}>
          <Carrello />
          <Totale />
          <Affiliazione />
        </Flex>
        <Storico fullHeight />
        <Flex js as width={380} style={{ marginLeft: cliente && cliente.Id ? 20 : 0 }}>
          <Delivery />
          <Storico />
          <ClientData />
          <TotaleAmazon />
        </Flex>
      </Flex>
    </Flex>
  )
}
