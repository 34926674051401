import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Card, Input, Button, Text, Flex, DialogSaveDelete } from '../../Components'
import { FC, useCQuery } from '../../Services'
import { setCurrentSale } from '../Vendita/ProcedureVendita'
import { handleSaveMovimento, handleDeleteMovimento, handleResetMagazzino } from './ProcedureMagazzino'
import moment from 'moment'

export function Movimenti () {
  const [searchFrom, setSearchFrom] = useState(moment().startOf('month').toDate())
  const [searchTo, setSearchTo] = useState(moment().endOf('day').toDate())
  const [movimento, setMovimento] = useState({})
  const [dialogVisible, setDialogVisible] = useState(false)

  const { data: movimenti } = useCQuery(['movimenti', searchFrom, searchTo])
  const { isSuccess: succMagazzino, data: magazzino } = useCQuery('magazzino')

  const descNotFound = { Descrizione: 'Non Trovato' }
  const getDescrizione = IdProdotto => (succMagazzino && Array.isArray(magazzino) ? magazzino.find(m => m.Id === IdProdotto) || descNotFound : descNotFound).Descrizione
  const descrTempalte = ({ IdSale, Qt, IdProdotto }) => !IdSale && !Qt ? 'Reset completo del Magazzino' : getDescrizione(IdProdotto)
  const dateTemplate = (rowData, { field }) => moment(rowData[field]).format('DD/MM/YYYY [alle] HH:mm')
  const qtTemplate = ({ Qt }) => Qt || '🔥'
  const opTemplate = ({ IdSale, IdProdotto, Qt }) => {
    if (!IdSale && !IdProdotto && !Qt) return 'Reset Magazzino'
    if (IdSale > 0) return 'Vendita'
    return Qt < 0 ? 'Scarico' : 'Carico'
  }

  const opName = opTemplate(movimento)
  const onEditSuccess = () => {
    setDialogVisible(false)
    FC.updateCache(['movimenti', 'magazzinoQt'])
  }

  return (
    <Card fadeIn style={{ marginTop: 20 }} title='Storico Movimenti' collapsable collapsed>
      <DataTable
        style={{ minHeight: 400 }} paginator rows={20} emptyMessage='Nessuna Movimento Trovato'
        value={movimenti} selection={movimento} selectionMode='single'
        onSelectionChange={({ value }) => setMovimento(value)} onRowSelect={() => setDialogVisible(true)}
        header={(
          <Flex row fw jb>
            <Flex row jb width={460}>
              <Text value='Dal: ' />
              <Input date placeholder='Filtra Da Data' useState={[searchFrom, setSearchFrom]} />
              <Text value='Al: ' />
              <Input date placeholder='Filtra A Data' useState={[searchTo, setSearchTo]} />
            </Flex>
            <Button label='Reset Magazzino' icon='pencil-alt' onClick={() => handleResetMagazzino(onEditSuccess)} />
          </Flex>
        )}
      >
        <Column field='EventTime' header='Movimento Del' body={dateTemplate} style={{ width: 180, textAlign: 'center' }} />
        <Column header='Operazione' body={opTemplate} style={{ width: 150, textAlign: 'center' }} />
        <Column header='Qt' body={qtTemplate} style={{ width: 50, textAlign: 'right' }} />
        <Column header='Descrizione' body={descrTempalte} />
      </DataTable>

      <DialogSaveDelete
        visibleState={[dialogVisible, setDialogVisible]} header='Modifica Movimento'
        onSave={() => handleSaveMovimento(movimento, onEditSuccess)}
        onDelete={() => handleDeleteMovimento(movimento, onEditSuccess)}
        footer={(opName === 'Vendita') ? false : null}
      >
        {opName === 'Reset Magazzino' && (
          <Flex js style={{ width: 500, height: 500 }}>
            <Flex js row style={{ width: 450 }}>
              <Input date showTime id='EventTime' value={moment(movimento && movimento.EventTime).toDate()} onChange={({ EventTime }) => setMovimento({ ...movimento, EventTime })} style={{ width: 170 }} />
              <Text value='Modifica Data e Ora' style={{ marginLeft: 20 }} />
            </Flex>
            <Text value={'Tutti i movimenti precedenti all\' ultimo reset del magazzino sono ingorati nel conteggio quantità'} style={{ margin: 20 }} />

          </Flex>
        )}
        {opName === 'Vendita' && (
          <Flex js style={{ width: 500, height: 100 }}>
            <Text value='Per modificare un movimento vendita bisogna modificare la vendita' style={{ margin: 20 }} />
            <Flex fw jb row width={350}>
              <Button label='Annulla' onClick={() => setDialogVisible(false)} />
              <Button label='Modifica Vendita' icon='pencil-alt' onClick={() => setCurrentSale(movimento.IdSale)} />
            </Flex>
          </Flex>
        )}
        {(opName === 'Scarico' || opName === 'Carico') && (
          <Flex js width={400}>
            <Text value={`Modifica Carico / Scarico per ${getDescrizione(movimento.IdProdotto)}`} style={{ margin: 20 }} />
            <Flex row>
              <Input id='Qt' value={(movimento || { Qt: 0 }).Qt} onChange={({ Qt }) => setMovimento({ ...movimento, Qt })} style={{ width: 170 }} />
              <Text value='Modifica Quantità' style={{ marginLeft: 20 }} />
            </Flex>

          </Flex>
        )}
      </DialogSaveDelete>
    </Card>
  )
}
