import Capsule, { useCapsule } from 'react-capsule'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { objectMap } from '../Services'

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
class StoredCapsule extends Capsule {
  constructor (initialState, storeKey) {
    super(initialState)
    this.storeKey = storeKey
    const storedState = this.getStored()
    storedState !== null && this.setState(storedState)
  }

  traceIssues (state) {
    const cleanState = this.isObject(state) ? state : { storeKey: state }
    isDev && console.log('SetStore', this.storeKey, _.omit(cleanState, 'accessToken'))

    Sentry.setContext(this.storeKey, objectMap(cleanState, (key, value) => key === 'accessToken' ? '##ACCESSTOKEN##'
      : (Array.isArray(value) ? JSON.stringify(value) : value)))
  }

  isObject (obj) {
    return obj !== null && typeof obj === 'object'
  }

  getStored () {
    try {
      return JSON.parse(window.localStorage.getItem(this.storeKey))
    } catch (e) {}
    return null
  }

  patchState (newState) {
    if (!this.isObject(newState) || !this.isObject(this.state)) return false
    this.setState({ ...this.state, ...newState })
  }

  setState (newState) {
    super.setState(newState)
    window.localStorage.setItem(this.storeKey, JSON.stringify(newState))
    this.traceIssues(newState)
  }

  reset () {
    super.reset()
    window.localStorage.removeItem(this.storeKey)
  }
}

export { StoredCapsule, useCapsule }
