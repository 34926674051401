import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import posed from 'react-pose'

import { getScreenWidth, getScreenHeight } from '../../Services'
import { doLogout, navigateTo } from '../../State'

import { Icon } from '../Common/Icon'
import { Text } from '../Common/Text'

const modalMenu = document.getElementById('modal-menu')
const MENU_WIDTH = 300
const MINI_WIDTH = 100
const ORANGE = '#F28C2C'

const menuItems = [
  {
    label: 'Home',
    icon: 'home',
    path: 'Home'
  },
  {
    label: 'Clienti',
    icon: 'users',
    path: 'Clienti'
  },
  {
    label: 'Magazzino',
    icon: 'boxes',
    path: 'Magazzino'
  },
  {
    label: 'Bolle',
    icon: 'truck',
    path: 'Bolle'
  },
  {
    label: 'Statistiche',
    icon: 'chart-pie',
    path: 'Statistiche'
  },
  {
    label: 'Affiliazioni',
    icon: 'money-bill-alt',
    path: 'Affiliazioni'
  },
  {
    label: 'Uscita',
    icon: 'sign-out-alt',
    path: 'Logout'
  }

]

function MenuItem ({ item: { label, icon, path }, pose, action }) {
  const [isHovered, setisHovered] = useState(false)
  const slidingMenuDiv = {
    display: 'flex',
    alignItems: 'center',
    minHeight: '3rem',
    cursor: 'pointer',
    padding: '1rem 2rem'
  }

  const updateHoverStatus = (boolean) => (e) => setisHovered(boolean)

  return (
    <div
      onClick={() => action(path)}
      style={{ ...slidingMenuDiv, backgroundColor: isHovered ? ORANGE : '' }}
      onMouseEnter={updateHoverStatus(true)}
      onMouseLeave={updateHoverStatus(false)}
    >
      <Icon color={isHovered ? 'white' : ORANGE} name={icon} size={35} />

      <TextContainer pose={pose}>
        <Text
          size={20}
          style={{ marginLeft: 25, color: isHovered ? 'white' : ORANGE }}
          bold
          value={label}
        />
      </TextContainer>
    </div>
  )
}

class SlidingMenu extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
    this.state = { visible: 0 }
    this.toggle = this.toggle.bind(this)
  }

  toggle (type) {
    const { visible } = this.state

    const trasitions = {
      hide: 0,
      mouseIn: visible > 1 ? 2 : 1,
      mouseOut: visible > 1 ? 2 : 0,
      hoverInDiv: 2,
      click: visible < 2 ? 2 : 0
    }

    this.setState({ visible: trasitions[type] })
  }

  componentDidMount () {
    modalMenu.appendChild(this.el)
  }

  componentWillUnmount () {
    modalMenu.removeChild(this.el)
  }

  renderDivision () {
    return (<div style={{ height: 1, backgroundColor: 'white', border: '1px solid #F28C2C' }} />)
  }

  navigate (page) {
    if (page === 'Logout') {
      doLogout(true)
      window.location.reload()
      return false
    }
    this.toggle('hide')
    navigateTo(page)
  }

  renderMenu () {
    const screenHeight = getScreenHeight()
    const screenWidth = getScreenWidth()
    const { visible } = this.state

    const poseStates = ['hidden', 'mini', 'visible']
    const shadowStates = ['none', '4px 0 78px 0px rgba(100, 100, 100, 0.7)', '4px 0 58px 0px rgba(100, 100, 100, 0.7)']
    const overlaySize = [0, screenWidth - MINI_WIDTH, screenWidth - MENU_WIDTH]

    return (
      <MenuContainer pose={poseStates[visible]} height={screenHeight - 50}>
        <div onMouseEnter={() => this.toggle('hoverInDiv')} style={{ width: MENU_WIDTH, backgroundColor: 'white', boxShadow: shadowStates[visible] }}>
          {menuItems.map((item, ind) => (
            <MenuItemContainer key={ind} pose={poseStates[visible]} height={screenHeight - 50}>
              <MenuItem item={item} visible={visible} pose={poseStates[visible]} action={this.navigate.bind(this)} />
            </MenuItemContainer>
          ))}
        </div>

        <div onClick={() => this.toggle('hide')} style={{ height: '100%', width: overlaySize[visible] }} />
      </MenuContainer>
    )
  }

  render () {
    return ReactDOM.createPortal(this.renderMenu(), this.el)
  }
}

const commonMenuContainerStyle = {
  height: (props) => props.height,
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  zIndex: 19,
  marginTop: 50,
  top: 0,
  left: 0
}
const MenuContainer = posed.div({
  visible: { ...commonMenuContainerStyle, x: 0, transition: { duration: 300 } },
  mini: { ...commonMenuContainerStyle, x: -(MENU_WIDTH - MINI_WIDTH), transition: { duration: 300 } },
  hidden: { ...commonMenuContainerStyle, x: -MENU_WIDTH, transition: { duration: 300 } }
})

const MenuItemContainer = posed.div({
  visible: { x: 0, transition: { duration: 300 } },
  mini: { x: MENU_WIDTH - 100, transition: { duration: 300 } }
})

const TextContainer = posed.div({
  visible: { opacity: '100%', transition: { duration: 2000 } },
  mini: { opacity: '0%', transition: { duration: 0 } }
})

export default SlidingMenu
