import { FC, getDisplayWeight, getPaymentCode } from '../../Services'
import { serializeError } from 'serialize-error'
import { cap } from '../../State'

const sendCommandToPrinter = async (command) => {
  cap.cassa.setState({ message: '' })
  if (window.ipcBridge) {
    window.ipcBridge.sendToMain(command)
  } else {
    setTimeout(() => cap.cassa.setState({ message: JSON.stringify({ OK: false, Error: 'No IPC Bridge' }) }), 2000)
  }

  const result = await new Promise((resolve) => {
    const timeout = setTimeout(() => {
      clearInterval(timer)
      resolve({ OK: false, Error: 'Timeout' })
    }, 20000)
    const timer = setInterval(() => {
      if (cap.cassa.state.message !== '') {
        clearTimeout(timeout)
        clearInterval(timer)
        try {
          resolve(JSON.parse(cap.cassa.state.message))
        } catch {
          resolve({ OK: false, Error: 'JSON Error' })
        }
        cap.cassa.setState({ message: '' })
      }
    }, 500)
  })
  return result
}

const getPaymentString = (pay, total) => {
  return '3007' + getPaymentCode(pay) + String(total).padStart(9, '0')
}

const getProductString = (magazzino, se) => {
  const isSconto = se.TotaleSingolo < 0
  const tipoOp = isSconto ? '3' : '1'
  const qtInt = String(isSconto ? 0 : se.Qt).padStart(6, '0')
  const qDec = '000'
  const reparto = String(isSconto ? 1 : se.Reparto).padStart(3, '0')
  const Prod = magazzino.find(m => m.Id === se.IdProdotto)

  let desc = se.Note || (Prod ? Prod.Descrizione : 'Prodotto Singolo')
  !se.Note && se.IdProdotto === 0 && (desc = 'Servizio')
  !se.Note && se.IdProdotto === -1 && (desc = 'Sconto')
  !se.Note && se.PesoSfuso > 0 && (desc = 'Sfuso ' + getDisplayWeight(se.PesoSfuso).replace(',', '.') + ' ' + desc)
  desc = desc.replace(/[^a-zA-Z0-9. _-]/g, '').substr(0, 30)

  const descLen = String(desc.length).padStart(2, '0')
  const price = String(Math.floor(Math.abs(se.TotaleSingolo) / (se.Qt || 1))).padStart(9, '0')
  return '3301' + tipoOp + qtInt + qDec + reparto + descLen + desc + price + '00'
}

const sortScontoCarrello = (carrello) => {
  if (!carrello.find(se => se.TotaleSingolo < 0)) return carrello
  const [prodCaro, ...prodotti] = carrello.filter(se => se.TotaleSingolo > 0).sort((a, b) => b.TotaleSingolo - a.TotaleSingolo)
  if (!prodCaro || !prodCaro.TotaleSingolo) return null
  const totSconti = carrello.filter(se => se.TotaleSingolo < 0).reduce((tot, curr) => curr.TotaleSingolo + tot, 0)
  if (prodCaro.TotaleSingolo < Math.abs(totSconti)) return null
  return [
    prodCaro,
    { IdProdotto: -1, Reparto: 1, Qt: 1, TotaleSingolo: totSconti },
    ...prodotti
  ]
}

const getLotteryCode = (lottery) => {
  if (!lottery || lottery.length < 5) return []
  return ['3019' + String(lottery.length).padStart(2, '0') + lottery]
}

const printSale = async (magazzino, carrello, sale, lottery, mockPrinterResponse) => {
  const commands = [...getLotteryCode(lottery), ...carrello.map(se => getProductString(magazzino, se))]
  commands.push(getPaymentString(sale.Pagamento, sale.Totale)) // Riga Totale
  commands.push('3011') // Chiusura Scontrino
  commands.push('3013') // Espulsione Scontrino con taglio parziale
  try {
    if (mockPrinterResponse) {
      console.log(JSON.stringify(commands))
      await new Promise(resolve => setTimeout(resolve, 3000))
      return true
    }
    const result = await sendCommandToPrinter(JSON.stringify(commands))
    FC.log({ EventType: 'StampaScontrino', IdTarget: sale.Id, Data: result }, !result.OK)
    return result.OK
  } catch (e) {
    FC.log({ EventType: 'StampaScontrino', IdTarget: sale.Id, Data: serializeError(e) }, true)
    return false
  }
}

export const printReceipt = async (magazzino, sale, carrello, mockPrinterResponse, lottery) => {
  if (!sale || !sale.Id) {
    FC.log({ EventType: 'ErroreInPrintReceipt - NoSaleId', Data: { sale, carrello } }, true)
    return false
  }

  const sortedCarrello = sortScontoCarrello(carrello)
  if (!sortedCarrello) {
    window.growl.show({
      severity: 'error',
      summary: 'Battere Scontrino a mano',
      detail: 'Sconto superiore a prodotto più costoso'
    })
    return false
  }

  const success = await printSale(magazzino, sortedCarrello, sale, lottery, mockPrinterResponse)

  window.growl.show({
    severity: success ? 'info' : 'error',
    summary: success ? 'Scontrino Stampato' : 'Errore di stampa',
    detail: success ? '' : 'Problema con la stampante, riprovare'
  })

  success && FC.service('sales').patch(sale.Id, { Processato: mockPrinterResponse ? 0 : 1 })
    .then(() => FC.updateCache(['todaySales', 'daRitirare', 'daEvadere']))
    .catch(err => FC.log({ EventType: 'ErroreInPrintReceiptPatch', Error: serializeError(err), Data: { sale, carrello } }, true))

  return success
}
