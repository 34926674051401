import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { capitalize, getEmptyCliente, getAnonimo, isLargeScreen, useCQuery, prefetchQuery } from '../Services'
import { cap } from '../State'
import { Spinner, Input, Text, Icon, Button, Flex } from '.'
import { ClienteEditor } from './ClienteEditor'
import moment from 'moment'

export function ClienteSelection ({ isVendita }) {
  const [cliente, setCliente] = useState(null)
  const [gSearch, setGSearch] = useState('')
  const [lookExpired, setLookExpired] = useState(false)
  const datatable = useRef(null)

  const { isSuccess: cliSuccess, data: clienti } = useCQuery(lookExpired ? 'clientiExp' : 'clienti')
  const { isSuccess: affSuccess, data: affiliazioni } = useCQuery('affiliazioni')

  useEffect(() => {
    const id = cliente ? cliente.Id : 0
    isVendita && id !== 0 && prefetchQuery(['statsCliente', id])
    isVendita && id !== 0 && prefetchQuery(['storicoCliente', id])
  }, [cliente, isVendita])

  const handleClienteAmazon = () => {
    cap.cliente.setState({ Id: -1, Nome: 'Vendita', Cognome: 'Amazon' })
    cap.vendita.patchState({ sale: { ...cap.vendita.state.sale, isDelivery: true } })
  }

  const dataRegTemplate = ({ DataReg }) => moment(DataReg).format('DD/MM/YYYY')
  const dataLastTemplate = ({ LastSale }) => LastSale ? moment(LastSale).format('DD/MM/YYYY') : 'Mai Fatto Acquisti'
  const textTemplate = (upcase) => (rowData, { field }) => <Text value={upcase ? capitalize(rowData[field].toLowerCase()) : rowData[field]} color={rowData.Piva ? 'red' : 'black'} />
  const templateAFf = ({ Affiliato }) => (affiliazioni?.find(({ Id }) => Id === Affiliato) || { Nome: 'Nessuna Affiliazione' }).Nome

  const FiltroAFf = ({ label, datatable }) => {
    const state = useState('')
    const optionsAffiliazione = [
      { label: 'Nessuna Affiliazione', value: 0 },
      ...(!affSuccess ? [] : affiliazioni?.map(({ Id, Nome }) => ({ label: Nome, value: Id })))
    ]
    return !affSuccess
      ? <Spinner />
      : (
        <Input
          id='FiltroAff' dropdown showClear placeholder='Cerca...'
          options={optionsAffiliazione} useState={state}
          onChange={value => datatable.current.filter(value, 'Affiliato', 'equals')}
        />)
  }

  if (!cliSuccess || !affSuccess) return <Spinner />
  return (
    <>
      <DataTable
        paginator rows={isLargeScreen() ? 20 : 10} emptyMessage='Nessun Cliente Trovato' selectionMode='single' globalFilter={gSearch}
        value={clienti} selection={cliente} onRowSelect={({ data }) => setCliente(data)} ref={datatable}
        header={(
          <Flex fw row jb>
            <Flex row jb>
              <Icon name='search' size={30} />
              <Input useState={[gSearch, setGSearch]} placeholder='Ricerca' style={{ width: 200, marginLeft: 15 }} />
              <Text bold style={{ marginLeft: 20 }} size={20} value={`CLIENTI ${lookExpired ? 'CON TESSERA ARCHIVIATA' : 'ATTIVI'}`} />
              <Button icon='sync' label={`${lookExpired ? 'TORNA A CLIENTI' : 'GUARDA CLIENTI ARCHIVIATI'}`} style={{ marginLeft: 20 }} onClick={() => setLookExpired(!lookExpired)} />
            </Flex>
            <Flex row jb>
              <Button hidden={lookExpired} icon='plus' label='Nuovo Cliente' onClick={() => setCliente(getEmptyCliente())} />
              <Button hidden={!isVendita || lookExpired} icon='truck' label='Amazon' style={{ marginLeft: 15 }} onClick={handleClienteAmazon} />
              <Button hidden={!isVendita || lookExpired} icon='user' label='Cliente Anonimo' style={{ marginLeft: 15 }} onClick={() => cap.cliente.setState(getAnonimo())} />
            </Flex>
          </Flex>
        )}
      >
        <Column field='Nome' header='Nome' filter filterPlaceholder='Ricerca per Nome' body={textTemplate(true)} />
        <Column field='Cognome' header='Cognome' filterPlaceholder='Ricerca per Cognome' filter body={textTemplate(true)} />
        <Column field='Email' header='Dettaglio' body={textTemplate(false)} />
        <Column header='Data Registrazione' body={dataRegTemplate} />
        <Column header='Data Ultimo Acquisto' body={dataLastTemplate} />
        <Column field='Affiliato' header='Affiliazione' body={templateAFf} filter sortable filterElement={<FiltroAFf datatable={datatable} />} />
        <Column field='Piva' header='Partita IVA' body={textTemplate(false)} />
      </DataTable>

      <ClienteEditor
        visibleState={[!!cliente, () => setCliente(null)]}
        inputCliente={cliente} isVendita={isVendita}
        onSelectionCompleted={() => setCliente(null)}
        expiredState={[lookExpired, setLookExpired]}
      />
    </>
  )
}
