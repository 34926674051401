import React, { useEffect, useState } from 'react'
import { capitalize, getFullNomeCli, getDisplayWeight, getDisplayPrice, isLargeScreen, useCQuery, FC, getPaymetLabel } from '../../Services'
import { Text, Card, Icon, Flex, Input, Button, FlagField, Spinner } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import moment from 'moment'
import { Sale } from './Sale'
import { setCurrentSale } from '../Vendita/ProcedureVendita'

export default function Bolle () {
  const [filteredSales, setFilteredSales] = useState([])
  const [gSearch, setGSearch] = useState('')
  const [sale, setSale] = useState({})
  const [dialogVisible, setDialogVisible] = useState(false)
  const [searchFrom, setSearchFrom] = useState('')
  const [searchTo, setSearchTo] = useState('')
  const [showEvase, setShowEvase] = useState(false)
  const [showPickup, setShowPickup] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [selectedFatt, setSelectedFatt] = useState({})
  const { isSuccess, data = [] } = useCQuery(showEvase ? 'daEvadere' : (showPickup ? 'daRitirare' : 'bolle'))

  useEffect(() => {
    const displaySale = ({ SaleTime, Pagamento }) =>
      (!searchFrom || moment(SaleTime).isAfter(searchFrom)) &&
      (!searchTo || moment(SaleTime).subtract(1, 'day').isBefore(searchTo)) &&
      (!showPickup || Pagamento === 'allaconsegnainnegozio')
    isSuccess && setFilteredSales(data.filter(displaySale).map(s => ({ ...s, nomeCliente: getFullNomeCli(s.Cliente) })))
  }, [isSuccess, data, searchFrom, searchTo, showPickup])

  const dateTemplate = (rowData, { field }) => <Text value={moment(rowData[field]).format('DD/MM/YYYY [alle] HH:mm')} />
  const textTemplate = (upcase) => (rowData, { field }) => <Text value={upcase ? capitalize((rowData[field] || '').toLowerCase()) : rowData[field]} />
  const priceTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={getDisplayPrice(rowData[field])} />
  const pesoTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={getDisplayWeight(rowData[field] * 1000)} />
  const evasaTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={rowData[field] ? 'Ordine Evaso' : 'Non Evaso'} />
  const payTemplate = (rowData, { field }) => <Text style={{ textAlign: 'right' }} value={getPaymetLabel(rowData[field])} />
  const raggruppaTemplate = ({ Cliente, ...sale }) => {
    if (!Cliente?.Piva) return null
    const id = String(sale.Id)
    return (
      <Flex onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={selectedFatt[id]} onChange={({ checked }) => setSelectedFatt(sf => ({ ...sf, [id]: checked }))} />
      </Flex>
    )
  }

  const totaleValue = <Text value={`Totale Venduto: ${getDisplayPrice(filteredSales.reduce((tot, { Totale }) => Totale + tot, 0))}`} />
  const totBolle = <Text value={`Numero Bolle: ${filteredSales.length}`} />
  const totalePeso = <Text value={`Totale Peso: ${filteredSales.reduce((tot, { PesoKg }) => PesoKg + tot, 0)} Kg`} />

  const cambiaEvasione = async () => {
    setProcessing(true)
    setSale({ ...sale, Evasa: sale.Evasa ? 0 : 1 })
    await FC.service('sales').patch(sale.Id, { Evasa: sale.Evasa ? 0 : 1 })
    FC.updateCache(['daEvadere', 'bolle', 'daRitirare'])
    setProcessing(false)
  }
  const hasMultiple = Object.values(selectedFatt).filter(v => v).length > 1
  const combinedIDs = Object.entries(selectedFatt).filter(([id, selected]) => selected).map(([id]) => id).join('-')

  return (
    <Card fadeIn>
      <DataTable
        value={filteredSales} emptyMessage='Nessuna Bolla Trovata'
        paginator rows={isLargeScreen() ? 20 : 12} globalFilter={gSearch} selectionMode='single'
        selection={sale} onSelectionChange={({ value }) => setSale(value)} onRowSelect={() => setDialogVisible(true)}
        header={
          <Flex row jb width={1250}>
            <Icon name='search' size={30} />
            <Input useState={[gSearch, setGSearch]} placeholder='Ricerca' style={{ width: 170 }} />
            <Input date placeholder='Filtra Da Data' useState={[searchFrom, setSearchFrom]} style={{ width: 170 }} />
            <Input date placeholder='Filtra A Data' useState={[searchTo, setSearchTo]} style={{ width: 170 }} />
            <Button icon='ban' label='Pulisci Filtri' onClick={() => { setGSearch(''); setSearchFrom(''); setSearchTo('') }} />
            <FlagField label='Da Spedire' checked={showEvase} onChange={(c) => { setShowEvase(c); c && setShowPickup(false) }} style={{ marginLeft: 15, width: 180 }} />
            <FlagField label='In Ritiro' checked={showPickup} onChange={(c) => { setShowPickup(c); c && setShowEvase(false) }} style={{ marginLeft: 15, width: 180 }} />
          </Flex>
        }
      >
        <Column field='nomeCliente' header='Cliente' filter filterPlaceholder='Ricerca per Cliente' />
        <Column field='Operatore' header='Operatore' filterPlaceholder='Ricerca per Operatore' filter body={textTemplate(true)} />
        <Column field='Pagamento' header='Pagamento' body={payTemplate} />
        <Column field='SaleTime' header='Vendita Del' body={dateTemplate} filter filterElement={totBolle} />
        <Column field='Totale' header='Totale' body={priceTemplate} filter filterElement={totaleValue} />
        <Column field='BollaDelivery' header='N. Bolla' bodyStyle={{ textAlign: 'right' }} />
        <Column field='Colli' header='Colli' bodyStyle={{ textAlign: 'right' }} />
        <Column field='PesoKg' header='Peso in Kg' body={pesoTemplate} filter filterElement={totalePeso} />
        <Column field='Evasa' header='Evasa' body={evasaTemplate} />
        <Column header='Fa.' body={raggruppaTemplate} style={{ width: 50 }} />

      </DataTable>
      <Dialog
        visible={dialogVisible} style={{ width: 900 }} header='Dettaglio Bolla' modal
        onHide={() => setDialogVisible(false)} footer={
          <Flex row jb fw>
            <Button label='Modifica Vendita' icon='pencil-alt' onClick={sale ? () => setCurrentSale(sale.Id) : null} />
            {processing ? <Spinner /> : <Button label='Cambia Evasione' icon='globe-africa' onClick={sale ? () => cambiaEvasione() : null} />}

            <Button label='Annulla' icon='ban' onClick={() => setDialogVisible(false)} />
            <Button label='Stampa' icon='print' onClick={() => window.open(`${window.location.origin}/?view=bolla&idSale=${sale.Id}`, '_blank')} />
            {hasMultiple && <Button
              label='Stampa Combinata' icon='print' onClick={() =>
                window.open(`${window.location.origin}/?view=bolla&idSale=${sale.Id}&combine=${combinedIDs}`, '_blank')}
                            />}
          </Flex>
        }
      >
        <Sale sale={sale} />
      </Dialog>
    </Card>
  )
}
