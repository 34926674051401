import React from 'react'

import { Card, Spinner } from '../../Components'
import { capitalizeSentence, useCQuery } from '../../Services'
import { Chart } from 'primereact/chart'

import moment from 'moment'

export function ClientiChart ({ query }) {
  const { status: statusStorico, data: storico } = useCQuery(['storicoClienti', query])
  const labels = storico && storico.map(({ Giorno }) => capitalizeSentence(moment(Giorno).format('D MMM YY')))
  return (
    <Card title='STORICO CLIENTI' style={{ marginTop: 20, width: '100%' }} collapsable collapsed>
      {statusStorico === 'loading' ? <Spinner /> : (
        <>
          <Chart
            type='line' data={{
              labels,
              datasets: [{ type: 'line', label: 'Storico Clienti', borderColor: '#2196F3', fill: false, data: storico && storico.map(d => d.TotClienti) }]
            }}
            options={{
              responsive: true,
              title: { display: true, text: 'test' },
              tooltips: { mode: 'index', intersect: true }
            }}
          />
          <Chart
            type='line' data={{
              labels,
              datasets: [{ type: 'line', label: 'Media Anno', borderColor: '#ff96F3', fill: false, data: storico && storico.map(d => Math.round(d.AvgTotAnno / 100)) }]
            }}
            options={{
              responsive: true,
              title: { display: false, text: '' },
              tooltips: { mode: 'index', intersect: true },
              scales: { yAxes: [{ ticks: { callback: (value, index, values) => '€' + value } }] }
            }}
          />
        </>
      )}
    </Card>
  )
}
